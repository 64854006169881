import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import Alert from '../../../../shared/components/Alert';
import { Table } from 'react-bootstrap';
import { Card, CardBody, Col, Modal, Badge } from 'reactstrap';
var dateFormat = require('dateformat');
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	helper: [],
	help: '',
	allquesids: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	email: '',
	token: localStorage.token,
	currentcolor: [
		'#000000',
	],
	content_add: '',
	myItems: [],
	testEmails: [],
	isalready: false,
};
class SaveTest extends Component {
	constructor(props) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'savetest');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchSavedTests();
		}
	}
	handleFocus(event) { //eslint-disable-line
		// this.setState({ content_add: '' });
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		// console.log(usr_input);
		this.setState({ content_add: usr_input });
	};

	handleAddEmail(event) {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];
		var validemails = [];
		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

		aallemails.map((aallemail) => {
			let isvalidrec = re.test(aallemail);
			if (isvalidrec) {
				var newemail_string_array = aallemail.split('@');
				var domain_string_location = newemail_string_array.length - 1;
				var newemaildomain = newemail_string_array[domain_string_location];
				if (mainemaildomain == newemaildomain) {
					this.setState({
						content_add: '',
					});
				} else {
					var dnjk = this.state.testEmails.includes(aallemail);

					if (dnjk) {
						this.setState({
							content_add: '',
						});
					} else {
						validemails.push(aallemail);
					}
				}
			} else {
				this.setState({
					content_add: '',
				});
			}
		});
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		if (validemails.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				testemail: validemails,
				testid: this.state.selectedId,
			};
			const testidurl = apiurl + 'valnewchecktester';
			axios
				.post(testidurl, postData, {
					headers: headers,
				})
				.then(async (response) => {
					console.log(response);
					if (response.data.data[1].length >= 1) {
						var areusers = response.data.data[1].toString();
						console.log(areusers);
						swal('Already Sent ' + areusers, {
							icon: 'error',
						});
					}
					if (response.data.data[0].length >= 1) {
						const allemails = response.data.data[0];
						let isexist = [];
						await Promise.all(
							allemails.map((temails) => {
								return new Promise((resolve) => {
									this.state.myItems.filter((listitem) => {
										if (listitem.content == temails) {
											isexist.push(temails);
										}
									});
									// console.log('isexist ', isexist.length);

									if (isexist.length >= 1) {
										resolve('');
									} else {
										newArray.push({
											content: temails,
											id: ++this.lastId,
										});
										testEmails.push(temails);
										this.setState(
											{
												myItems: newArray,
												content_add: '',
											},
											() => {
												console.log(this.state.myItems);
												resolve('');
											},
										);
									}
								});
							}),
						);
						if (isexist.length >= 1) {
							isexist = isexist.toString();
							swal(isexist + ' Already Added', {
								icon: 'error',
							});
						}
					}
				});
		}
	}
	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			// console.log('items ', this.state.myItems);
			// console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}
	handleUserSubmit = (event) => {
		event.preventDefault();
		// console.log(this.state.testEmails);
		var help = [];
		var ttyuyp = this.state.helper;
		ttyuyp.map((tech) => {
			help.push(tech.difficultyLevel);
		});
		var technames = help.join(', ');
		this.setState(
			{
				help: technames,
			},
			() => {
				this.handleSendTest();
			},
		);
	};
	handleSendTest = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'sendTestToEmail';
		var postData = {
			email: this.state.testEmails,
			testid: this.state.selectedId,
			fromby: localStorage.by,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				// console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showEbox: false,
							showAlert: false,
						},
						() => {
							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};
							var rname = localStorage.name;
							if (localStorage.domain) {
								rname + ' - ' + localStorage.domain;
							}
							var postData = {
								email: [this.state.testEmails],
								id: this.state.selectedId,
								by: localStorage.by,
								technames: this.state.help,
								rname: rname,
								tname: 'User',
							};
							const sendTestMailx = apiurl + 'sendTestMail';

							axios
								.post(sendTestMailx, postData, {
									headers: headers,
								})

								.then(() => {
									swal(
										' Test ' +
										this.state.selectedId +
										' ' +
										response.data.message,
										{
											icon: 'success',
										},
									);
									this.props.history.push({ //eslint-disable-line
										pathname: '/savetest',
									});
								})
								.catch((error) => {
									console.log('Error in promise ', error);
								});
						},
					);
				}
			})
			.catch((error) => { //eslint-disable-line
				// console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleCkey = () => {
		this.setState({
			showEbox: false,
		});
	};

	fetchSavedTests = () => {
		this.setState(
			{
				loading: true,
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const fetchSavedTestsx = apiurl + 'fetchRSTests';
				var postData = {
					email: localStorage.by,
				};
				axios
					.post(fetchSavedTestsx, postData, {
						headers: headers,
					})
					.then((response) => {
						// console.log(response.data.data);
						this.setState(
							{
								tests: [...response.data.data],
								akeywords: response.data.akeywords,
							},
							() => {
								this.setState({
									loading: false,
								});
							},
						);
					})
					.catch((error) => {
						console.log(error);
					});
			},
		);
	};
	getBadgeColor = (index) => {
		switch (index % 3) {
			case 0:
				return 'badge-purple';
			case 1:
				return 'badge-yellow';
			case 2:
				return 'badge-grey';
		}
	};

	redirectFaqs = (faqhelp, event) => {
		event.preventDefault();
		// var faqhelp = this.state.selectedTechnologies;
		this.setState(
			{
				showAlert: false,
			},
			() => {
				if (faqhelp) {
					var faqtechs = faqhelp.join(',');
					localStorage.setItem('faqtechs', faqtechs);
					// this.props.history.push('/faqtechsques');
					const win = window.open('/faqtechsques', '_blank');
					if (win != null) {
						win.focus();
					}
					// console.log('faqtechs ', faqtechs);
				} else {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				}
			},
		);
	};
	getDetails = (techid) => {
		// console.log('normal ', techid);
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === techid;
		});
		// console.log(techname[0].keyword);
		return techname[0].keyword;
	};
	render() {
		let klk = 1;

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">List of Created and Saved Tests</h5>
						</div>
						<Table responsive striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Test Id</th>
									<th>Details</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tests &&
									this.state.tests.map((test, index) => {
										let techarrayhelp = [];
										return (
											<tr key={index}>
												<td>{klk++}</td>
												<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
												<td>
													{test.generated_id}
													<br />
													{test.ttype == 'my' ? (
														<Badge color={'success'}>Private</Badge>
													) : null}
												</td>

												<td>
													<Table>
														<thead>
															<tr>
																<th>Technology</th>
																<th>No of Questions</th>
															</tr>
														</thead>
														<tbody>
															{test.helper.map((tech, index) => {
																techarrayhelp.push(tech.technology);
																return (
																	<tr key={index}>
																		<td>{this.getDetails(tech.technology)}</td>

																		<td>{tech.questions}</td>
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</td>

												<td>
													<div
														className="btn btn-primary btn-sm"
														style={{ cursor: 'pointer' }}
														onClick={() => {
															this.setState({
																showEbox: true,
																selectedId: test.generated_id,
																helper: test.helper,
																myItems: [],
																testEmails: [],
															});
														}}
													>
														Send Test
													</div>
													<br />
													<div
														className="btn btn-primary btn-sm"
														style={{ cursor: 'pointer' }}
														onClick={this.redirectFaqs.bind(
															this,
															techarrayhelp,
														)}
													>
														View FAQ&#39;S
													</div>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.showEbox}
							modalClassName="ltr-support"
							className="modal-dialog--primary addemailsbox"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
								<h4 className="text-modal  modal__title">
									Send {this.state.selectedId} Test
								</h4>
							</div>
							<div className="modal__body">
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<form
									className="form"
									onSubmit={(event) => this.handleAddEmail(event)}
								>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
									<p>{this.makeAddedList()}</p>
									<div className="form__form-group">
										{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
										<div className="form__form-group-field">
											<textarea
												name="email"
												type="email"
												placeholder="Add Email ID"
												onFocus={this.handleFocus}
												onChange={this.handleChange}
												value={this.state.content_add}
												style={{ width: '75%', margin: '15px' }}
												onKeyUp={(e) => {
													e.preventDefault();

													// if (
													// 	e.keyCode == 13 ||
													// 	e.keyCode == 32 ||
													// 	e.keyCode == 188
													// ) {
													// 	this.handleAddEmail(event);
													// }
												}}
											/>
											<button
												className="btn btn-primary btn-sm"
												style={{ marginTop: '15px' }}
												type="submit"
											>
												Add Email
											</button>
										</div>
									</div>
								</form>
								<form
									className="form"
									onSubmit={(event) => this.handleUserSubmit(event)}
								>
									<Col md={12}>
										{this.state.testEmails.length > 0 ? (
											<button
												className="btn btn-primary float-right"
												type="submit"
												style={{ width: '40%' }}
											>
												Send Test
											</button>
										) : (
											<button
												className="btn btn-secondary float-right"
												type="button"
												desabled="true"
												style={{ width: '40%' }}
											>
												Send Test
											</button>
										)}
									</Col>
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default SaveTest;
