import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class CompanySidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				{localStorage.viewrecruiter ? (
					<div>
						<ul className="sidebar__block">
							<SidebarLink
								title="Phone Screen"
								icon="list"
								route="/recruiter/faqtechview"
								onClick={this.hideSidebar}
							/>
							<SidebarCategory title="Tech Tests" icon="layers">
								<SidebarLink
									title="Add Test"
									icon="list"
									route="/recruiter/addtest"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Saved Tests"
									icon="list"
									route="/recruiter/savetest"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Sent Tests"
									icon="user"
									route="/recruiter/senttests"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Completed Tests"
									icon="list"
									route="/recruiter/comtest"
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>
						</ul>
						<ul className="sidebar__block">
							{/* <SidebarCategory title="Custom Tests" icon="layers">
								<SidebarLink
									title="Add Custom Test"
									icon="list"
									route="/recruiter/addcustomtest"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="UnSaved Test List"
									icon="list"
									route="/recruiter/mycustoms"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Saved Test List"
									icon="list"
									route="/recruiter/customsavedtest"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Sent Test List"
									icon="list"
									route="/recruiter/customsenttest"
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title="Completed Custom Tests"
									icon="list"
									route="/recruiter/cuscomtest"
									onClick={this.hideSidebar}
								/>
							</SidebarCategory> */}
						</ul>
					</div>
				) : (
						<ul className="sidebar__block">
							<SidebarLink
								title="Reports"
								icon="list"
								route="/reportspage"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Weekly Report"
								icon="list"
								route="/reportseven"
								onClick={this.hideSidebar}
							/>
						</ul>
					)}
			</div>
		);
	}
}

export default CompanySidebarContent;
