import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import { Table } from 'react-bootstrap';
import { Card, CardBody, Col } from 'reactstrap';
var dateFormat = require('dateformat');
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	testTakers: [],
};
class CustomSentTests extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'senttests');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchcustomSentTests();
		}
	}

	fetchcustomSentTests = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'fetchcustomSentTests';
		var postData = {
			email: localStorage.by,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.data);
				this.setState({
					tests: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	getBadgeColor = (index) => {
		switch (index % 3) {
			case 0:
				return 'badge-purple';
			case 1:
				return 'badge-yellow';
			case 2:
				return 'badge-grey';
		}
	};
	render() {
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">List of Sent Tests</h5>
						</div>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Test Name</th>
									<th>Test Taker Details</th>
									<th>No.Q</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tests &&
									this.state.tests.map((test, index) => {
										let details = [...test.tester];
										let str = test.question_ids;
										let quids = str.split(',');
										quids = quids.length;
										if (test.taken === 0) {
											return (
												<tr key={index}>
													<td>{index}</td>
													<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
													<td>{test.testname}</td>
													<td>
														<b>Email: </b>
														{test.sent_to}
														<br />
														<b>Name: </b>
														{details[0].name && details[0].name}
														<br />
														<b>Mobile: </b>
														{details[0].mobile && details[0].mobile}
													</td>
													<td>{quids}</td>
													<td>
														{test.taken === 1 ? 'Taken' : 'Not taken Yet'}
													</td>
												</tr>
											);
										}
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default CustomSentTests;
