import React, { Component } from 'react';
/* global  */

import { withRouter } from 'react-router-dom';

class Logout extends Component {
	constructor (props) {
		super(props);
	}
	componentDidMount() {
		console.log('Logout');
		localStorage.clear();
		this.props.history.push('/'); //eslint-disable-line
	}

	render() {
		return <div></div>;
	}
}

export default withRouter(Logout);
