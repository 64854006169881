import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import Dashboard from './components/Dashboard';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
import ListAuKeywords from './components/ListAuKeywords';
import StartCheckQues from './components/StartCheckQues';
import ListQuesKeywords from './components/ListQuesKeywords';
import RepairTechQues from './components/RepairTechQues';
import ViewTechQues from './components/ViewTechQues';
import ChgPwd from '../Apanel/ChgPwd';
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class ITPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/chgpwd" component={ChgPwd} />
					<Route path="/listautechs" component={ListAuKeywords} />
					<Route path="/listqueskeys" component={ListQuesKeywords} />
					<Route path="/repairtech" component={RepairTechQues} />
					<Route path="/viewittech" component={ViewTechQues} />
					<Route path="/checkques" component={StartCheckQues} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
				</div>
			</div>
		);
	}
}

export default withRouter(ITPanel);
