import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import Dashboard from './components/Dashboard';
import AddQuest from './components/AddQuest';
import AddFaqs from './components/AddFaqs';
import AllQues from './components/AllQues';
import KeyQues from './components/KeyQues';
import TechQues from './components/TechQues';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
import ChgPwd from '../Apanel/ChgPwd';
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class CreaterPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/addfaqs" component={AddFaqs} />
					<Route path="/addquest" component={AddQuest} />
					<Route path="/allques" component={AllQues} />
					<Route path="/keyques" component={KeyQues} />
					<Route path="/techques" component={TechQues} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
					<Route path="/chgpwd" component={ChgPwd} />
				</div>
			</div>
		);
	}
}

export default withRouter(CreaterPanel);
