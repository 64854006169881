import React, { PureComponent } from 'react';


import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const initialstate = {

	collapse: false,
};
export default class TopbarProfile extends PureComponent {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };

	toggle = () => {
		this.setState((prevState) => ({ collapse: !prevState.collapse }));
	};

	render() {
		const { collapse } = this.state;

		return (
			<div className="topbar__profile">
				<button type="button" className="topbar__avatar" onClick={this.toggle}>
					<img
						className="topbar__avatar-img"
						src={localStorage.profile_img}
						alt="avatar"
					/>
					<p className="topbar__avatar-name">{localStorage.name}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && (
					<button
						type="button"
						className="topbar__back"
						onClick={this.toggle}
					/>
				)}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu" onClick={this.toggle}>
						<TopbarMenuLink title="Profile" icon="list" path="/profile" />
						{localStorage.roleSelected != 'test_taker' ? (
							<>
								<div className="topbar__menu-divider" />
								<TopbarMenuLink title="Change Password" icon="warning" path="/chgpwd" />
							</>
						) : null}
						<div className="topbar__menu-divider" />
						<TopbarMenuLink title="Log Out" icon="exit" path="/logout" />
					</div>
				</Collapse>
			</div>
		);
	}
}
