import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import axios from 'axios';
import { MY_API_URL } from './QueryMe';

const apiurl = MY_API_URL;
const initialstate = {
	keyname: '',
};
class TechName extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	static propTypes = {
		technolid: PropTypes.string,
	};
	componentDidMount() {
		this.handleGetkeys();
	}
	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			keyid: this.props.technolid,
		};
		const getuurl = apiurl + 'getkeywordname';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						keyname: response.data.data,
					});
				}
			})
			.catch(() => { });
	};
	render() {
		return <span>{this.state.keyname}</span>;
	}
}

export default TechName;
