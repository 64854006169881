import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';

import SearchIcon from 'mdi-react/SearchIcon';

const apiurl = MY_API_URL;

import {
	Table,
	Button
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	technologies: [],
	selectedTechnologies: [],
	technologyToSearch: '',
	difficulty: 'Beginner',
	responseQuestions: [],
	questionQuantity: [],
	difficultyLevels: [],
	gotCount: [],
	email: '',
	emails: [],
	technologyWise: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,
	options: [],

	questionSelected: {},
	technologySelected: '',
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	currentcolor: [
		'#000000',
	],
	content_add: '',
	myItems: [],
	testEmails: [],
};
class FaqTechView extends Component {
	constructor(props) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {
		this.setState({ myItems: [], testEmails: [] });
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();
			localStorage.setItem('faqtechs', '');
		}
	}
	handleFocus(event) { //eslint-disable-line
		this.setState({ content_add: '' });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}

	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'allfaqkeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	unselectTechnology = (technology) => {
		console.log('Technology is ', technology);
		console.log('Selected Technologies', this.state.selectedTechnologies);
		console.log('Selected difficultyLevels', this.state.difficultyLevels);
		let index = this.state.selectedTechnologies.indexOf(technology);
		console.log('Index is ', index);
		let copyArray = [...this.state.selectedTechnologies];
		let copyDIfArray = [...this.state.difficultyLevels];
		copyArray.splice(index, 1);
		copyDIfArray.splice(index, 1);
		this.setState(
			{
				selectedTechnologies: [...copyArray],
				difficultyLevels: [...copyDIfArray],
			},
			() => {
				console.log('Selected Technologies', this.state.selectedTechnologies);
				console.log('Selected difficultyLevels', this.state.difficultyLevels);
			},
		);
	};

	redirectFaqs = (event) => {
		event.preventDefault();
		var faqtecharray = this.state.selectedTechnologies;
		this.setState(
			{
				showAlert: false,
			},
			() => {
				if (faqtecharray) {
					var faqtechs = faqtecharray.join(',');
					localStorage.setItem('faqtechs', faqtechs);
					const win = window.open('/faqtechsques', '_blank');
					if (win != null) {
						win.focus();
					}
					// this.props.history.push('/faqtechsques');
					// console.log('faqtechs ', faqtechs);
				} else {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				}
			},
		);
	};

	render() {

		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3 order-md-1 order-sm-1 order-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>

								<form className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<SearchIcon />
											</div>
											<input
												name="tkey"
												type="text"
												placeholder="Search"
												onChange={(e) => {
													this.handleSearch(e);
												}}
											/>
										</div>
									</div>
								</form>
								{/* technologies here */}
								<Table responsive hover>
									<tbody>
										{this.state.relatedKeywords.map((keyword, index) => {
											if (
												this.state.selectedTechnologies.indexOf(keyword.id) ===
												-1
											)
												return (
													<tr key={index}>
														<td
															// onClick={() => {
															// 	this.setState({
															// 		technologySelected: keyword.id,
															// 	});
															// }}
															onClick={() => {
																if (
																	this.state.selectedTechnologies.indexOf(
																		keyword.id,
																	) == -1
																) {
																	this.setState(
																		{
																			selectedTechnologies: [
																				...this.state.selectedTechnologies,
																				keyword.id,
																			],
																		},
																		() => {
																			let previousDifficultyArray = this.state
																				.difficultyLevels;
																			previousDifficultyArray.push(
																				keyword.keyword,
																			);
																			let previousQuantityArray = this.state
																				.questionQuantity;
																			previousQuantityArray.push(0);
																			this.setState({
																				difficultyLevels: previousDifficultyArray,
																				questionQuantity: previousQuantityArray,
																			});
																		},
																	);
																}
															}}
															style={{
																background:
																	keyword.id === this.state.technologySelected
																		? 'red'
																		: 'transparent',

																cursor: 'pointer',
															}}
														>
															<strong>{keyword.keyword}</strong>
														</td>
													</tr>
												);
										})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					<div className="col-md-9 order-md-12 order-sm-12 order-1">
						<div className="row">
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										{this.state.selectedTechnologies.map(
											(technology, index) => {
												return (
													<Button
														key={index}
														className="btn-sm icon btn btn-primary"
														style={{ marginLeft: 10 }}
													>
														{this.state.difficultyLevels[index]}

														<i
															className="lnr lnr-cross"
															style={{ marginLeft: 10 }}
															onClick={() => {
																this.unselectTechnology(technology);
															}}
														></i>
													</Button>
												);
											},
										)}
									</div>
								</div>
							</div>

							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<form
											className="form"
											onSubmit={(event) => this.redirectFaqs(event)}
										>
											{this.state.selectedTechnologies.length > 0 ? (
												<button
													className="btn btn-primary account__btn account__btn--small"
													type="submit"
												>
													View FAQ&#39;S
												</button>
											) : (
												<button
													className="btn btn-primary account__btn account__btn--small"
													type="button"
													desabled="true"
												>
													View FAQ&#39;S
												</button>
											)}
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FaqTechView;
