import React, { PureComponent } from 'react';
/* global  */
import PropTypes from 'prop-types';

class Flagimg extends PureComponent {
	constructor (props) {
		super(props);
	}
	static propTypes = {
		clname: PropTypes.string,
		flagname: PropTypes.string,
	};

	render() {


		return (
			<img
				className={this.props.clname}
				src={'flags/' + this.props.flagname + '.svg'}
				alt="flag image"
			/>
		);
	}
}

export default Flagimg;
