import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import Logout from '../Logout';
// import LogIn from '../LogIn/index';
import IndexPanel from '../IndexPanel/index';
import VendorFormPanel from '../IndexPanel/VendorFormPanel';
import Welcome from '../IndexPanel/Welcome';
import ApplyToJob from '../IndexPanel/ApplyToJob';
import AmCandidate from '../IndexPanel/AmCandidate';
import AmEmployer from '../IndexPanel/AmEmployer';
import FaqsPanel from '../FaqsPanel/index';

const wrappedRoutes = () => (
	<div>
		<Layout />
	</div>
);

const Router = () => (
	<MainWrapper>
		<main>
			<Switch>
				<Route
					exact
					path={'/'}
					component={IndexPanel}
				/>
				<Route exact path="/logout" component={Logout} />
				<Route exact path="/login" component={IndexPanel} />
				<Route exact path="/home" component={IndexPanel} />
				<Route exact path="/welcome" component={Welcome} />
				<Route exact path="/applytojob" component={ApplyToJob} />
				<Route exact path="/amcandidate" component={AmCandidate} />
				<Route exact path="/amemployer" component={AmEmployer} />
				<Route exact path="/faqs" component={FaqsPanel} />
				<Route path="/user/:usercode/:compname/:recname" render={(props) => <VendorFormPanel text="Hello, " {...props} />} />
				<Route path="/" component={wrappedRoutes} />
			</Switch>
		</main>
	</MainWrapper>
);

export default withRouter(Router);
