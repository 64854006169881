import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

const apiurl = MY_API_URL;
import {
	Card,
	CardBody,
	Col,
} from 'reactstrap';
const initialstate = {
	token: localStorage.token,
};
class ViewQuest extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'viewquest');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			if (localStorage.viewcustestid) {
				this.handleGetTest(localStorage.viewcustestid);
			} else {
				this.props.history.push('/customsavedtest'); //eslint-disable-line
			}
		}
	}

	handleGetTest = (testid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			testid: testid,
		};
		const getfqurl = apiurl + 'getmytestid';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log(response.data.data[0].testname);
					this.setState({
						newtestname: response.data.data[0].testname,
						questions: [...response.data.testques],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		return (
			<div>
				{this.state.newtestname ? (
					<Col md={8}>
						<Card>
							<CardBody>
								<h5 className="bold-text" style={{ marginBottom: '20px' }}>
									Test Name: {this.state.newtestname}
								</h5>

								{this.state.questions &&
									this.state.questions.map((ques, index) => {
										return (
											<div className="col-md-12 viewques" key={index}>
												<h5
													className="bold-text"
													style={{
														position: 'relative',
														marginTop: '15px',
														marginBottom: '5px',
													}}
												>
													<span className="viewcusquestionic">Q:</span>
													{ques.question}
												</h5>
												<div className="clearfix"></div>
												{ques.questype == 1 ? (
													<div className="row">
														<div
															className={
																ques.correct == 0
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>
															{ques.options[0]}
														</div>

														<div
															className={
																ques.correct == 1
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 2
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">C:</span>
															{ques.options[2]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 3
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">D:</span>
															{ques.options[3]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 2 ? (
													<div className="row">
														<div
															className={
																ques.correct == 0
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>
															{ques.options[0]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 1
																	? 'col-md-12 viewmycusoptionsuccess'
																	: 'col-md-12 viewmycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 3 ? (
													<div className="row">
														<div className="col-md-12 viewmycusoptions">
															Test Taker Need to Write Answer
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
											</div>
										);
									})}
							</CardBody>
						</Card>
					</Col>
				) : null}
			</div>
		);
	}
}

export default ViewQuest;
