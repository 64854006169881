import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import Dashboard from './components/Dashboard';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
import AllAuQues from './components/AllAuQues';
import AllAuAppQues from './components/AllAuAppQues';
import AuKeywords from './components/AuKeywords';
import TechAuKeywords from './components/TechAuKeywords';
import TechCheckQuestions from './components/TechCheckQuestions';
import TechCheckQuestionsEdited from './components/TechCheckQuestionsEdited';
import TechCheckQuestionsRejected from './components/TechCheckQuestionsRejected';
import ListAuKeywords from './components/ListAuKeywords';
import ListAuAgainKeywords from './components/ListAuAgainKeywords';
import ListAuOnholdKeywords from './components/ListAuOnholdKeywords';
import ListAuRepKeywords from './components/ListAuRepKeywords';
import ListFaqKeywords from './components/ListFaqKeywords';
import ListFaqAgainKeywords from './components/ListFaqAgainKeywords';
import StartCheckQues from './components/StartCheckQues';
import StartCheckFaqs from './components/StartCheckFaqs';
import ChgPwd from '../Apanel/ChgPwd';
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class AuditorPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/listautechs" component={ListAuKeywords} />
					<Route path="/listauagaintechs" component={ListAuAgainKeywords} />
					<Route path="/listauonholdtechs" component={ListAuOnholdKeywords} />
					<Route path="/listaureptechs" component={ListAuRepKeywords} />
					<Route path="/listfaqtechs" component={ListFaqKeywords} />
					<Route path="/listfaqagaintechs" component={ListFaqAgainKeywords} />
					<Route path="/checkques" component={StartCheckQues} />
					<Route path="/checkfaqs" component={StartCheckFaqs} />
					<Route path="/autechs" component={AuKeywords} />
					<Route path="/techautechs" component={TechAuKeywords} />
					<Route path="/techcheckques" component={TechCheckQuestions} />
					<Route path="/techcheckquesedited" component={TechCheckQuestionsEdited} />
					<Route path="/techcheckquesrejected" component={TechCheckQuestionsRejected} />
					<Route path="/allauques" component={AllAuQues} />
					<Route path="/allauappques" component={AllAuAppQues} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
					<Route path="/chgpwd" component={ChgPwd} />
				</div>
			</div>
		);
	}
}

export default withRouter(AuditorPanel);
