import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import { Table } from 'react-bootstrap';
import { Card, CardBody, Col, Badge } from 'reactstrap';
var dateFormat = require('dateformat');
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	testTakers: [],
};
class SentTests extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'senttests');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchSentTests();
		}
	}
	// getDetails = (email) => {
	// 	let user =
	// 		this.state.testTakers &&
	// 		this.state.testTakers.length > 0 &&
	// 		this.state.testTakers.filter((tt) => {
	// 			return tt.email === email;
	// 		});
	// 	return user[0];
	// };
	getDetails = (techid) => {
		// console.log('normal ', techid);
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === techid;
		});
		// console.log(techname[0].keyword);
		return techname[0].keyword;
	};

	fetchSentTests = () => {
		this.setState(
			{
				loading: true,
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};
				var postData = {
					email: localStorage.by,
				};
				const getuurl = apiurl + 'fetchRTests';
				axios
					.post(getuurl, postData, {
						headers: headers,
					})
					.then((response) => {
						// console.log(response.data.data);
						this.setState(
							{
								tests: [...response.data.data],
								akeywords: response.data.akeywords,
							},
							() => {
								this.setState({
									loading: false,
								});
							},
						);
					})
					.catch((error) => {
						console.log(error);
					});
			},
		);
	};
	getBadgeColor = (index) => {
		switch (index % 3) {
			case 0:
				return 'badge-purple';
			case 1:
				return 'badge-yellow';
			case 2:
				return 'badge-grey';
		}
	};
	render() {
		let klk = 1;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">List of Sent Tests</h5>
						</div>
						<Table responsive striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Test Id</th>
									<th>Test Taker Details</th>
									<th>Details</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tests &&
									this.state.tests.map((test, index) => {
										if (test.taken === 0) {
											return (
												<tr key={index}>
													<td>{klk++}</td>
													<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
													<td>
														{test.generated_id}
														<br />
														{test.ttype == 'my' ? (
															<Badge color={'success'}>Private</Badge>
														) : null}
													</td>
													<td>
														<b>Email: </b>
														{test.sent_to}
														<br />
														<b>Name: </b>
														{test.name}
														<br />
														<b>Mobile: </b>
														{test.mobile}
													</td>
													<td>
														<Table>
															<thead>
																<tr>
																	<th>Technology</th>
																	<th>No of Questions</th>
																</tr>
															</thead>
															<tbody>
																{test.helper.map((tech, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				{this.getDetails(tech.technology)}
																			</td>
																			<td>{tech.questions}</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</td>
													<td>
														{test.taken === 1 ? 'Taken' : 'Not taken Yet'}
													</td>
												</tr>
											);
										}
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default SentTests;
