import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../../Apanel/QueryMe';
var moment = require('moment');
import {
	Card,
	CardBody,
	Col,
	Table,
} from 'reactstrap';

import ProfileImage from '../../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

let seventhday = moment().subtract(6, 'days').format('ddd MM-DD-YYYY');
let sixthday = moment().subtract(5, 'days').format('ddd MM-DD-YYYY');
let fivethday = moment().subtract(4, 'days').format('ddd MM-DD-YYYY');
let fourthday = moment().subtract(3, 'days').format('ddd MM-DD-YYYY');
let threethday = moment().subtract(2, 'days').format('ddd MM-DD-YYYY');
let twothday = moment().subtract(1, 'days').format('ddd MM-DD-YYYY');
let today = moment().format('ddd MM-DD-YYYY');
let tendays = [
	today,
	twothday,
	threethday,
	fourthday,
	fivethday,
	sixthday,
	seventhday,
];
const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'recruiter',
	email: '',
	domain: '',
	tendays: tendays,
	token: localStorage.token,
};
class RecReportsSevenDaysPage extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'ReportsSevenDaysPage');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetSevendays(localStorage.token);
		}
	}

	handleGetSevendays = (token) => {
		let seventhday = moment().subtract(6, 'days').format('YYYY-MM-DD');
		let sixthday = moment().subtract(5, 'days').format('YYYY-MM-DD');
		let fivethday = moment().subtract(4, 'days').format('YYYY-MM-DD');
		let fourthday = moment().subtract(3, 'days').format('YYYY-MM-DD');
		let threethday = moment().subtract(2, 'days').format('YYYY-MM-DD');
		let twothday = moment().subtract(1, 'days').format('YYYY-MM-DD');
		let today = moment().format('YYYY-MM-DD');
		let tendays = [
			today,
			twothday,
			threethday,
			fourthday,
			fivethday,
			sixthday,
			seventhday,
		];
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			tendays: tendays,
			bossemail: localStorage.by,
		};
		const getuurl = apiurl + 'bosssevendays';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('bosssevendays: ', response.data);
				if (!response.data.error) {
					this.setState({
						allusers: [...response.data.allcomprecs],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">Weekly Report</h5>
						</div>
						<Table responsive hover>
							<thead>
								<tr>
									<th>Recruiter</th>
									<th></th>
									{this.state.tendays.map((aday, index) => {
										return <th key={index}>{aday}</th>;
									})}
								</tr>
							</thead>
							<tbody>
								{this.state.allusers &&
									this.state.allusers.map((auser, index) => {
										let huser = auser.comprec;
										// let dayss = huser.senven;
										console.log(huser.seven);
										return (
											<tr key={index}>
												<td>
													<p>
														<ProfileImage
															clname="topbar__avatar-img"
															flpath={
																huser.image_url
																	? huser.image_url
																	: this.state.image
															}
														/>
														<b style={{ marginLeft: '5px' }}>{huser.name}</b>
													</p>
													<p>
														<b>Email: </b>
														{huser.email}
													</p>
													<p>
														<b>Mobile: </b>
														{huser.mobile}
													</p>
													<p>
														<b>Created: </b>
														{huser.createdon}
													</p>
												</td>
												<td>
													<b>Test Generated: </b>

													<br />
													<b>Test Sent: </b>

													<br />
													<b>Test Taken: </b>

													<br />
													<b>Logins: </b>

													<br />
												</td>
												{huser.seven.map((days, index) => {
													return (
														<td key={index} style={{ textAlign: 'center' }}>
															{days.ttest}
															<br />

															{days.tsent}
															<br />

															{days.ttaken}
															<br />
															{days.logged}
															<br />
														</td>
													);
												})}
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default RecReportsSevenDaysPage;
