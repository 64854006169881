import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import { TagInput } from 'reactjs-tag-input';
import SearchIcon from 'mdi-react/SearchIcon';
import { Button, Card, CardBody, Col, Badge, Table, Modal } from 'reactstrap';

import Alert from '../../../shared/components/Alert';

import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	keyword: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	aliasnames: '',
	editalias: '',
	noorid: 0,
	tags: [],
	addKeyModal: false,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class AddKeywordSearchItems extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys(localStorage.token);
		// this.setaliasmainkeyword(localStorage.token);
		this.onTagsChanged = this.onTagsChanged.bind(this);
		this.setKeywordAlias = this.setKeywordAlias.bind(this);
		this.editKeywordAlias = this.editKeywordAlias.bind(this);
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	setKeywordAlias() {
		if (this.state.noorid && this.state.tags) {
			var tags = this.state.tags;
			var newtags = [];
			tags.map((tag) => {
				newtags.push(tag.displayValue);
			});
			// console.log('tags ', this.state.tags);
			// console.log('newtags ', newtags);
			var newtagsline = newtags.join(', ');
			// console.log('newtags ', newtags);
			console.log('newtagsline ', newtagsline);
			// post started
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const editkeyurl = apiurl + 'addalias';
			var postData = {
				noorid: this.state.noorid,
				keyword: newtagsline,
				keyname: this.state.keyName,
			};
			axios
				.post(editkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'warning',
						});
						this.setState({
							addUserModal: false,
							noorid: 0,
							keyName: '',
							tags: [],
						});
					} else {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'success',
						});
						this.setState(
							{
								addUserModal: false,
								noorid: 0,
								keyName: '',
								tags: [],
							},
							() => {
								this.handleGetkeys(localStorage.token);
							},
						);
					}
				});
			//post ended
		}
	}
	editKeywordAlias() {
		event.preventDefault();
		if (this.state.noorid && this.state.editalias) {
			// post started
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const editkeyurl = apiurl + 'editalias';
			var postData = {
				noorid: this.state.noorid,
				keyword: this.state.editalias,
				keyname: this.state.keyName,
			};
			axios
				.post(editkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'warning',
						});
						this.setState({
							editUserModal: false,
							noorid: 0,
							keyName: '',
							editalias: '',
						});
					} else {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'success',
						});
						this.setState(
							{
								editUserModal: false,
								noorid: 0,
								keyName: '',
								editalias: '',
							},
							() => {
								this.handleGetkeys(localStorage.token);
							},
						);
					}
				});
			//post ended
		}
	}
	handleGetkeys = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'aliasadminkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];
					console.log('new', response.data.nowkeys);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	// setaliasmainkeyword = (token) => {
	// 	const headers = {
	// 		'Content-Type': 'application/json',
	// 		Authorization: localStorage.token,
	// 	};
	// 	var postData = {
	// 		token: token,
	// 	};
	// 	const getuurl = apiurl + 'setaliasmainkeyword';
	// 	axios
	// 		.post(getuurl, postData, {
	// 			headers: headers,
	// 		})
	// 		.then((response) => {
	// 			if (response.data.error) {
	// 			} else {
	// 				console.log(response.data);
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	onTagsChanged(tags) {
		this.setState({ tags });
	}
	handleEditkey = (keyid, keyname, aliasnames, e) => {
		e.preventDefault();
		console.log(keyid);
		this.setState({
			noorid: keyid,
			keyName: keyname,
			editalias: aliasnames,
			editUserModal: true,
			addUserModal: false,
		});
	};
	handleAkey = (keyid, keyname, e) => {
		e.preventDefault();
		console.log(keyid);
		this.setState({
			noorid: keyid,
			keyName: keyname,
			addUserModal: true,
			editUserModal: false,
		});
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
			editUserModal: false,
			tags: [],
		});
	};

	render() {
		const { editalias } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								Technology Keywords{' '}
								<Badge color="primary">Total {this.state.tkeys} </Badge>
								{'  '}
								<Badge color="success">Active {this.state.akeys} </Badge>
								{'  '}
								<Badge color="warning">InActive {this.state.bkeys} </Badge>
								{'  '}
							</h5>
						</div>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>
						{this.state.tshowAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Keyword</th>
									<th>Alias Names</th>
									<th>Add Alias</th>
									<th>Edit & Update</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {

										var aliasnames = hkey.alias;
										aliasnames = aliasnames.replace(/<pre>/gi, '');
										aliasnames = aliasnames.replace(/<\/pre>/gi, '');
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.keyword}</strong>
												</td>

												<td>
													<strong>{aliasnames}</strong>
												</td>

												<td>
													<Button
														className="btn-sm"
														color="success"
														onClick={this.handleAkey.bind(
															this,
															hkey.id,
															hkey.keyword,
														)}
													>
														+
													</Button>
												</td>
												<td>
													<Button
														className="btn-sm"
														color="primary"
														onClick={this.handleEditkey.bind(
															this,
															hkey.id,
															hkey.keyword,
															aliasnames,
														)}
													>
														Edit & Update
													</Button>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.addUserModal}
							size="lg"
							modalClassName="ltr-support"
							className="modal-dialog--primary aliasbox"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
							</div>
							<div className="modal__body">
								<div className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<TagInput
												tags={this.state.tags}
												onTagsChanged={this.onTagsChanged}
											/>
										</div>
									</div>
									<p>
										<br />
									</p>
									<button
										className="btn btn-primary account__btn account__btn--small"
										onClick={this.setKeywordAlias}
									>
										Add Alias to {this.state.keyName}
									</button>
								</div>
							</div>
						</Modal>
						<Modal
							isOpen={this.state.editUserModal}
							size="lg"
							modalClassName="ltr-support"
							className="modal-dialog--primary aliasbox"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
							</div>
							<div className="modal__body">
								<form
									className="form"
									onSubmit={(event) => this.editKeywordAlias(event)}
								>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
									<div className="form__form-group">
										{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
										<div className="form__form-group-field">
											<textarea
												name="editalias"
												value={editalias}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
										<p>
											<br />
										</p>
										<button
											className="btn btn-primary btn-sm float-right"
											type="submit"
										>
											Update Alias {this.state.keyName}
										</button>
									</div>
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AddKeywordSearchItems;
