import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from 'react-router-dom';

import SearchIcon from 'mdi-react/SearchIcon';
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../QueryMe';
import DownloadIcon from 'mdi-react/DownloadIcon'; //eslint-disable-line
import AddIcon from 'mdi-react/EditIcon'; //eslint-disable-line
import EditIcon from 'mdi-react/EditIcon'; //eslint-disable-line
import DeleteIcon from 'mdi-react/DeleteIcon'; //eslint-disable-line
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon'; //eslint-disable-line
import Alert from '../../../shared/components/Alert'; //eslint-disable-line
import swal from 'sweetalert';
import Select from 'react-select'; //eslint-disable-line
const apiurl = MY_API_URL;

import { Card, CardBody, Col, Row, Button, Modal, Container, Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap'; //eslint-disable-line

import classnames from 'classnames';

import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';  //eslint-disable-line
const initialstate = {
	image: localStorage.profile_img,
	editprofile: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	name: '',
	secondary_phone: '',
	skype: '',
	current_location: '',
	passport: '',
	ssn: '',
	dob: '',
	work_auth: '',
	employer_info: '',
	relocation: '',
	availability: '',
	linkedin: '',
	resume: '',
	editex_resume: [],
	filteredkeywords: [],
	selectedTechnologies: [],
	technologyToSearch: '',

};
class ProfileCard extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1',
		};
		this.handleAddSkill = this.handleAddSkill.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
	}
	state = { ...initialstate };
	toggle(tab) {
		const { activeTab } = this.state;
		if (activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			this.props.history.push({
				pathname: '/profileedit',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();
		}
		if (localStorage.profile_img) {
			this.setState({
				image: localStorage.profile_img,
			});
		} else {
			this.setState({
				image: DUM_IMG_URL,
			});
		}
	}
	handleClick(kid) {
		swal({
			title: "Are you sure?",
			text: "Are you sure that you want to Delete this Skill?",
			icon: "warning",
			dangerMode: true,
			buttons: true,
		})
			.then(willDelete => {
				if (willDelete) {
					const idToRemove = kid;
					console.log('idToRemove', idToRemove);
					const newArray = this.state.editex_resume.filter((listitem) => {
						console.log('listitem.kid', listitem.kid);
						return listitem.kid !== idToRemove;
					});

					this.setState({ editex_resume: newArray }, () => {
						console.log('new editex_resume ', this.state.editex_resume);
						// swal("Deleted!", "Your Skill has been deleted!", "success");
					});

				}
			});

	}

	handleAddSkill = () => {
		this.setState({
			showAddSkillbox: true,
		});

	};



	handleEditSkillBox = () => {
		this.setState({
			showSkillsbox: true,
			showAlert: false,
			alertType: '',
			alertText: '',
			editex_resume: this.state.ex_resume
		});
	};
	handleCkey = () => {
		this.setState({
			showSkillsbox: false,
			showAlert: false,
			alertType: '',
			alertText: '',
		});
	};




	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleSkillsSubmit = () => {


		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		var postData = {
			editex_resume: this.state.editex_resume,
			uid: localStorage.usercode,
		};
		const pupurl = apiurl + 'editex_resume';
		axios
			.post(pupurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('Response is ', response.data);
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState({
						showAlert: false,
						showSkillsbox: false,
						editex_resume: ''
					}, () => {
						this.handleGetkeys();
					});
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try again Later!',
				});
			});

	};

	handleGetkeys = () => {
		console.log('localStorage.jstatus', localStorage.jstatus);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			uid: localStorage.usercode,
		};
		const getuurl = apiurl + 'myprofile';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then(async (response) => {
				if (!response.data.error) {
					//console.log('data', response.data);
					if (response.data.profile) {
						var aex_resume = response.data.resumed.ex_resume;
						const alldash_skills = response.data.alldash_skills;
						// console.log('alldash_skills', alldash_skills);
						var exr = ['0'];
						if (aex_resume.length >= 1) {
							aex_resume = JSON.parse(aex_resume);
							console.log('aex_resume', aex_resume);

							aex_resume.map((exskill) => {
								exr.push(exskill.kid);
							});
						} else {
							aex_resume = [];
						}

						var allkeywords = [];
						var filteredskills = [];
						var filteredkeywords = [];
						//////////////////////////////////////
						const unifiedCount = await Promise.all( //eslint-disable-line
							alldash_skills.map((zskill) => {
								return new Promise((resolve, reject) => { //eslint-disable-line
									// prekeys.push(prekeyword.id);
									// prekeywords.push(prekeyword.keyword);
									var dnjk = exr.includes(zskill.id);

									if (dnjk) {
										filteredskills.push({ "kid": zskill.id, "skill": zskill.keyword, "exp": "", "last_used": "" });
									} else {
										filteredkeywords.push({ "kid": zskill.id, "skill": zskill.keyword, "exp": "", "last_used": "" });
									}
									allkeywords.push({ "kid": zskill.id, "skill": zskill.keyword, "exp": "", "last_used": "" });

									resolve('ok');

								});
							}),
						);
						/////////////////////////////////////

						this.setState({
							filteredkeywords: filteredkeywords,
							filteredskills: filteredskills,
							allkeywords: allkeywords,
							secondary_phone: response.data.profile.secondary_phone,
							skype: response.data.profile.skype,
							current_location: response.data.profile.current_location,
							passport: response.data.profile.passport,
							ssn: response.data.profile.ssn,
							dob: response.data.profile.dob,
							work_auth: response.data.profile.work_auth,
							employer_info: response.data.profile.employer_info,
							relocation: response.data.profile.relocation,
							availability: response.data.profile.availability,
							linkedin: response.data.profile.linkedin,
							resume: response.data.resumed.resume,
							ex_resume: aex_resume,
							resumed: response.data.resumed.resume_data,

						}, () => {
							console.log('ex_resume', this.state.ex_resume);
							console.log('filteredskills', this.state.filteredskills);
							console.log('allkeywords', this.state.allkeywords);

						});
					} else {
						this.props.history.push('/profileedit'); //eslint-disable-line
					}

				} else {
					localStorage.clear();
					this.props.history.push('/dashboard'); //eslint-disable-line
				}
			})
			.catch((error) => {
				console.log(error);
				//localStorage.clear();
				//this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};


	handlerelocationChange = secoption => {
		var relocation = secoption.value;
		this.setState({ relocation });
		console.log(`relocation selected:`, relocation);
	};
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						filteredkeywords: [...this.state.allkeywords],
					});
				} else {
					let filteredkeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						filteredkeywords: [...filteredkeywords],
					});
				}
			},
		);
	};

	render() {
		const { email, prefixoptions, prefix, name, mobile, secondary_phone, skype, current_location, passport, ssn, dob, work_auth, employer_info, relocation, availability, linkedin, resume, ex_resume, dash_skill } = this.state; //eslint-disable-line
		const options = [//eslint-disable-line
			{ value: 'Yes', label: 'Yes' },
			{ value: 'No', label: 'No' },
		];
		console.log('this.state.ex_resume', this.state.ex_resume);
		const { activeTab } = this.state;
		return (
			<Container className="dashboard">
				<div className="profile">
					<Row>
						<Col md={12} lg={12} xl={4}>
							<Row>
								<Col md={12} lg={12} xl={12}>
									<Card>
										<CardBody className="profile__card">
											<div className="profile__information">
												<div className="profile__avatar">
													<img src={this.state.image} alt="Profile Image" />
												</div>
												<div className="profile__data">
													<p className="profile__name">{localStorage.name}</p>
													<p className="profile__work">Senior Account Manager</p>
													<p className="profile__contact">{localStorage.by}</p>
													<p className="profile__contact" dir="ltr">{localStorage.mobile}</p>
													<Row>
														<Col md={6}>
															{this.state.editprofile ? null : (

																<Link
																	color="primary" className="btn btn-primary float-right" style={{ padding: '3px 10px' }}
																	to="/profileedit"
																>
																	<EditIcon /> Profile
																</Link>
															)}
														</Col>
														<Col md={6}><a className="btn btn-primary" style={{ padding: '3px 10px' }} href={resume} ><DownloadIcon /> Resume</a></Col>
													</Row>


												</div>
											</div>
											<div className="profile__stats">
												<div className="profile__stat">
													<p className="profile__stat-number">05</p>
													<p className="profile__stat-title">Tests Taken</p>
												</div>
												<div className="profile__stat">
													<p className="profile__stat-number">24</p>
													<p className="profile__stat-title">Jobs Applied</p>
												</div>
												{/* <div className="profile__stat">
													<p className="profile__stat-number">12</p>
													<p className="profile__stat-title">Reports</p>
												</div> */}
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col md={12} lg={12} xl={8}>
							<CardBody>
								<div className="profile__card tabs tabs--bordered-bottom">
									<div className="tabs__wrap">
										<Nav tabs>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === '1' })}
													onClick={() => {
														this.toggle('1');
													}}
												>Profile</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === '2' })}
													onClick={() => {
														this.toggle('2');
													}}
												>View Resume</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === '3' })}
													onClick={() => {
														this.toggle('3');
													}}
												>Skills</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === '4' })}
													onClick={() => {
														this.toggle('3');
													}}
												>Marketing</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({ active: activeTab === '5' })}
													onClick={() => {
														this.toggle('4');
													}}
												>Others</NavLink>
											</NavItem>
										</Nav>
										<TabContent activeTab={activeTab}>
											<TabPane tabId="1">
												<Card>
													<div className="project-summary">
														{/* <div className="card__title project-summary__card-title">
																<h5 className="bold-text">Nes website for Global company</h5>
															</div> */}
														{/* const { , , , , , , , , , , , , , , employer_info, relocation, availability, linkedin, resume, } = this.state; */}
														<table className="project-summary__info">
															<tbody>
																<tr>
																	<th>Full Name:</th>
																	<td>{localStorage.name}</td>
																</tr>
																<tr>
																	<th>Primary Contact:</th>
																	<td>{localStorage.mobile}</td>
																</tr>
																<tr>
																	<th>Secondary Contact:</th>
																	<td>{secondary_phone}</td>
																</tr>
																<tr>
																	<th>DOB:</th>
																	<td>{dob}</td>
																</tr>
																<tr>
																	<th>Availability:</th>
																	<td>{availability}</td>
																</tr>
																<tr>
																	<th>Current Location:</th>
																	<td>{current_location}</td>
																</tr>
																<tr>
																	<th>Relocation:</th>
																	<td>{relocation}</td>
																</tr>
																<tr>
																	<th>Skype ID:</th>
																	<td>{skype}</td>
																</tr>
																<tr>
																	<th>Linkedin:</th>
																	<td>{linkedin}</td>
																</tr>
																<tr>
																	<th>Passport no:</th>
																	<td>{passport}</td>
																</tr>
																<tr>
																	<th>SSN last 4 digit nos:</th>
																	<td>{ssn}</td>
																</tr>
																<tr>
																	<th>Work Authorization:</th>
																	<td>{work_auth}</td>
																</tr>
																<tr>
																	<th>Employer info if any:</th>
																	<td>{employer_info}</td>
																</tr>
																<tr>
																	<th>Resume:</th>
																	<td><a className="btn btn-primary" href={resume} >Download Resume</a></td>
																</tr>
															</tbody>
														</table>
														{/* <p className="typography-message">
															Knowledge nay estimable questions repulsive daughters boy. Solicitude gay way unaffected expression for. </p> */}

														<hr />

													</div>

												</Card>
											</TabPane>
											<TabPane tabId="2">
												<Col md={12} style={{ marginBottom: '5px' }}>
													<div dangerouslySetInnerHTML={{ __html: this.state.resumed, }} />
													{/* {this.state.resumed} */}
												</Col>
											</TabPane>
											<TabPane tabId="3">
												<Col md={12} style={{ marginBottom: '5px' }}>
													<Table responsive striped bordered hover>
														<thead>
															<tr>
																<th><span
																	className="editskill"
																	onClick={this.handleEditSkillBox}
																><EditIcon /></span></th>
																<th>Skill</th>
																<th colSpan="2"> </th>
															</tr>
														</thead>
														<tbody>
															{this.state.ex_resume &&
																this.state.ex_resume.map((tech, index) => {
																	return (
																		<tr key={index}>
																			<td> {index + 1} </td>
																			<td>{tech.skill}{tech.kid}</td>
																			<td>Result Meter</td>

																			<td><span className="btn btn-primary btn-sm">Take Test</span></td>
																		</tr>

																	);
																})}
														</tbody>
													</Table>


												</Col>
											</TabPane>
											<TabPane tabId="4">
												<p>third</p>
											</TabPane>
											<TabPane tabId="5">
												others

											</TabPane>
										</TabContent>
									</div>
								</div>
							</CardBody>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={this.state.showSkillsbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title" style={{ marginBottom: '40px' }}><b>Manage Skills</b>

						</h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}




						<Row style={{ width: '100%' }}>
							<Col md={4}>
								<div className="grid card">
									<div className="card-body">
										<h5 className="bold-text">Technologies </h5>

										<form className="form">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<SearchIcon />
													</div>
													<input
														name="tkey"
														type="text"
														placeholder="Search"
														onChange={(e) => {
															this.handleSearch(e);
														}}
													/>
												</div>
											</div>
										</form>
										{/* technologies here */}
										<Table responsive hover>
											<tbody>
												{this.state.filteredkeywords.map((keyword, index) => {

													// var ifmatched = this.state.prekeys.includes(keyword.id);
													// if (ifmatched) {

													// }
													if (
														this.state.selectedTechnologies.indexOf(keyword.kid) ===
														-1
													) {
														return (
															<tr key={index}>
																<td>
																	<strong>{keyword.skill}</strong>
																</td>
															</tr>
														);
													}
												})}
											</tbody>
										</Table>
									</div>
								</div>

							</Col>

							<Col md={8}>
								{this.state.editex_resume &&
									this.state.editex_resume.map((tech, index) => {
										return (
											<Col md={6} key={index} className="skillslist">
												<Row style={{ width: '100%' }}>
													<Col md={10}>{tech.skill}</Col>
													<Col md={2}>
														<span
															className="deleteskill"
															onClick={this.handleClick.bind(this, tech.kid)}

														><DeleteIcon /></span>
													</Col>
												</Row>



											</Col>


										);
									})}

							</Col>

							<Col md={{ size: 12, offset: 8 }}><br />
								{this.state.blockbtn == 2 ? (
									<span
										className="btn btn-primary account__btn account__btn--small"
										desabled="true"
									>
										Submit Candidate Details
									</span>
								) : (

									<button
										className="btn btn-primary account__btn account__btn--small"
										onClick={this.handleSkillsSubmit}
									>
										Save Skills
									</button>
								)}

							</Col>

						</Row>




					</div>
				</Modal>
			</Container >
		);
	}
}

export default withRouter(ProfileCard);
