import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from './QueryMe';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import Alert from '../../shared/components/Alert';
import { Link, withRouter } from 'react-router-dom';
const apiurl = MY_API_URL;

const initialstate = {
	email: localStorage.by,
	showAlert: false,
	alertType: '',
	alertText: '',
	pwd: '',
	cpwd: '',
	profile: null,
	failbtn: false,
};
class ChgPwd extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };

	componentDidMount() {

	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleProfileSubmit = (event) => {
		event.preventDefault();
		if (this.state.pwd != this.state.cpwd) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Password and Confirm Password Not Matched",
			});
		} else {


			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				email: this.state.email,
				pwd: this.state.pwd,
				role: localStorage.roleSelected,
			};
			const pupurl = apiurl + 'updateUserPwd';
			axios
				.post(pupurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log('Response is ', response.data);
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState(
							{
								showAlert: true,
								alertType: 'success',
								alertText: response.data.message,
								pwd: '',
								cpwd: '',
							}
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Request Failed Try again Later!',
					});
				});

		}
	};

	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};
	render() {
		const {
			failbtn,
			pwd,
			cpwd,
			showPassword
		} = this.state;


		return (
			<div className="account">
				<div className="account__wrapper">
					<div className="account__card">
						<div className="account__head">
							<h3 className="account__title">Change Password</h3>
							<h4 className="account__subhead subhead">
								<Link to="/dashboard">Back to Dashboard</Link>
							</h4>
						</div>

						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleProfileSubmit(event)}
						>
							<div className="form__form-group">
								<span className="form__form-group-label">Your Password</span>
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<KeyVariantIcon />
									</div>

									<input
										name="pwd"
										type={showPassword ? 'text' : 'password'}
										placeholder="Your Password"
										value={pwd}
										onChange={this.handleChange}
										pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
										title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
										required
										onKeyUp={(e) => {
											e.preventDefault();
											if (this.state.pwd != this.state.cpwd) {
												this.setState({
													showAlert: true,
													alertType: 'warning',
													alertText: "Password and Confirm Password Not Matched",
												});
											} else {
												this.setState({
													showAlert: false,
													alertType: 'success',
													alertText: "Password and Confirm Password  Matched",
												});
											}
										}}
									/>
									<button
										className={`form__form-group-button${showPassword ? ' active' : ''
											}`}
										onClick={(e) => this.showPassword(e)}
										type="button"
									>
										<EyeIcon />
									</button>
								</div>
							</div>

							<div className="form__form-group">
								<span className="form__form-group-label">Confirm Password</span>
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<KeyVariantIcon />
									</div>

									<input
										name="cpwd"
										type={showPassword ? 'text' : 'password'}
										placeholder="Confirm Password"
										value={cpwd}
										onChange={this.handleChange}
										pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
										title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
										required
										onKeyUp={(e) => {
											e.preventDefault();
											if (this.state.pwd != this.state.cpwd) {
												this.setState({
													showAlert: true,
													alertType: 'warning',
													alertText: "Password and Confirm Password Not Matched",
												});
											} else {
												this.setState({
													showAlert: false,
													alertType: 'success',
													alertText: "Password and Confirm Password  Matched",
												});
											}
										}}
									/>
									<button
										className={`form__form-group-button${showPassword ? ' active' : ''
											}`}
										onClick={(e) => this.showPassword(e)}
										type="button"
									>
										<EyeIcon />
									</button>
								</div>
							</div>

							<button
								className="btn btn-primary account__btn account__btn--small"
								// onClick={this.handleProfileSubmit}
								type="submit"
								disabled={failbtn}
							>
								Change Password
							</button>
						</form>
					</div>
				</div>
			</div>


		);
	}
}

export default withRouter(ChgPwd);
