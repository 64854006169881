import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

import TechName from '../../Apanel/TechName';
const apiurl = MY_API_URL;
import {
	Table,
	Badge,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	showQues: false,
	technology: '',
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class TechQues extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetkeys();
		}
	}
	handleSingle = (technology) => {
		console.log(technology);

		this.setState(
			{
				showQues: true,
				technology: technology,
			},
			() => {
				this.fetchQuestions();
			},
		);
	};
	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			technology: this.state.technology,
		};
		const getfqurl = apiurl + 'fetchQues';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const alltques = [...response.data.data];

					let akeys = 0;

					let tkeys = 0;
					alltques.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						questions: [...response.data.data],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'mykeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					var nokeys = response.data.allkeys.length >= 1 ? false : true;
					this.setState({
						keywords: [...response.data.allkeys],
						keyques: [...response.data.allkeyques],
						showAlert: nokeys,
						alertText: 'No Questions Added',
						alertType: 'warning',
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		return (
			<div className="col-md-12">
				<div className="row">
					{/* <div className="col-md-12">
						<div className="grid card">
							<div className="card-body"> */}
					{/* {this.state.keywords.map((keyword, index) => {
									return (
										<Button
											key={index}
											color="success"
											id={keyword.technology}
											onClick={this.handleSingle.bind(this, keyword.technology)}
										>
											{keyword.technology}
										</Button>
									);
								})} */}
					{/* {this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null} */}
					{/* </div>
						</div>
					</div> */}
					<div className="col-md-12">
						<div className="grid card">
							<div className="card-body">
								<div className="card__title">
									<h5 className="bold-text">
										All Questions Filtered by Technologies
									</h5>
								</div>
								{/* <h5 className="bold-text">
									Entered Questions{' '}
									<Badge color="primary">Total {this.state.tkeys} </Badge>
									{'  '}
									<Badge color="success">Aproved {this.state.akeys} </Badge>
									{'  '}
									<Badge color="warning">Pending {this.state.bkeys} </Badge>
								</h5> */}
								{/* {this.state.showQues ? (
									<Table responsive hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Technology</th>
												<th>Status</th>
												<th>Created</th>
												<th>Question</th>
											</tr>
										</thead>
										<tbody>
											{this.state.questions &&
												this.state.questions.map((ques, index) => {
													return (
														<tr key={index}>
															<td>{index + 1}</td>

															<td>
																<strong>{ques.technology}</strong>
															</td>
															<td>
																<strong>
																	<Badge
																		color={
																			ques.status == 'Pending'
																				? 'warning'
																				: 'success'
																		}
																	>
																		{ques.status}
																	</Badge>
																</strong>
															</td>
															<td>
																<strong>{ques.created_at}</strong>
															</td>
															<td>
																<strong>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: ques.question,
																		}}
																	/>
																</strong>
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								) : null} */}
								{this.state.keyques ? (
									<Table responsive hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Technology</th>
												<th>Total</th>
												<th>Approved</th>
												<th>Pending</th>
											</tr>
										</thead>
										<tbody>
											{this.state.keyques &&
												this.state.keyques.map((ques, index) => {
													return (
														<tr key={index}>
															<td>{index + 1}</td>

															<td>
																<strong>
																	<TechName technolid={ques.technology} />
																</strong>
															</td>
															<td>
																<Badge
																	style={{ fontSize: '15px' }}
																	color="primary"
																>
																	{ques.allq}
																</Badge>
															</td>
															<td>
																<Badge
																	style={{ fontSize: '15px' }}
																	color="success"
																>
																	{ques.approved}
																</Badge>
															</td>
															<td>
																<Badge
																	style={{ fontSize: '15px' }}
																	color="warning"
																>
																	{ques.pending}
																</Badge>
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TechQues;
