import React, { Component } from 'react';
/* global  */
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import { Table } from 'react-bootstrap';
import {
	Container,
	Row,
	Card,
	CardBody,
	Col,
	Badge,
	FormGroup,
	Label,
	Input,
} from 'reactstrap';
import ResultMeter from '../../Apanel/ResultMeter';
import CheckProfileDone from '../../Apanel/CheckProfileDone';
var dateFormat = require('dateformat'); // eslint-disable-line
import TotalProducts from '../../AdminPanel/ecomponents/TotalProducts';
import TotalProfit from '../../AdminPanel/ecomponents/TotalProfit';

var moment = require('moment'); // eslint-disable-line
const apiurl = MY_API_URL;
const initialstate = {
	comprec: [{ name: 'Page A', amt: '0' }],

	ttest: [{ name: 'Page A', uv: '0' }],
	tsent: [{ name: 'Page A', uv: '0' }],
	ttaken: [{ name: 'Page A', uv: '0' }],
	flogged: [{ name: 'Page A', uv: '0' }],

	acomprec: 0,

	atsent: 0,
	attaken: 0,
	atflogged: 0,
	alltests: [],
	senttests: [],
	comptests: [],
	allfaqs: [],
	akeywords: [],
	showtickets: 1,
};
class ReportsPage extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		this.handleGetTendays(localStorage.token);
		this.handleGetAllcnts(localStorage.token);
		this.handleGetAllTests(localStorage.token);
	}

	handleGetTendays = (token) => {
		let tenthday = moment().subtract(9, 'days').format('YYYY-MM-DD');
		let ninethday = moment().subtract(8, 'days').format('YYYY-MM-DD');
		let eightthday = moment().subtract(7, 'days').format('YYYY-MM-DD');
		let seventhday = moment().subtract(6, 'days').format('YYYY-MM-DD');
		let sixthday = moment().subtract(5, 'days').format('YYYY-MM-DD');
		let fivethday = moment().subtract(4, 'days').format('YYYY-MM-DD');
		let fourthday = moment().subtract(3, 'days').format('YYYY-MM-DD');
		let threethday = moment().subtract(2, 'days').format('YYYY-MM-DD');
		let twothday = moment().subtract(1, 'days').format('YYYY-MM-DD');
		let today = moment().format('YYYY-MM-DD');
		let tendays = [
			today,
			twothday,
			threethday,
			fourthday,
			fivethday,
			sixthday,
			seventhday,
			eightthday,
			ninethday,
			tenthday,
		];
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			tendays: tendays,
			cemail: localStorage.by,
		};
		const getuurl = apiurl + 'comptendays';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('comptendays: ', response.data);
				if (!response.data.error) {
					this.setState({
						comprec: response.data.comprec,
						ttest: response.data.ttest,
						tsent: response.data.tsent,
						ttaken: response.data.ttaken,
						flogged: response.data.flogged,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleGetAllcnts = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			cemail: localStorage.by,
		};
		const getuurl = apiurl + 'compreportcnt';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('compreportcnt', response.data);
				if (!response.data.error) {
					this.setState({
						acomprec: response.data.acomprec,
						attest: response.data.attest,
						atsent: response.data.atsent,
						attaken: response.data.attaken,
						atflogged: response.data.atflogged,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	handleGetAllTests = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			email: localStorage.by,
		};
		const getuurl = apiurl + 'companyreporttests';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				// console.log('reportcnt', response.data);
				if (!response.data.error) {
					// console.log('akeywords', response.data.akeywords);
					this.setState({
						alltests: response.data.alltests,
						senttests: response.data.senttests,
						comptests: response.data.comptests,
						allfaqs: response.data.allfaqs,
						akeywords: response.data.akeywords,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	timespent = (given_seconds) => {
		let hours = Math.floor(given_seconds / 3600);
		let minutes = Math.floor((given_seconds - hours * 3600) / 60);
		let seconds = given_seconds - hours * 3600 - minutes * 60;

		let timeString =
			hours.toString().padStart(2, '0') +
			':' +
			minutes.toString().padStart(2, '0') +
			':' +
			seconds.toString().padStart(2, '0');

		return timeString;
	};
	getDetails = (techid) => {
		// console.log('normal ', techid);
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === techid;
		});
		// console.log(techname[0].keyword);
		return techname[0].keyword;
	};
	getFDetails = (techid) => {
		// console.log('faq ', techid);
		techid = parseInt(techid, 0);
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === techid;
		});
		// console.log(techname[0].keyword);
		return techname[0].keyword;
	};
	handleTicketChange(e1, e2) {
		e2.preventDefault();
		this.setState(
			{
				showtickets: e1,
			},
			() => {
				console.log('showtickets', this.state.showtickets);
			},
		);
	}

	render() {
		const {
			ttest,
			tsent,
			ttaken,
			attest,
			atsent,
			attaken,
			flogged,
			atflogged,
			showtickets,
		} = this.state;
		const mystyle = {
			color: 'green',
			cursor: 'pointer',
			fontSize: '22px',
		};
		return (
			<Container className="dashboard">
				<CheckProfileDone />
				{/* <Row>
					<TotalProfit
						tname="Company Recruiters"
						totaln={acomprec}
						rdata={comprec}
					/>
				</Row> */}
				<Row>
					<TotalProducts
						tname="Tests Generated"
						totaln={attest}
						rdata={ttest}
					/>
					<TotalProducts tname="Tests Sent" totaln={atsent} rdata={tsent} />
					<TotalProfit tname="Tests Taken" totaln={attaken} rdata={ttaken} />

					<TotalProducts
						tname="Faqs Searched"
						totaln={atflogged}
						rdata={flogged}
					/>
				</Row>
				<Row>
					<Card>
						<CardBody>
							<Table responsive hover>
								<thead>
									<tr>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="radio"
														name="radio1"
														checked={showtickets == 1 ? 1 : 0}
														onChange={this.handleTicketChange.bind(this, 1)}
													/>{' '}
													Tests Generated
												</Label>
											</FormGroup>
										</th>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="radio"
														name="radio1"
														checked={showtickets == 2 ? 1 : 0}
														onChange={this.handleTicketChange.bind(this, 2)}
													/>{' '}
													Tests Sent
												</Label>
											</FormGroup>
										</th>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="radio"
														name="radio1"
														checked={showtickets == 3 ? 1 : 0}
														onChange={this.handleTicketChange.bind(this, 3)}
													/>{' '}
													Tests Taken
												</Label>
											</FormGroup>
										</th>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="radio"
														name="radio1"
														checked={showtickets == 4 ? 1 : 0}
														onChange={this.handleTicketChange.bind(this, 4)}
													/>{' '}
													Faqs
												</Label>
											</FormGroup>
										</th>
									</tr>
								</thead>
							</Table>
						</CardBody>
					</Card>
				</Row>
				{showtickets == 1 ? (
					<Row>
						<Card>
							<CardBody>
								<Col md={12}>
									<div className="card__title">
										<h5 className="bold-text">All Tests </h5>
									</div>

									<Table striped bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Test Id</th>
												<th>Recruiter Details</th>
												<th>Details</th>
											</tr>
										</thead>
										<tbody>
											{this.state.alltests &&
												this.state.alltests.map((test, index) => {
													let techarrayhelp = [];
													return (
														<tr key={index}>
															<td>{test.id}</td>
															<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
															<td>
																{test.generated_id}
																<br />
																{test.ttype == 'my' ? (
																	<Badge color={'success'}>Private</Badge>
																) : null}
															</td>
															<td>
																<b>Email: </b>
																{test.remail}
																<br />
																<b>Name: </b>
																{test.rname}
																<br />
																<b>Mobile: </b>
																{test.rmobile}
																<br />
																<b>Type: </b>
																{test.rtype != ''
																	? 'Company Recruiter'
																	: 'Recruiter'}
															</td>
															<td>
																<Table>
																	<thead>
																		<tr>
																			<th>Technology</th>
																			<th>No of Questions</th>
																		</tr>
																	</thead>
																	<tbody>
																		{test.helper.map((tech, index) => {
																			techarrayhelp.push(tech.technology);
																			return (
																				<tr key={index}>
																					<td>
																						{this.getDetails(tech.technology)}
																					</td>

																					<td>{tech.questions}</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</Table>
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Col>
							</CardBody>
						</Card>
					</Row>
				) : null}
				{showtickets == 2 ? (
					<Row>
						<Card>
							<CardBody>
								<Col md={12}>
									<div className="card__title">
										<h5 className="bold-text">All Tests Sent</h5>
									</div>
									<Table striped bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Test Id</th>
												<th>Recruiter Details</th>
												<th>Test Taker Details</th>
												<th>Details</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											{this.state.senttests &&
												this.state.senttests.map((test, index) => {
													// console.log('Details are ', details);

													return (
														<tr key={index}>
															<td>{test.id}</td>
															<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
															<td>
																{test.generated_id}
																<br />
																{test.ttype == 'my' ? (
																	<Badge color={'success'}>Private</Badge>
																) : null}
															</td>
															<td>
																<b>Email: </b>
																{test.remail}
																<br />
																<b>Name: </b>
																{test.rname}
																<br />
																<b>Mobile: </b>
																{test.rmobile}
																<br />
																<b>Type: </b>
																{test.rtype != ''
																	? 'Company Recruiter'
																	: 'Recruiter'}
															</td>
															<td>
																<b>Email: </b>
																{test.sent_to}
																<br />
																<b>Name: </b>
																{test.name}
																<br />
																<b>Mobile: </b>
																{test.mobile}
															</td>
															<td>
																<Table>
																	<thead>
																		<tr>
																			<th>Technology</th>
																			<th>No of Questions</th>
																		</tr>
																	</thead>
																	<tbody>
																		{test.helper.map((tech, index) => {
																			return (
																				<tr key={index}>
																					<td>
																						{this.getDetails(tech.technology)}
																					</td>
																					<td>{tech.questions}</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</Table>
															</td>
															<td>
																{test.taken === 1 ? 'Taken' : 'Not taken Yet'}
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Col>
							</CardBody>
						</Card>
					</Row>
				) : null}
				{showtickets == 3 ? (
					<Row>
						<Card>
							<CardBody>
								<Col md={12}>
									<div className="card__title">
										<h5 className="bold-text">All Tests Taken</h5>
									</div>
									<Table striped bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Test Id</th>
												<th>Recruiter Details</th>
												<th>Test Taker Details</th>
												<th>
													Time Taken
													<br />
													HH:MM:SS
												</th>
												<th>Details</th>
												<th>% of Score</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{this.state.comptests &&
												this.state.comptests.map((test, index) => {
													var allresults = test.results;
													// console.log(test.helper);
													var myresult = test.helper;
													// console.log(myresult);
													// console.log('results here ', allresults);
													let totalCorrect = 0;
													// let totalIncorrect = 0;
													// let totalUnattempted = 0;
													let totalquestions = 0;

													allresults.map((result) => {
														totalCorrect += result.correct;
														// totalIncorrect += result.incorrect;
														// totalUnattempted += result.unattempted;
													});
													myresult.map((resul) => {
														resul.questions = parseInt(resul.questions);
														totalquestions += resul.questions;
													});
													totalCorrect = (100 / totalquestions) * totalCorrect;
													return (
														<tr key={index}>
															<td>{test.id}</td>
															<td>{dateFormat(test.taken_at, 'd-m-yyyy')}</td>
															<td>{test.generated_id}</td>
															<td>
																<b>Email: </b>
																{test.remail}
																<br />
																<b>Name: </b>
																{test.rname}
																<br />
																<b>Mobile: </b>
																{test.rmobile}
																<br />
																<b>Type: </b>
																{test.rtype != ''
																	? 'Company Recruiter'
																	: 'Recruiter'}
															</td>
															<td>
																<b>Email: </b>
																{test.sent_to}
																<br />
																<b>Name: </b>
																{test.name}
																<br />
																<b>Mobile: </b>
																{test.mobile}
															</td>
															<td>{this.timespent(test.nqno)}</td>
															<td>
																<Table>
																	<thead>
																		<tr>
																			<th>Technology</th>
																			<th>No of Questions</th>
																		</tr>
																	</thead>
																	<tbody>
																		{test.helper.map((tech, index) => {
																			return (
																				<tr key={index}>
																					<td>
																						{this.getDetails(tech.technology)}
																					</td>
																					<td>{tech.questions}</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</Table>
															</td>
															<td>
																<ResultMeter
																	marks={totalCorrect}
																	mmarks={totalquestions}
																/>
															</td>
															<td>
																{test.taken === 1 ? (
																	<div>
																		{/* <i
																className="lnr lnr-download"
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	this.fetchTest(test.id, test.sent_to);
																}}
															></i> */}
																		<i
																			className="lnr lnr-eye"
																			style={mystyle}
																			onClick={() => {
																				this.props.history.push({ //eslint-disable-line
																					pathname: '/testreport',
																					testId: test.id,
																					email: test.sent_to,
																				});
																			}}
																		></i>
																	</div>
																) : (
																		''
																	)}
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Col>
							</CardBody>
						</Card>
					</Row>
				) : null}
				{showtickets == 4 ? (
					<Row>
						<Card>
							<CardBody>
								<Col md={12}>
									<div className="card__title">
										<h5 className="bold-text">Faqs Viewed</h5>
									</div>
									<Table striped bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Recruiter Details</th>
												<th>Technology Details</th>
											</tr>
										</thead>
										<tbody>
											{this.state.allfaqs &&
												this.state.allfaqs.map((faq, index) => {
													// console.log('Details are ', details);
													let rawtechs = faq.techids;

													let alltechs = rawtechs.split(',');

													return (
														<tr key={index}>
															<td>{faq.id}</td>
															<td>{dateFormat(faq.posted, 'd-m-yyyy')}</td>

															<td>
																<b>Email: </b>
																{faq.remail}
																<br />
																<b>Name: </b>
																{faq.rname}
																<br />
																<b>Mobile: </b>
																{faq.rmobile}
																<br />
																<b>Type: </b>
																{faq.rtype != ''
																	? 'Company Recruiter'
																	: 'Recruiter'}
															</td>

															<td>
																{alltechs &&
																	alltechs.map((tech, index) => {
																		return (
																			<div key={index}>
																				{this.getFDetails(tech)}
																			</div>
																		);
																	})}
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Col>
							</CardBody>
						</Card>
					</Row>
				) : null}
			</Container>
		);
	}
}

export default ReportsPage;
