import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import SearchIcon from 'mdi-react/SearchIcon';
import {
	Card,
	CardBody,
	Col,
	Badge,
	Table,
} from 'reactstrap';

const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	keyword: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class AuKeywords extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys();
	}

	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			reqtype: 'all',
		};
		const getuurl = apiurl + 'allauditorkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('new', response.data.nowkeys);

					this.setState({
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};
	render() {

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Technology</th>
									<th>Total</th>
									<th>Approved</th>
									<th>Pending</th>
									<th>Reported</th>
									<th>On Hold</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.keyword}</strong>
												</td>

												<td>
													<Badge color="primary">{hkey.allq}</Badge>
												</td>

												<td>
													<Badge color="success">{hkey.approved}</Badge>
												</td>
												<td>
													<Badge color="warning">{hkey.pending}</Badge>
												</td>
												<td>
													<Badge color="danger">{hkey.reported}</Badge>
												</td>
												<td>
													<Badge color="primary">{hkey.onhold}</Badge>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AuKeywords;
