import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import classNames from 'classnames';
// import Topbar from '../Apanel/topbar/Topbar';
import TopbarWithNavigation from '../Apanel/topbar_with_navigation/TopbarWithNavigation';
// import Sidebar from '../Apanel/sidebar/Sidebar';
import UsersRoles from './components/UsersRoles';
import CDashboard from './components/CDashboard';
import MngCDashboard from './components/MngCDashboard';
import Dashboard from './components/Dashboard';
import Asettings from './components/Asettings';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
import ReportsPage from './components/ReportsPage';
import ReportsSevenDaysPage from './components/ReportsSevenDaysPage';

import TestReport from '../Apanel/TestReport';

import TechReportsPage from './components/TechReportsPage';
import ChgPwd from '../Apanel/ChgPwd';
var alldashboards = '[]';
if (localStorage.dashboards) {
	alldashboards = localStorage.dashboards;
}
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
	dashboards: JSON.parse(alldashboards)
};

class CompanyPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		// this.insertTawkto();
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		}
		if (localStorage.viewrecruiter) {
			const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
			let isvalidrec = re.test(localStorage.viewrecruiter);
			if (isvalidrec) {
				this.props.history.push('/recruiter/dashboard'); //eslint-disable-line
			} else {
				this.props.history.push('/dashboard'); //eslint-disable-line
			}
		}
	}
	// insertTawkto() {
	// 	var Tawk_API = Tawk_API || {},
	// 		Tawk_LoadStart = new Date();
	// 	(function () {
	// 		var s1 = document.createElement('script'),
	// 			s0 = document.getElementsByTagName('script')[0];
	// 		s1.async = true;
	// 		s1.src = 'https://embed.tawk.to/5da35321fbec0f2fe3b98448/default';
	// 		s1.charset = 'UTF-8';
	// 		s1.setAttribute('crossorigin', '*');
	// 		s0.parentNode.insertBefore(s1, s0);
	// 	})();
	// }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state; // eslint-disable-line
		// const layoutClass = classNames({
		// 	layout: true,
		// 	'layout--collapse': sidebarCollapse,
		// });
		var dashroute = "/dashboard_" + localStorage.dashboard; // eslint-disable-line
		return (
			<div>
				{/* <div className={layoutClass}> */}
				<div className="layout layout--top-navigation">
					{/* <Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/> */}
					<TopbarWithNavigation
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
					{/* <Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/> */}
				</div>
				<div className="container__wrap">


					<Route path="/dashboard" component={Dashboard} />
					<Route path="/asettings" component={Asettings} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
					<Route path="/reportspage" component={ReportsPage} />
					<Route path="/reportseven" component={ReportsSevenDaysPage} />
					<Route path="/techreportspage" component={TechReportsPage} />
					<Route path="/testreport" component={TestReport} />
					<Route path="/staffingboard" component={CDashboard} />
					<Route path="/mngcdashboard" component={MngCDashboard} />
					<Route path="/usersroles" component={UsersRoles} />
					<Route path="/chgpwd" component={ChgPwd} />

				</div>
			</div>
		);
	}
}

export default withRouter(CompanyPanel);
