import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class TotalProducts extends PureComponent {
	static propTypes = {
		rdata: PropTypes.array,
		tname: PropTypes.string,
		totaln: PropTypes.number,
	};

	constructor () {
		super();
		this.state = {
			activeIndex: 0,
		};
	}

	handleClick = (item) => {
		const data = this.props.rdata;
		const index = data.indexOf(item.payload);
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		let data = this.props.rdata;
		const { activeIndex } = this.state;
		const activeItem = data[activeIndex];


		return (
			<Col md={12} xl={3} lg={6} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget">
						<div className="card__title">
							<h5 className="bold-text">
								{this.props.tname}
								<span style={{ float: 'right', fontSize: '36px' }}>
									{this.props.totaln}
								</span>
							</h5>
						</div>
						<div className="dashboard__total">
							<TrendingUpIcon className="dashboard__trend-icon" />
							<p className="dashboard__total-stat">{activeItem.uv}</p>
							<div className="dashboard__chart-container">
								<ResponsiveContainer height={50}>
									<BarChart data={data}>
										<Bar dataKey="uv" onClick={this.handleClick}>
											{data.map((entry, index) => (
												<Cell
													cursor="pointer"
													fill={index === activeIndex ? '#4ce1b6' : '#ff4861'}
													key={`cell-${index}`}
												/>
											))}
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default withTranslation('common')(TotalProducts);
