

export const MY_API_URL = 'https://api.screenhr.com/';
// export const MY_API_URL = 'http://localhost:4300/';
export const TINYMCEAPIKEY = 'mvr29bix1za9dw9kzmsbu91pul65pmg1xldwgk4fxyzu8g20';
export const AXIOSHEADERS = {
	'Content-Type': 'application/json',
};
export const CRYPTKEY = 'pHQiWUEGMEdiWUEGMEdUwoo7fpHQ';
export const DUM_IMG_URL = 'https://ScreenHR.com/img/ava.png';
export const LOGO_URL = 'https://ScreenHR.com/img/1.png';
// export const LOGO_SQUARE_URL = 'https://texpertzowo.s3.ap-south-1.amazonaws.com/profile_img_1613129722223.png';
export const LOGO_SQUARE_URL = 'https://ScreenHR.com/img/ssio365-logo-square.png';
export const LOGO_SVG_URL = 'https://ScreenHR.com/img/ssio365-logo-square.svg';

export const VENDOR_SHARE_URL = 'https://ScreenHR.com/user/';

