import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
// import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import TopbarWithNavigationrec from '../Apanel/topbar_with_navigation/TopbarWithNavigationrec';
import Dashboard from './components/Dashboard';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
// import NewJobs from './components/jobs/NewJobs';
import JobsPublished from './components/jobs/JobsPublished';
import BenchCandyList from './components/jobs/BenchCandyList';
import VendorList from './components/jobs/VendorList';
import JobsSaved from './components/jobs/JobsSaved';
import JobApplicationsList from './components/jobs/JobApplicationsList';
import JobsDeactivated from './components/jobs/JobsDeactivated';

import AddJobTest from './components/jobs/AddJobTest';
import EditJobTest from './components/jobs/EditJobTest';
import ViewJobs from './components/jobs/ViewJobs';
import AddTest from './components/tests/AddTest';
import SaveTest from './components/tests/SaveTest';
import SentTests from './components/tests/SentTests';
import ComTest from './components/tests/ComTest';
import AddAdvTest from './components/tests/AddAdvTest';
import SaveAdvTest from './components/tests/SaveAdvTest';
import SentAdvTests from './components/tests/SentAdvTests';
import ComAdvTest from './components/tests/ComAdvTest';
import FaqTechView from './components/faqs/FaqTechView';
import FaqTechQues from './components/faqs/FaqTechQues';
import CustomSavedTest from './components/customtests/CustomSavedTest';
import CustomSentTests from './components/customtests/CustomSentTests';
import CusComTest from './components/customtests/CusComTest';
import CustomTestReport from './components/customtests/CustomTestReport';
import TestReport from '../Apanel/TestReport';
import TestAdvReport from '../Apanel/TestAdvReport';
import Emailids from './components/Emailids';
import AddQuest from './components/customtests/AddQuest';
import ViewQuest from './components/ViewQuest';
import Allmycustoms from './components/customtests/Allmycustoms';
import MyQuestions from './components/MyQuestions';
import Clients from './components/clients/Clients';
import ChgPwd from '../Apanel/ChgPwd';
import Mail from '../Mail/index';
import ReportsPage from './components/reports/RecReportsPage';
import ReportsSevenDaysPage from './components/reports/RecReportsSevenDaysPage';
import MembersList from './components/members/MembersList';
import TLMembersList from './components/members/TLMembersList';
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: true,
};

class RecruiterPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {

	}


	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {

		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					{/* <div className="layout layout--top-navigation"> */}
					{/* <Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/> */}
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
					<TopbarWithNavigationrec
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/emailids" component={Emailids} />
					<Route path="/testreport" component={TestReport} />
					<Route path="/testadvreport" component={TestAdvReport} />

					{/* <Route path="/newjobs" component={NewJobs} /> */}
					<Route path="/jobspublished" component={JobsPublished} />
					<Route path="/bcandys" component={BenchCandyList} />
					<Route path="/vendorlist" component={VendorList} />
					<Route path="/jobssaved" component={JobsSaved} />
					<Route path="/jobapplist" component={JobApplicationsList} />
					<Route path="/jobsdeactivated" component={JobsDeactivated} />
					<Route path="/viewjobs" component={ViewJobs} />
					<Route path="/addtest" component={AddTest} />
					<Route path="/addjobtest" component={AddJobTest} />
					<Route path="/editjobtest" component={EditJobTest} />
					<Route path="/savetest" component={SaveTest} />
					<Route path="/senttests" component={SentTests} />
					<Route path="/comtest" component={ComTest} />
					<Route path="/addadvtest" component={AddAdvTest} />
					<Route path="/saveadvtest" component={SaveAdvTest} />
					<Route path="/sentadvtests" component={SentAdvTests} />
					<Route path="/comadvtest" component={ComAdvTest} />
					<Route path="/faqtechview" component={FaqTechView} />
					<Route path="/faqtechsques" component={FaqTechQues} />
					<Route path="/customsavedtest" component={CustomSavedTest} />
					<Route path="/customsenttest" component={CustomSentTests} />
					<Route path="/cuscomtest" component={CusComTest} />
					<Route path="/custestreport" component={CustomTestReport} />
					<Route path="/addcustomtest" component={AddQuest} />
					<Route path="/viewcustomtest" component={ViewQuest} />
					<Route path="/mycustoms" component={Allmycustoms} />
					<Route path="/myqueslist" component={MyQuestions} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/chgpwd" component={ChgPwd} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
					<Route path="/clientlist" component={Clients} />
					<Route path="/mail" component={Mail} />
					<Route path="/reportspage" component={ReportsPage} />
					<Route path="/reportseven" component={ReportsSevenDaysPage} />
					{localStorage.dashbutype == 'man' ? (
						<Route path="/teamusers" component={MembersList} />
					) : (
							<Route path="/teamusers" component={TLMembersList} />
						)}

				</div>
			</div>
		);
	}
}

export default withRouter(RecruiterPanel);
