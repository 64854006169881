import React, { PureComponent } from 'react';


import { withRouter } from 'react-router-dom';

import AllUserProfileEdit from './components/AllUserProfileEdit';
import TechUserProfileEdit from './components/TechUserProfileEdit';
import ProfileEdit from './components/ProfileEdit';
const initialstate = {};

class UserProfileEdit extends PureComponent {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };

	render() {
		var userProfileEdit;
		var upanel = localStorage.upanel;
		if (upanel === 'admin-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'creater-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'it-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'tech-panel') {
			userProfileEdit = <TechUserProfileEdit />;
		} else if (upanel === 'auditor-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'company-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'ccare-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'recruiter-panel') {
			userProfileEdit = <AllUserProfileEdit />;
		} else if (upanel === 'tests-view') {
			userProfileEdit = <ProfileEdit />;
		}
		return <div>{userProfileEdit}</div>;
	}
}

export default withRouter(UserProfileEdit);
