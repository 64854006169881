import React, { PureComponent } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import EyeIcon from 'mdi-react/EyeIcon'; //eslint-disable-line
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'; //eslint-disable-line
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import Alert from '../../../../shared/components/Alert';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
	Modal
} from 'reactstrap';
const initialstate = {
	work_auths: [],
	dash_states: [{
		value: '',
		label: (<div>
			Select State
		</div>
		),
	}],
	pincode: '',
	email: '',
	showEbox: false,
	showToListbox: false,
	showAddCandybox: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	cmobile: '',
	profile: null,
	prefix: '',
	prefixoptions: [],
	name: '',
	jobrole: '',
	secondary_phone: '',
	skype: '',
	current_location: '',
	passport: '',
	ssn: '',
	dob: '',
	work_auth: '',
	employer_info: '',
	relocation: '',
	availability: '',
	linkedin: '',
	resume: '',
	otp: '',
	sts: '',
	cuid: 0,
	blockbtn: 1,
	resumeup: false,
	pdid: ''
};
class BenchCandyList extends PureComponent {
	constructor(props) {
		super(props);

	}
	state = { ...initialstate };

	componentDidMount() {
		let fsprefix = localStorage.sufix;
		let flprefix = [];
		let fnprefix = '';
		if (fsprefix) {
			flprefix = fsprefix.split('@');
			fnprefix = flprefix[1];
		}
		this.setState({ nprefix: fnprefix });
		this.handleGetkeys();
	}
	handleCkey = () => {
		this.setState({
			showEbox: false,
			showToListbox: false,
			showAddCandybox: false,
			showAlert: false,
			alertType: '',
			alertText: '',
			showOtpbox: false,
			otp: '',
			sts: '',
			email: ''
		});
	};
	maskDate = (sep) => {
		return [/[0-3]/, /\d/, sep, /[0-1]/, /\d/, sep, /\d/, /\d/, /\d/, /\d/];
	};

	autoCorrectedDatePipe = (sep) => {
		const format = `dd${sep}mm${sep}yyyy`;
		return createAutoCorrectedDatePipe(format);
	};
	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleSelectChange = (selectedOption) => {
		let sprefix = selectedOption.value;
		let lprefix = sprefix.split('@');
		let nprefix = lprefix[1];
		this.setState({ prefix: sprefix, nprefix: nprefix });
	};
	handleEmailSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				email: this.state.email,
				bid: localStorage.usercode,
				bemail: localStorage.by,
			};
			const loginurl = apiurl + 'checkcandyemail';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,

						});
					} else if (response.data.sts == 99) {
						this.setState({
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
							sts: response.data.sts,
							tokenn: response.data.tokenn,
							showEbox: true,
							showOtpbox: true,
							showToListbox: false,
							showAddCandybox: false,
						});
					} else if (response.data.sts == 66) {
						this.setState({
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
							sts: response.data.sts,
							tokenn: response.data.tokenn,
							showEbox: true,
							showOtpbox: true,
							showToListbox: false,
							showAddCandybox: false,
						});
					} else {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
							showEbox: true,
							showOtpbox: false,
							showToListbox: false,
							showAddCandybox: false,
						});

						// if (response.data.addtolist) {
						// 	this.setState({
						// 		showAlert: true,
						// 		alertType: 'success',
						// 		alertText: response.data.message,
						// 		showEbox: false,
						// 		showToListbox: true,
						// 		candydtls: [...response.data.candydtls],
						// 		showAddCandybox: false,
						// 	}, () => {
						// 		console.log('candydtls', this.state.candydtls);
						// 	});
						// } else if (response.data.addcandy) {

						// 	let allprefix = response.data.allprefix;
						// 	let prefixoptions = [];

						// 	allprefix.map((prefix) => {
						// 		var flagname = prefix.ccode;
						// 		flagname = flagname.toLowerCase();
						// 		let prefixname =
						// 			prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						// 		prefixoptions.push({
						// 			value: prefixname,
						// 			label: (
						// 				<div className="counoption">
						// 					<img
						// 						src={'flags/' + flagname + '.svg'}
						// 						width="25px"
						// 						height="15px"
						// 					/>
						// 					{prefix.prefix + ' - ' + prefix.cname}
						// 				</div>
						// 			),
						// 		});
						// 	});

						// 	this.setState({
						// 		showEbox: false,
						// 		showToListbox: false,
						// 		showAddCandybox: true,
						// 		prefixoptions: prefixoptions,
						// 		ccmobile: this.state.nprefix + '-'
						// 	});
						// }

					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleOtpSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};


		var postData = {
			email: this.state.email,
			sts: this.state.sts,
			otp: this.state.otp,
			bid: localStorage.usercode,
			bemail: localStorage.by,
		};
		const loginurl = apiurl + 'checkcandyotp';
		axios
			.post(loginurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,

					});
				} else {
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname =
							prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});

					if (response.data.addtolist) {
						this.setState({
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
							showEbox: false,
							showToListbox: true,
							candydtls: response.data.candydtls,
							showAddCandybox: false,
							showOtpbox: false,
							otp: '',
							sts: '',
							prefixoptions: prefixoptions,
							ccmobile: this.state.nprefix + '-'

						}, () => {
							console.log('candydtls', this.state.candydtls);

						});
					} else if (response.data.addcandy) {



						this.setState({
							showAlert: false,
							alertType: 'success',
							alertText: '',
							showEbox: false,
							showOtpbox: false,
							otp: '',
							sts: '',
							showToListbox: false,
							showAddCandybox: true,
							prefixoptions: prefixoptions,
							ccmobile: this.state.nprefix + '-'
						});
					}

				}
			})
			.catch((error) => { //eslint-disable-line
				console.log('error', error);
			});

	};

	handleCandySubmit = (event) => {
		event.preventDefault();
		if (isNaN(this.state.cmobile)) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Invalid Mobile',
			});
		} else if (this.state.relocation == '') {
			swal('Select Relocation', {
				icon: 'warning',
			});
		} else if (this.state.work_auth == '') {
			swal('Select Work Authorization', {
				icon: 'warning',
			});
		} else if (this.state.dash_state == '') {
			swal('Select State', {
				icon: 'warning',
			});
		} else if (this.state.pdid == '') {
			swal('Resume Not Uploaded', {
				icon: 'warning',
			});
		} else {

			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			var postData = {
				email: this.state.email,
				cmobile: this.state.cmobile,
				name: this.state.name,
				jobrole: this.state.jobrole,
				bid: localStorage.usercode,
				prefix: this.state.prefix,
				resume: this.state.resume,
				secondary_phone: this.state.secondary_phone,
				skype: this.state.skype,
				current_location: this.state.current_location,
				dash_state: this.state.dash_state,
				pincode: this.state.pincode,
				passport: this.state.passport,
				ssn: this.state.ssn,
				dob: this.state.dob,
				work_auth: this.state.work_auth,
				employer_info: this.state.employer_info,
				relocation: this.state.relocation,
				availability: this.state.availability,
				linkedin: this.state.linkedin,
				tokenn: this.state.tokenn,
				pdid: this.state.pdid,
			};
			const pupurl = apiurl + 'addcandy';
			axios
				.post(pupurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log('Response is ', response.data);
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: false,
							showEbox: false,
							showToListbox: false,
							showAddCandybox: false,
							showOtpbox: false,
							otp: '',
							sts: '',
							email: '',
							tokenn: ''
						}, () => {
							this.handleGetkeys();
						});
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Request Failed Try again Later!',
					});
				});
		}
	};
	// uploadfile = (thisone, mfile) => {
	// 	// console.log('mfile', mfile);
	// 	console.log('thisone', thisone);

	// 	const thisonefile = mfile.target.files[0];
	// 	console.log(this.state[thisone]);
	// 	// console.log(mfile.target.files[0]);
	// 	// console.log(resume);
	// 	const data = new FormData();

	// 	data.append('resume', thisonefile);
	// 	data.append('email', this.state.email);
	// 	const resumeurl = apiurl + 'resumeupload';
	// 	axios
	// 		.post(resumeurl, data)
	// 		.then((response) => {
	// 			// console.log('Response is ', response.data);
	// 			// console.log(response.data.message);
	// 			if (response.data.error) {
	// 				this.setState({
	// 					showAlert: true,
	// 					alertType: 'warning',
	// 					alertText: response.data.message,
	// 				});
	// 			} else {
	// 				const headers = {
	// 					ContentType: thisonefile.type,
	// 				};
	// 				const filetopost = response.data.filetopost;
	// 				const newfile = response.data.fileurl;
	// 				console.log(newfile);
	// 				// console.log(filetopost);
	// 				// console.log(resume);
	// 				axios
	// 					.put(filetopost, thisonefile, headers)
	// 					.then(() => {

	// 						this.setState(
	// 							{
	// 								showAlert: false,
	// 								[thisone]: newfile,
	// 							},
	// 							() => {
	// 								console.log('this.state', this.state);
	// 							},
	// 						);
	// 					})
	// 					.catch((error) => {
	// 						console.log('Error is ', error.response);
	// 						this.setState({
	// 							alertType: 'danger',
	// 							showAlert: true,
	// 							alertText: 'Failled Try Again Later!',
	// 						});
	// 					});
	// 			}
	// 		})
	// 		.catch(() => {
	// 			this.setState({
	// 				alertType: 'danger',
	// 				showAlert: true,
	// 				alertText: 'Failled Try Again Later!',
	// 			});
	// 		});
	// };

	uploadfile = (thisone, mfile) => {
		console.log('mfile', mfile);
		console.log('thisone', thisone);
		const thisonefile = mfile.target.files[0];
		this.setState({
			blockbtn: 2,
		}, () => {

			const data = new FormData();
			data.append('resume', thisonefile);
			const resumeurl = 'https://ScreenHRai.com/samtosun.php?u=none&r=' + localStorage.usercode;
			axios.post(resumeurl, data)
				.then((response) => {
					console.log('Response is ', response.data);
					// console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
							resume: '',
							resumeup: false,
							blockbtn: 2,
						});
					} else {
						this.setState({
							showAlert: false,
							resume: response.data.fileurl,
							resumeup: true,
							blockbtn: 1,
							pdid: response.data.did,
						});
					}
				})
				.catch(() => {
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failled Try Again Later!',
					});
				});
		});
	};


	handleGetkeys = () => {

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			bid: localStorage.usercode,
			dashbid: localStorage.dashbid,
		};
		const getuurl = apiurl + 'allmycandys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);
					const allwork_auths = response.data.allwork_auths;
					const alldash_states = response.data.alldash_states;
					this.setState({
						allcandys: response.data.allcandys,
						allwork_auths: allwork_auths,
						alldash_states: alldash_states,
						dash_states: [{
							value: '',
							label: (<div>
								Select State
							</div>
							),
						}],
						work_auths: [],
					}, () => {
						allwork_auths.map((work_auth) => {
							this.state.work_auths.push({
								value: work_auth.id,
								label: (
									<div>
										{work_auth.visatypes}
									</div>
								),
							});
						});
						alldash_states.map((dash_state) => {
							this.state.dash_states.push({
								value: dash_state.dash_state,
								label: (
									<div>
										{dash_state.dash_state}
									</div>
								),
							});
						});


					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};
	keeponhold = () => {

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			bid: localStorage.usercode,
			email: this.state.email,
			tokenn: this.state.tokenn,
		};
		const getuurl = apiurl + 'keeponhold';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);
					this.setState({
						showAlert: true,
						alertType: 'success',
						alertText: response.data.message,
						showEbox: false,
						showToListbox: false,
						showAddCandybox: false,
						showOtpbox: false,
						otp: '',
						sts: '',
						tokenn: ''

					}, () => {
						this.handleGetkeys();

					});

				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};
	approveadd = () => {

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			bid: localStorage.usercode,
			email: this.state.email,
			sts: this.state.sts,
			tokenn: this.state.tokenn,
		};
		const getuurl = apiurl + 'approveadd';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);

					this.setState({
						showAlert: true,
						alertType: 'success',
						alertText: response.data.message,
						showEbox: false,
						showToListbox: false,
						showAddCandybox: false,
						showOtpbox: false,
						otp: '',
						sts: '',
						tokenn: ''

					}, () => {
						this.handleGetkeys();

					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};
	handlerelocationChange = secoption => {
		var relocation = secoption.value;
		this.setState({ relocation });
		console.log(`relocation selected:`, relocation);
	};
	handleSelectWAChange = (selectedOption) => {
		let work_auth = selectedOption.value;
		this.setState({ work_auth: work_auth });
		// this.setState({ work_auth });
		console.log(`work_auth selected:`, work_auth);
		console.log(`this.state.work_auth:`, this.state.work_auth);
	};

	handleSelectDashStateChange = (selectedOption) => {
		let dash_state = selectedOption.value;
		this.setState({ dash_state: dash_state });
	};

	render() {
		const { email, prefixoptions, prefix, name, jobrole, cmobile, secondary_phone, skype, current_location, dash_state, pincode, passport, ssn, dob, work_auth, employer_info, relocation, availability, linkedin, resume, otp, cuid } = this.state; //eslint-disable-line
		const options = [//eslint-disable-line
			{ value: 'Yes', label: 'Yes' },
			{ value: 'No', label: 'No' },
		];


		return (
			<>
				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">
									Candidates

									<Button
										className="float-right btn-sm"
										color="success"
										onClick={() => {
											this.setState({ showEbox: true });
										}}
									>
										+
									</Button>
								</h3>

							</div>
						</CardBody>
					</Card>
				</Col>


				{this.state.allcandys && this.state.allcandys.map((candy, index) => {


					return (
						<Col md={12} key={index}>
							<Card >
								<CardBody style={{ position: 'relative' }}>
									<Col md={12}>
										<div className="card__title" style={{ marginBottom: '5px' }}>
											<h4 className="bold-text">
												{candy.name}
											</h4>
											<b>Date: </b>{candy.posted}
										</div>

									</Col>
									<Row>
										<Col md={3}><p><b>Full Name:</b> {candy.m.name}</p></Col>
										<Col md={3}><p><b>Email:</b> {candy.m.email}</p></Col>
										<Col md={3}><p><b>Primary Contact:</b> {candy.m.mobile}</p></Col>
										<Col md={3}><p><b>Secondary Contact:</b> {candy.p.secondary_phone}</p></Col>
										<Col md={3}><p><b>DOB:</b> {candy.p.dob}</p></Col>
										<Col md={3}><p><b>Job Role:</b> {candy.p.jobrole}</p></Col>
										<Col md={3}><p><b>Availability:</b> {candy.p.availability}</p></Col>
										<Col md={3}><p><b>Current Location:</b> {candy.p.current_location}</p></Col>
										<Col md={3}><p><b>State:</b> {candy.p.show_dash_state}</p></Col>
										<Col md={3}><p><b>Zipcode:</b> {candy.p.pincode}</p></Col>
										<Col md={3}><p><b>Relocation:</b> {candy.p.relocation}</p></Col>
										<Col md={3}><p><b>Skype ID:</b> {candy.p.skype}</p></Col>
										<Col md={3}><p><b>Linkedin:</b> {candy.p.linkedin}</p></Col>
										<Col md={3}><p><b>Passport no:</b> {candy.p.passport}</p></Col>
										<Col md={3}><p><b>SSN last 4 digit nos:</b> {candy.p.ssn}</p></Col>
										<Col md={3}><p><b>Work Authorization:</b> {candy.p.visatypes}</p></Col>
										<Col md={3}><p><b>Employer info if any:</b> {candy.p.employer_info}</p></Col>
										<Col md={3}><p><b>Resume:</b> <a style={{ padding: '3px 10px' }} href={candy.p.resume} target="blank" ><DownloadIcon /> Resume</a></p></Col>


									</Row>
								</CardBody>
							</Card>
						</Col>
					)
				})}
				<Modal
					isOpen={this.state.showToListbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title"><b>Candidate Details</b>  </h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<Card>
							<div className="project-summary">
								{this.state.showToListbox && this.state.candydtls ? (
									<table className="table benchcandy project-summary__info">
										<tbody>
											<tr>
												<th>Email:</th>
												<td>{this.state.candydtls.email}</td>
											</tr>
											{this.state.candydtls.profile_completed == 1 ? (
												<>
													<tr>
														<th>Full Name:</th>
														<td>{this.state.candydtls.name}</td>
													</tr>

													<tr>
														<th>Primary Contact:</th>
														<td>{this.state.candydtls.mobile}</td>
													</tr>

													<tr>
														<th>Secondary Contact:</th>
														<td>{this.state.candydtls.secondary_phone}</td>
													</tr>
													<tr>
														<th>DOB:</th>
														<td>{this.state.candydtls.dob}</td>
													</tr>
													<tr>
														<th>Job Role:</th>
														<td>{this.state.candydtls.jobrole}</td>
													</tr>
													<tr>
														<th>Availability:</th>
														<td>{this.state.candydtls.availability}</td>
													</tr>
													<tr>
														<th>Current Location:</th>
														<td>{this.state.candydtls.current_location}</td>
													</tr>
													<tr>
														<th>State:</th>
														<td>{this.state.candydtls.show_dash_state}</td>
													</tr>
													<tr>
														<th>Zipcode:</th>
														<td>{this.state.candydtls.pincode}</td>
													</tr>
													<tr>
														<th>Relocation:</th>
														<td>{this.state.candydtls.relocation}</td>
													</tr>
													<tr>
														<th>Skype ID:</th>
														<td>{this.state.candydtls.skype}</td>
													</tr>
													<tr>
														<th>Linkedin:</th>
														<td>{this.state.candydtls.linkedin}</td>
													</tr>
													<tr>
														<th>Passport no:</th>
														<td>{this.state.candydtls.passport}</td>
													</tr>
													<tr>
														<th>SSN last 4 digit nos:</th>
														<td>{this.state.candydtls.ssn}</td>
													</tr>
													<tr>
														<th>Work Authorization:</th>
														<td>{this.state.candydtls.visatypes}</td>
													</tr>
													<tr>
														<th>Employer info if any:</th>
														<td>{this.state.candydtls.employer_info}</td>
													</tr>
													<tr>
														<th>Resume:</th>
														<td>

															<a style={{ padding: '3px 10px' }} href={this.state.candydtls.resume} ><DownloadIcon /> Resume</a>
														</td>
													</tr>
													<tr>
														<th><span className="btn btn-primary" style={{ padding: '3px 10px' }} onClick={this.keeponhold} >Hold</span></th>
														<td><span className="btn btn-primary" style={{ padding: '3px 10px' }} onClick={this.approveadd} >Add to List</span></td>
													</tr>
												</>
											) : (
												<>
													<tr>
														<th>Profile not Updated</th>
														<td>{' '}</td>
													</tr>
													<tr>
														<th><span className="btn btn-primary" style={{ padding: '3px 10px' }} onClick={this.keeponhold} >Hold</span></th>
														<td><span className="btn btn-primary" style={{ padding: '3px 10px' }} onClick={() => {
															this.setState({
																showToListbox: false,
																showAddCandybox: true,
															});
														}} >Update Profile</span></td>
													</tr>

												</>

											)}

										</tbody>
									</table>
								) : null}

								<hr />

							</div>

						</Card>

					</div>
				</Modal>

				<Modal
					isOpen={this.state.showAddCandybox}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title" style={{ marginBottom: '40px' }}><b>Add Candidate</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleCandySubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									{/* old fields */}

									<div className="form__form-group">
										<span className="form__form-group-label">Email ID
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Your Email"
												className="form-control"
												defaultValue={email ? email : ''}
												required
												readOnly
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Your Full Name
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Your Full Name"
												className="form-control"
												defaultValue={name ? name : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Primary Contact
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<Select
												name="prefix"
												value={prefixoptions.filter(
													(option) => option.value == prefix,
												)}
												onChange={this.handleSelectChange}
												options={prefixoptions}
												clearable={false}
												placeholder="Country"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>

											<input
												name="cmobile"
												type="text"
												placeholder="Your Mobile"
												className="form-control"
												defaultValue={cmobile ? cmobile : ''}
												onChange={this.handleChange}
												pattern="^\d{10}$"
												title="Min and Max 10 Digits"
												required
											/>
										</div>
									</div>



									<div className="form__form-group">
										<span className="form__form-group-label">Secondary Contact  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">
											<input
												name="secondary_phone"
												type="text"
												placeholder="Your Mobile"
												className="form-control"
												defaultValue={secondary_phone ? secondary_phone : ''}
												onChange={this.handleChange}
												// pattern="^\d{10}$"
												// pattern="\d*"
												// pattern="/^[\+\d]+(?:[\d-.\s()]*)$/mg"
												title="Secondary Contact"

											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">DOB dd-mm-yyyy  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<MaskedInput
												name="dob"
												type="text"
												placeholder="dd-mm-yyyy"
												className="form-control"
												defaultValue={dob ? dob : ''}
												onChange={this.handleChange}
												mask={this.maskDate('-')}
												pipe={this.autoCorrectedDatePipe('-')}
											/>
										</div>
									</div>


									<div className="form__form-group">
										<span className="form__form-group-label">Passport no.  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="passport"
												type="text"
												placeholder="Passport no."
												className="form-control"
												defaultValue={passport ? passport : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>





									<div className="form__form-group">
										<span className="form__form-group-label">Skype ID  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">
											<input
												name="skype"
												type="text"
												placeholder="Enter Skype ID"
												className="form-control"
												defaultValue={skype ? skype : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												className="form-control"
												defaultValue={linkedin ? linkedin : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<div className="form__form-group">
										<span className="form__form-group-label">SSN last 4 digit nos  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="ssn"
												type="text"
												placeholder="SSN last 4 digit nos"
												className="form-control"
												defaultValue={ssn ? ssn : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Employer info if any  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="employer_info"
												type="text"
												placeholder="Employer info if any"
												className="form-control"
												defaultValue={employer_info ? employer_info : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>



								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Job Role
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<input
												name="jobrole"
												type="text"
												placeholder="Job Role"
												className="form-control"
												defaultValue={jobrole ? jobrole : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Availability
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="availability"
												type="text"
												placeholder="Availability"
												className="form-control"
												defaultValue={availability ? availability : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Current Location
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="current_location"
												type="text"
												placeholder="Current Location"
												className="form-control"
												defaultValue={current_location ? current_location : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">State  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="dash_state"
												value={this.state.dash_states.filter(
													(option) => option.value == dash_state,
												)}
												onChange={this.handleSelectDashStateChange}
												options={this.state.dash_states}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Zipcode  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="pincode"
												type="text"
												placeholder="Enter Pincode"
												className="form-control"
												value={pincode}
												onChange={this.handleChange}
												pattern="\d*"

											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Relocation
											<span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="relocation"
												value={options.filter(
													(option) => option.value == relocation,
												)}
												onChange={this.handlerelocationChange}
												options={options}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											/>

										</div>
									</div>



									<div className="form__form-group">
										<span className="form__form-group-label">Work Authorization <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ minHeight: '30px', height: 'auto' }}>

											<Select
												name="work_auth"
												value={this.state.work_auths.filter(
													(option) => option.value == work_auth,
												)}
												onChange={this.handleSelectWAChange}
												options={this.state.work_auths}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											/>

										</div>
									</div>







									<div className="form__form-group">
										<span className="form__form-group-label">Resume <span className='lablestar'>*</span></span> <span style={{ color: 'green' }}>{this.state.resumeup ? "Updated" : null}</span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<input type="file" className="form-control" required onChange={this.uploadfile.bind(this, 'resume')} />

										</div>
									</div>
									{this.state.blockbtn == 2 ? (
										<span
											className="btn btn-primary account__btn account__btn--small"
											desabled="true"
										>
											Submit Candidate Details
										</span>
									) : (

										<button
											className="btn btn-primary account__btn account__btn--small"
											type="submit"
										>
											Submit Candidate Details
										</button>
									)}

								</Col>
							</Row>

						</form>


					</div>
				</Modal>


				<Modal
					isOpen={this.state.showEbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>Check Candidate Details</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						{this.state.showOtpbox ? (
							<form
								className="form"
								onSubmit={(event) => this.handleOtpSubmit(event)}
							>

								<div className="form__form-group">
									<span className="form__form-group-label">Enter OTP given by Candidate</span>
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="otp"
											type="text"
											placeholder="Enter OTP"
											value={otp}
											className="form-control"
											onChange={this.handleChange}
											pattern="^\d{5}$"
											required
											onKeyUp={(e) => {
												e.preventDefault();

												if (e.keyCode == 13) {
													this.handleOtpSubmit(event);
												}
											}}
										/>
									</div>
								</div>

								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Submit
								</button>


							</form>
						) : (
							<form
								className="form"
								onSubmit={(event) => this.handleEmailSubmit(event)}
							>

								<div className="form__form-group">
									<span className="form__form-group-label">Candidate Email ID</span>
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="email"
											type="email"
											placeholder="Candidate Email"
											value={email}
											className="form-control"
											onChange={this.handleChange}
											required
											onKeyUp={(e) => {
												e.preventDefault();

												if (e.keyCode == 13) {
													this.handleEmailSubmit(event);
												}
											}}
										/>
									</div>
								</div>

								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Submit
								</button>


							</form>
						)}

					</div>
				</Modal>




			</>
		);
	}
}

export default withRouter(BenchCandyList);
