import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import Dashboard from './components/Dashboard';
import GetOtp from './components/GetOtp';
import AllKeywords from './components/AllKeywords';
import AddKeywordSearchItems from './components/AddKeywordSearchItems';
import AddKeywordSearchItemsNew from './components/AddKeywordSearchItemsNew';
import CountryFlags from './components/CountryFlags';
import EditKeywords from './components/EditKeywords';
import DashBItems from './components/DashBItems';
import AllTickets from './components/AllTickets';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';
import AllUsers from './components/AllUsers';
import AllRecruiter from './components/AllRecruiter';
import AllCreater from './components/AllCreater';
import AllAuditor from './components/AllAuditor';
import AllCompany from './components/AllCompany';
import AllCustomercare from './components/AllCustomercare';
import AllCurator from './components/AllCurator';
import AllTechnicals from './components/AllTechnicals';
import ReportsPage from './components/ReportsPage';
import TechReportsPage from './components/TechReportsPage';
import CusReportsPage from './components/CusReportsPage';
import KeyReportsPage from './components/KeyReportsPage';
import QuesReportsPage from './components/QuesReportsPage';
import AllKeyQuesExcel from './components/AllKeyQuesExcel';
import Csettings from './components/Csettings';
import ManageDashboards from './components/ManageDashboards';
import ManageEmails from './components/ManageEmails';
import TestReport from '../Apanel/TestReport';
import ChgPwd from '../Apanel/ChgPwd';

const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class AdminPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/managedashboards" component={ManageDashboards} />
					<Route path="/manageemails" component={ManageEmails} />
					<Route path="/csettings" component={Csettings} />
					<Route path="/testreport" component={TestReport} />
					<Route path="/countries" component={CountryFlags} />
					<Route path="/addsearchitems" component={AddKeywordSearchItems} />
					<Route path="/addsearchitemsnew" component={AddKeywordSearchItemsNew} />
					<Route path="/allkeywords" component={AllKeywords} />
					<Route path="/editkeywords" component={EditKeywords} />
					<Route path="/dashbitems" component={DashBItems} />
					<Route path="/alltickets" component={AllTickets} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/chgpwd" component={ChgPwd} />
					<Route path="/getotp" component={GetOtp} />
					<Route path="/users/all" component={AllUsers} />
					<Route path="/users/recruiter" component={AllRecruiter} />
					<Route path="/users/creaters" component={AllCreater} />
					<Route path="/users/auditors" component={AllAuditor} />
					<Route path="/users/companies" component={AllCompany} />
					<Route path="/users/ccares" component={AllCustomercare} />
					<Route path="/users/technicals" component={AllTechnicals} />
					<Route path="/users/curators" component={AllCurator} />
					<Route path="/reportspage" component={ReportsPage} />
					<Route path="/techreportspage" component={TechReportsPage} />
					<Route path="/cusreportspage" component={CusReportsPage} />
					<Route path="/keyreportspage" component={KeyReportsPage} />
					<Route path="/quesreportspage" component={QuesReportsPage} />
					<Route path="/getquesexcel" component={AllKeyQuesExcel} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
				</div>
			</div>
		);
	}
}

export default withRouter(AdminPanel);
