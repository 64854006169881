import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

class ITSidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink
						title="Reported Questions"
						icon="list"
						route="/listautechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Repair Tech Questions"
						icon="list"
						route="/listqueskeys"
						onClick={this.hideSidebar}
					/>
					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>
			</div>
		);
	}
}

export default ITSidebarContent;
