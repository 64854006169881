import React, { Component } from 'react';

import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
} from '@react-pdf/renderer';
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
const apiurl = MY_API_URL;

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#ffffff',
	},
	section: {
		margin: 10,
		padding: 10,
		height: 900,
		width: 900,
		flexGrow: 1,
	},
	cellrow: {
		margin: 0,
		marginLeft: 20,
		padding: 0,

		flexDirection: 'row',
		fontSize: 15,
		flexGrow: 1,
	},
	cell1: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 70,
		border: '1 solid #ccc',
		textAlign: 'center',
	},
	cell2: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 250,
		border: '1 solid #ccc',
		textAlign: 'center',
	},
	cell3: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 50,
		border: '1 solid #ccc',
		textAlign: 'center',
	},

	cell11: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 70,
		border: '1 solid #ccc',
		textAlign: 'center',
	},
	cell21: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 250,
		border: '1 solid #ccc',
		textAlign: 'center',
	},
	cell31: {
		margin: 0,
		padding: 10,

		flexGrow: 1,
		fontSize: 15,
		height: 50,
		width: 50,
		border: '1 solid #ccc',
		textAlign: 'center',
	},
});
const initialstate = {
	token: localStorage.token,
};
class KeyQues extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetkeys(localStorage.token);
		}
	}

	handleGetkeys = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'adminkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];
					console.log('new', response.data.nowkeys);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		return (
			//<PDFViewer style={styles.section}>
			<Document>
				<Page style={styles.page}>
					{this.state.keywords ? (
						<View>
							<View style={styles.cellrow}>
								<Text style={styles.cell1}>#</Text>
								<Text style={styles.cell2}>Technology</Text>
								<Text style={styles.cell3}>Q</Text>
							</View>

							{this.state.keywords &&
								this.state.keywords.map((ques, index) => {
									const qcnt = ques.qids.length;
									if (qcnt <= 25) {
										return (
											<View key={index} style={styles.cellrow}>
												<Text style={styles.cell11}>{index + 1}</Text>

												<Text style={styles.cell21}>{ques.keyword}</Text>
												<Text style={styles.cell31}>{qcnt}</Text>
											</View>
										);
									}
								})}
						</View>
					) : null}
				</Page>
			</Document>
			//</PDFViewer>
		);
	}
}

export default KeyQues;
