import React, { PureComponent } from 'react';
/* global  */
const icon = `${process.env.PUBLIC_URL}/img/burger.svg`; // eslint-disable-line
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import TopbarNav from './tobar_nav/TopbarNav';

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired,
	};

	render() {
		const { changeMobileSidebarVisibility } = this.props;

		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<button
							className="topbar__button topbar__button--mobile"
							type="button"
							onClick={changeMobileSidebarVisibility}
						>
							<img src={icon} alt="" className="topbar__button-icon" />
						</button>
						<Link className="topbar__logo" to="/" />
					</div>

					<div className="topbar__right"> {/* <TopbarNav />{' '} */}</div>
				</div>
			</div>
		);
	}
}

export default Topbar;
