import React, { Component } from 'react';
/* eslint-disable */
import ReactDOM from 'react-dom';
import ReactToPdf from 'react-to-pdf';
import axios from 'axios';
import { MY_API_URL, LOGO_URL } from './QueryMe';
var base64Img = require('base64-img');
import {
	Row,
	Card,
	CardBody,
	Col,
	Progress,
	Button,
	Table,
	FormGroup,
	Label,
	Input,
} from 'reactstrap';
import logo from '../../shared/img/logo/logotext.png';
import ReactSpeedometer from 'react-d3-speedometer';
const ref = React.createRef();
import { round } from 'lodash';
var dateFormat = require('dateformat');
const apiurl = MY_API_URL;
// const logo = LOGO_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	test: [],
	testname: [],
	showsenton: 1,
	showsentfrom: 1,
	showsentemail: 1,
	showsentcontact: 1,
	showtakenon: 1,
	showtakenfrom: 1,
	showtakenemail: 1,
	showtakencontact: 1,
	newlogo: localStorage.logo,
};
class TestReport extends Component {
	constructor(props) {
		super(props);
		this.showsenton = this.showsenton.bind(this);
		this.showsentfrom = this.showsentfrom.bind(this);
		this.showsentemail = this.showsentemail.bind(this);
		this.showsentcontact = this.showsentcontact.bind(this);
		this.showtakenon = this.showtakenon.bind(this);
		this.showtakenfrom = this.showtakenfrom.bind(this);
		this.showtakenemail = this.showtakenemail.bind(this);
		this.showtakencontact = this.showtakencontact.bind(this);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'TestReport');
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		}
		if (!this.props.location.email || !this.props.location.testId) {
			this.props.history.goBack();
		} else {
			this.fetchResults();
		}
	}

	fetchResults = () => {
		// console.log(this.props);

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			testId: this.props.location.testId,
			email: this.props.location.email,
		};
		const getfqurl = apiurl + 'newfetchTestResults';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then(async (response) => {
				let totalCorrect = 0;
				let totalIncorrect = 0;
				let totalUnattempted = 0;
				let testname = response.data.data;
				response.data.data &&
					response.data.data.results.map((result, index) => {
						totalCorrect = totalCorrect + parseInt(result.correct, 0);
						totalIncorrect = totalIncorrect + parseInt(result.incorrect, 0);
						totalUnattempted =
							totalUnattempted + parseInt(result.unattempted, 0);
					});

				let tot = 0;
				response.data.data &&
					response.data.data.helper.map((helpe, index) => {
						tot = tot + parseInt(helpe.questions, 0);
					});
				let tpercentage = totalCorrect / tot;
				let totpercentage = tpercentage * 100;
				totpercentage = round(totpercentage, 0);
				let posted = dateFormat(testname.posted, 'd-m-yyyy');
				let taken_at = dateFormat(testname.taken_at, 'd-m-yyyy');


				this.setState(
					{
						totalCorrect,
						totalIncorrect,
						totalUnattempted,
						totpercentage,
						tot,
						posted,
						taken_at,
					},
					() => {
						this.setState({
							dataset: response.data.data,
							testname: response.data.data,
						});
					},
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		// var hDisplay = h > 0 ? h + (h == 1 ? ' Hr, ' : ' Hrs, ') : '';
		// var mDisplay = m > 0 ? m + (m == 1 ? ' Min, ' : ' Mins, ') : '';
		// var sDisplay = s > 0 ? s + (s == 1 ? ' Sec' : ' Secs') : '';
		var hDisplay = h > 0 ? h + (h == 1 ? ' H : ' : ' H : ') : ' 00 H : ';
		var mDisplay = m > 0 ? m + (m == 1 ? ' M : ' : ' M : ') : ' 00 M : ';
		var sDisplay = s > 0 ? s + (s == 1 ? ' S' : ' S') : ' 00 S : ';
		return hDisplay + mDisplay + sDisplay;
	};
	handleContactlines(e1, e2) { }
	showsenton(e) {
		let bmw = 1;
		if (this.state.showsenton == 1) {
			bmw = 0;
		}
		this.setState({
			showsenton: bmw,
		});
	}
	showsentfrom(e) {
		let bmw = 1;
		if (this.state.showsentfrom == 1) {
			bmw = 0;
		}
		this.setState({
			showsentfrom: bmw,
		});
	}
	showsentemail(e) {
		let bmw = 1;
		if (this.state.showsentemail == 1) {
			bmw = 0;
		}
		this.setState({
			showsentemail: bmw,
		});
	}
	showsentcontact(e) {
		let bmw = 1;
		if (this.state.showsentcontact == 1) {
			bmw = 0;
		}
		this.setState({
			showsentcontact: bmw,
		});
	}
	showtakenon(e) {
		let bmw = 1;
		if (this.state.showtakenon == 1) {
			bmw = 0;
		}
		this.setState({
			showtakenon: bmw,
		});
	}
	showtakenfrom(e) {
		let bmw = 1;
		if (this.state.showtakenfrom == 1) {
			bmw = 0;
		}
		this.setState({
			showtakenfrom: bmw,
		});
	}
	showtakenemail(e) {
		let bmw = 1;
		if (this.state.showtakenemail == 1) {
			bmw = 0;
		}
		this.setState({
			showtakenemail: bmw,
		});
	}
	showtakencontact(e) {
		let bmw = 1;
		if (this.state.showtakencontact == 1) {
			bmw = 0;
		}
		this.setState({
			showtakencontact: bmw,
		});
	}
	render() {
		const options = {
			orientation: 'P',
			unit: 'px',
			format: 'a3',
			floatPrecision: 'smart',
		};
		const {
			showsenton,
			showsentfrom,
			showsentemail,
			showsentcontact,
			showtakenon,
			showtakenfrom,
			showtakenemail,
			showtakencontact,
		} = this.state;
		// console.log('hhhhhhhhh', this.getlogobase(this));
		// console.log(this.state.testname.logo);
		return (
			<div style={{ width: '1100px', margin: '0px auto' }}>
				{localStorage.upanel == 'tests-view' ? (
					<ReactToPdf
						targetRef={ref}
						filename={
							'test-report-' + this.state.testname.generated_id + '.pdf'
						}
						options={options}
					>
						{({ toPdf }) => (
							<button
								className="btn btn-sm btn-success dwnpdf"
								onClick={toPdf}
							>
								Generate pdf
							</button>
						)}
					</ReactToPdf>
				) : (
					<Card>
						<CardBody>
							<Table responsive hover>
								<thead>
									<tr>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showsenton}
														onChange={this.showsenton}
													/>{' '}
													Sent On
												</Label>
											</FormGroup>

											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showsentfrom}
														onChange={this.showsentfrom}
													/>{' '}
													From
												</Label>
											</FormGroup>

											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showsentemail}
														onChange={this.showsentemail}
													/>{' '}
													From Email
												</Label>
											</FormGroup>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showsentcontact}
														onChange={this.showsentcontact}
													/>{' '}
													From Contact
												</Label>
											</FormGroup>
										</th>
										<th>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showtakenon}
														onChange={this.showtakenon}
													/>{' '}
													Taken on
												</Label>
											</FormGroup>

											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showtakenfrom}
														onChange={this.showtakenfrom}
													/>{' '}
													Taken By
												</Label>
											</FormGroup>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showtakenemail}
														onChange={this.showtakenemail}
													/>{' '}
													Taken By Email
												</Label>
											</FormGroup>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														checked={showtakencontact}
														onChange={this.showtakencontact}
													/>{' '}
													Taken By Contact
												</Label>
											</FormGroup>
										</th>

										<th>
											<ReactToPdf
												targetRef={ref}
												filename={
													'test-report-' +
													this.state.testname.generated_id +
													'.pdf'
												}
												options={options}
											>
												{({ toPdf }) => (
													<button
														className="btn btn-sm btn-success float-right"
														onClick={toPdf}
													>
														Generate pdf
													</button>
												)}
											</ReactToPdf>
										</th>
									</tr>
								</thead>
							</Table>
						</CardBody>
					</Card>
				)}
				{this.state.testname && this.state.testname ? (
					<div>
						<div ref={ref}>
							<Card>
								<CardBody>
									<div className="project-summary">
										<div className="card__title project-summary__card-title">
											<h4 className="bold-text">
												<Row>
													<Col md={4}>
														<img
															src={this.state.testname.logo}
															style={{ width: '150px' }}
														/>
													</Col>
													<Col md={4} className="text-center">
														<span>
															Test ID:
															{this.state.testname.generated_id}
														</span>
													</Col>
													<Col md={4}>
														<span className="float-right">
															Technical Assessment
														</span>
													</Col>
												</Row>
											</h4>
										</div>
										<hr />
										<Row>
											<Col md={6}>
												<table
													className="project-summary__info"
													style={{ textAlign: 'right' }}
												>
													<tbody>
														{showsenton ? (
															<tr>
																<th>Sent on: </th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.posted}
																</td>
															</tr>
														) : null}
														{showsentfrom ? (
															<tr>
																<th>From: </th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.rname}
																</td>
															</tr>
														) : null}
														{showsentemail ? (
															<tr>
																<th>Email:</th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.remail}
																</td>
															</tr>
														) : null}
														{showsentcontact ? (
															<tr>
																<th>Contact:</th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.rmobile}
																</td>
															</tr>
														) : null}
													</tbody>
												</table>
											</Col>
											<Col md={6}>
												<table
													className="project-summary__info float-right"
													style={{ textAlign: 'right' }}
												>
													<tbody>
														{showtakenon ? (
															<tr>
																<th>Test Taken on: </th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.taken_at}
																</td>
															</tr>
														) : null}
														{showtakenfrom ? (
															<tr>
																<th>Taken by: </th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.tname}
																</td>
															</tr>
														) : null}
														{showtakenemail ? (
															<tr>
																<th>Email:</th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.temail}
																</td>
															</tr>
														) : null}
														{showtakencontact ? (
															<tr>
																<th>Contact:</th>
																<td style={{ textAlign: 'left' }}>
																	{this.state.testname.tmobile}
																</td>
															</tr>
														) : null}
													</tbody>
												</table>
											</Col>
										</Row>

										<h4
											style={{
												textAlign: 'center',
												textTransform: 'uppercase',
												background: '#ecf0ef',
												padding: '5px',
												marginBottom: '15px',
												marginTop: '15px',
											}}
										>
											Test Score
										</h4>
										<Row>
											<Col md={4}>
												<table className="testscore">
													<tbody>
														<tr>
															<td>
																<b>
																	Score: {this.state.totalCorrect}/
																	{this.state.tot}
																</b>
															</td>
														</tr>
														<tr>
															<td>
																<b>Time Taken: </b>
																{this.secondsToHms(this.state.testname.nqno)}
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
											<Col md={4}>
												<h3 style={{ textAlign: 'center' }}>
													{this.state.totpercentage}%
												</h3>
												<center>
													<ReactSpeedometer
														forceRender
														maxSegmentLabels={1}
														width={250}
														// fluidWidth={true}
														height={170}
														ringWidth={25}
														// segmentColors={['green', 'gold']}
														// segments={5}
														// startColor="red"
														segments={4}
														segmentColors={[
															'#f26a76',
															'#ffc15c',
															'#45caf5',
															'#a4d28a',
														]}
														// endColor="green"
														needleColor="#414141"
														needleHeightRatio={0.8}
														labelFontSize="9px"
														minValue={0}
														maxValue={100}
														value={this.state.totpercentage}
													// customSegmentStops={[0, marks, 100]}
													/>
												</center>
											</Col>
											<Col md={4}>
												<table className="testscore">
													<tbody>
														<tr>
															<td>
																<span className="srcoredots dotred"></span>{' '}
																<b>Beginner: </b>
																(&#60; 25%)
															</td>
														</tr>
														<tr>
															<td>
																<span className="srcoredots dotyellow"></span>{' '}
																<b>Intermediate: </b>
																(25% - 50%)
															</td>
														</tr>
														<tr>
															<td>
																<span className="srcoredots dotblue"></span>{' '}
																<b>Experienced: </b> (50% - 75%)
															</td>
														</tr>
														<tr>
															<td>
																<span className="srcoredots dotgreen"></span>{' '}
																<b>Proficient: </b>
																(&#62; 75%)
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
										</Row>
										<h4
											style={{
												textTransform: 'uppercase',

												marginTop: '20px',
											}}
										>
											{this.state.testname.tname} has Scored{' '}
											<b>{this.state.totpercentage}%</b> and Completed
											Assessment in (
											<b>{this.secondsToHms(this.state.testname.nqno)}</b>) of
											the alloted time (
											<b>{this.secondsToHms(this.state.tot * 60)}</b>)
										</h4>

										<h4
											style={{
												textAlign: 'center',
												textTransform: 'uppercase',
												background: '#ecf0ef',
												padding: '5px',
												marginBottom: '20px',
												marginTop: '20px',
											}}
										>
											Technical Analysis
										</h4>
										<Row>
											<Col md={3} className="text-center">
												<span className="srcoredotso dotgrey"></span>
												<span className="clearfix"></span>
												<span>(&#61; 0%)</span>
											</Col>
											<Col md={2}>
												<span className="srcoredots dotred"></span>{' '}
												<b>Beginner: </b>
												<br />
												(&#60; 25%)
											</Col>

											<Col md={2}>
												<span className="srcoredots dotyellow"></span>{' '}
												<b>Intermediate: </b>
												<br />
												(25% - 50%)
											</Col>
											<Col md={2}>
												<span className="srcoredots dotblue"></span>{' '}
												<b>Experienced: </b> <br />
												(50% - 75%)
											</Col>
											<Col md={2}>
												<span className="srcoredots dotgreen"></span>{' '}
												<b>Proficient: </b>
												<br />
												(&#62; 75%)
											</Col>
										</Row>
										{this.state.testname.results &&
											this.state.testname.results.map((result, index) => {
												let helperDifficultyLevel = this.state.testname.helper.filter(
													(helpr) => {
														result.technology = parseInt(result.technology, 0);
														helpr.technology = parseInt(helpr.technology, 0);
														return helpr.technology === result.technology;
													},
												);
												let tot = parseInt(
													helperDifficultyLevel[0].questions,
													0,
												);
												let tpercentage = result.correct / tot;
												tpercentage = tpercentage * 100;
												let barcolor = '';
												if (tpercentage <= 25) {
													barcolor = 'danger';
												} else if (tpercentage >= 26 && tpercentage <= 50) {
													barcolor = 'warning';
												} else if (tpercentage >= 51 && tpercentage <= 75) {
													barcolor = 'primary';
												} else if (tpercentage >= 76 && tpercentage <= 100) {
													barcolor = 'success';
												}
												return (
													<Row key={index} className="scroreprogress">
														<Col md={3} style={{ textAlign: 'right' }}>
															{helperDifficultyLevel[0].difficultyLevel}
														</Col>
														<Col md={8}>
															<Progress color={barcolor} value={tpercentage}>
																{tpercentage}%
															</Progress>
														</Col>
													</Row>
												);
											})}
										<Table
											size="sm"
											responsive
											hover
											style={{ textAlign: 'center', marginTop: '20px' }}
										>
											<thead>
												<tr>
													<th>Sections</th>
													<th>Questions</th>
													<th>Correct</th>
													<th>InCorrect</th>
													<th>UnAttempted</th>
													<th>Score</th>
												</tr>
											</thead>
											<tbody>
												{this.state.testname.results &&
													this.state.testname.results.map((result, index) => {
														let helperDifficultyLevel = this.state.testname.helper.filter(
															(helpr) => {
																result.technology = parseInt(
																	result.technology,
																	0,
																);
																helpr.technology = parseInt(
																	helpr.technology,
																	0,
																);
																return helpr.technology === result.technology;
															},
														);
														let tot = parseInt(
															helperDifficultyLevel[0].questions,
															0,
														);
														let tpercentage = result.correct / tot;
														tpercentage = tpercentage * 100;
														let barcolor = '';
														if (tpercentage <= 25) {
															barcolor = 'danger';
														} else if (tpercentage >= 26 && tpercentage <= 50) {
															barcolor = 'warning';
														} else if (tpercentage >= 51 && tpercentage <= 75) {
															barcolor = 'danger';
														} else if (
															tpercentage >= 76 &&
															tpercentage <= 100
														) {
															barcolor = 'success';
														}
														return (
															<tr key={index}>
																<td>
																	{helperDifficultyLevel[0].difficultyLevel}
																</td>
																<td>{tot}</td>
																<td>{result.correct}</td>
																<td>{result.incorrect}</td>
																<td>{result.unattempted}</td>
																<td>
																	{result.correct}/{tot}
																</td>
															</tr>
														);
													})}
											</tbody>
										</Table>
										<hr />
										<Row>
											<Col md={12} style={{ textAlign: 'right' }}>
												{/* <form>
													<input
														type="hidden"
														value={this.getlogobase.bind(
															this.state.testname.logo,
														)}
													/>
												</form> */}
												Powered by:{' '}
												<img src={logo} style={{ width: '100px' }} />
												<br />
												<span>www.ScreenHRai.com</span>
											</Col>
										</Row>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				) : null}
				;
			</div>
		);
	}
}

export default TestReport;
