import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; //eslint-disable-line
import { Card, CardBody, Col, Row } from 'reactstrap';
/* global  */

import Topbar from '../Apanel/topbar/IndexTopbar';


const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class Welcome extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.setState({
			jtoken: this.props.location.jtoken,
			japid: this.props.location.japid
		});
	}

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};


	render() {

		return (
			<div>
				<div className="layout layout--top-navigation">
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
				</div>
				<div
					className="container__wrap"
					style={{ paddingTop: '62px', width: '100%', background: '#fff' }}
				>
					<Card>
						<CardBody className="logincardbody">
							<Row className="loginrow">
								<Col md={6} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											<span style={{ textTransform: 'capitalize' }}>
												I AM CANDIDATE
											</span><br />

											<span className="applybtntexpert"
												onClick={() => {
													this.props.history.push({
														pathname: '/amcandidate',
														jtoken: this.state.jtoken,
														japid: this.state.japid,
													});
												}} >Apply</span>
										</h2>
									</div>

								</Col>
								<Col md={6} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											<span style={{ textTransform: 'capitalize' }}>
												I AM EMPLOYER
											</span><br />

											<span className="applybtntexpert"
												onClick={() => {
													this.props.history.push({
														pathname: '/amemployer',
														jtoken: this.state.jtoken,
														japid: this.state.japid,
													});
												}} >Apply</span>
										</h2>
									</div>

								</Col>

							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default withRouter(Welcome);
