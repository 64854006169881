import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
// import Switch from 'react-switch';
import {
	Card,
	CardBody,
	Col,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../../shared/components/Alert';
const apiurl = MY_API_URL;
const initialstate = {
	email: '',
	userotp: '',
};
class GetOtp extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleAkey = () => {
		this.setState({
			addUserModal: true,
		});
	};
	handleUserSubmit = (event) => {
		event.preventDefault();
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'getuserotp';
			var postData = {
				email: this.state.email,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							addUserModal: false,
							showAlert: false,
							email: '',
							userotp: response.data.data,
						});
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
		});
	};

	render() {
		const { email } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">Returned OTP: {this.state.userotp}</h5>
						</div>
						<div className="col-md-6">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleUserSubmit(event)}
							>
								<div className="form__form-group">
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="email"
											type="email"
											placeholder="Enter email"
											className="form-control"
											value={email}
											onChange={this.handleChange}
											required
										/>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Get OTP
								</button>
							</form>
						</div>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default GetOtp;
