import React, { PureComponent } from 'react';


import { withRouter } from 'react-router-dom';
import AdminProfileCard from './components/AdminProfileCard';
import CreaterProfileCard from './components/CreaterProfileCard';
import ITProfileCard from './components/ITProfileCard';
import TechProfileCard from './components/TechProfileCard';
import AuditorProfileCard from './components/AuditorProfileCard';
import CompanyProfileCard from './components/CompanyProfileCard';
import CustomerCareProfileCard from './components/CustomerCareProfileCard';
import RecruiterProfileCard from './components/RecruiterProfileCard';
import ProfileCard from './components/ProfileCard';
const initialstate = {};

class Apanel extends PureComponent {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };

	render() {
		var userProfile;
		var upanel = localStorage.upanel;
		if (upanel === 'admin-panel') {
			userProfile = <AdminProfileCard />;
		} else if (upanel === 'creater-panel') {
			userProfile = <CreaterProfileCard />;
		} else if (upanel === 'it-panel') {
			userProfile = <ITProfileCard />;
		} else if (upanel === 'tech-panel') {
			userProfile = <TechProfileCard />;
		} else if (upanel === 'auditor-panel') {
			userProfile = <AuditorProfileCard />;
		} else if (upanel === 'company-panel') {
			userProfile = <CompanyProfileCard />;
		} else if (upanel === 'ccare-panel') {
			userProfile = <CustomerCareProfileCard />;
		} else if (upanel === 'recruiter-panel') {
			userProfile = <RecruiterProfileCard />;
		} else if (upanel === 'tests-view') {
			userProfile = <ProfileCard />;
		}
		return <div>{userProfile}</div>;
	}
}

export default withRouter(Apanel);
