import React, { Component } from 'react';
import ReactDOM from 'react-dom'; // eslint-disable-line
import { withRouter } from 'react-router-dom';

import SearchIcon from 'mdi-react/SearchIcon'; //eslint-disable-line
var dateFormat = require('dateformat'); //eslint-disable-line
 

import EditIcon from 'mdi-react/EditIcon'; //eslint-disable-line
import PlusIcon from 'mdi-react/PlusIcon'; //eslint-disable-line
import MinusIcon from 'mdi-react/MinusIcon'; //eslint-disable-line

const initialstate = {
	thisjobdtls: []
};
class JobApplicationsList extends Component {
	constructor (props) {
		super(props);

		this.lastId = -1;

	}
	state = { ...initialstate };

	showThisTest = (showthistest) => {
		this.setState({ showthistest });
	};


	render() {
		const { client, jobtitle, job_type, emp_type, duration, location, pincode, req_document, interview_mode, security_clearance, dash_state, jd, rskills, visatypes, payrate, workexp, edureq } = this.state; //eslint-disable-line
		return (
			<div>





			</div >
		);
	}
}


export default withRouter(JobApplicationsList);
