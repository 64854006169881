import React, { Component } from 'react';
import JobApplication from './JobApplication'; // eslint-disable-line
import { withRouter } from 'react-router-dom';
import ResultMeter from '../../../Apanel/ResultMeter';
var dateFormat = require('dateformat');
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
const apiurl = MY_API_URL;
import {
	// Button,
	Card,
	CardBody,
	Col,
	Row,
	// Modal,
	Table,
	Badge,
} from 'reactstrap';
const initialstate = {
	alljobapplist: []
};
class JobApplicationsList extends Component {
	constructor (props) {
		super(props);
		localStorage.setItem('jstatus', 1);
	}
	state = { ...initialstate };

	componentDidMount() {
		if (localStorage.dashbid && localStorage.jobapplistid) {
			this.handleGetkeys(localStorage.dashbid);
		} else {
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}

	handleGetkeys = (dashbid) => {

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			dashbid: dashbid,
			recid: localStorage.usercode,
			jobid: localStorage.jobapplistid,
			status: localStorage.jobappliststatus
		};
		const getuurl = apiurl + 'jobapplist';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('response.data.alljobapplist', response.data.alljobapplist);
				this.setState({
					alljobapplist: response.data.alljobapplist
				});
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};


	render() {

		return (
			<>
				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">
									Jobs Applications
								</h3>

							</div>
						</CardBody>
					</Card>
				</Col>
				{this.state.alljobapplist && this.state.alljobapplist.map((jobappli, index) => {
					// <JobApplication key={index} thisjobdtls={jobappli} />
					var applidtls = jobappli.aplicdtls;
					var istest = jobappli.istest;
					var jobtest = jobappli.jobtest;
					var req_docs = applidtls.req_document;
					if (req_docs.length >= 1) {
						req_docs = JSON.parse(req_docs);
					} else {
						req_docs = [];
					}
					let comptindex = 1;
					return (
						<Col md={12} key={index}>
							<Card>
								<CardBody style={{ position: 'relative' }}>

									<h3 className="bold-text" style={{ fontSize: '14px' }}>
										Name:  {applidtls.name}
									</h3>


									<Row>

										<Col md={3}><p><b>Email:</b> {applidtls.email}</p></Col>
										<Col md={3}><p><b>Mobile:</b> {applidtls.mobile}</p></Col>
										<Col md={3}><p><b>Phone:</b> {applidtls.secondary_phone}</p></Col>
										<Col md={3}><p><b>Current Location:</b> {applidtls.current_location}</p></Col>
										<Col md={3}><p><b>Passport:</b> {applidtls.passport}</p></Col>
										<Col md={3}><p><b>SSN:</b> {applidtls.ssn}</p></Col>
										<Col md={3}><p><b>DOB:</b> {applidtls.dob}</p></Col>
										<Col md={3}><p><b>Work Authorization:</b> {applidtls.work_auth}</p></Col>
										<Col md={3}><p><b>Relocation:</b> {applidtls.relocation}</p></Col>
										<Col md={3}><p><b>Linkedin:</b> <a href={applidtls.linkedin} >Click to View</a></p></Col>
										<Col md={3}><p><b>Skype:</b> {applidtls.skype}</p></Col>
										<Col md={3}><p><b>Resume:</b> <a href={applidtls.resume} >Click to Download</a></p></Col>
										<Col md={6}><p><b>Availability:</b> {applidtls.availability}</p></Col>
										<Col md={6}><p><b>Require Documents:</b> {req_docs && req_docs.map((reqdoc, index) => {
											return (<><a key={index} href={reqdoc.dfile} >{reqdoc.name}</a> {', '}</>)
										})}</p></Col>
										<Col md={6}><p><b>Employer info:</b> {applidtls.employer_info}</p></Col>

										<Col md={12}>
											<h5><b>Test Details:</b> {istest ? null : "No Test"}</h5>
											{istest ? (
												<Table responsive hover>
													<thead>
														<tr>
															<th>#</th>
															<th>Test ID</th>
															<th>Test Taken</th>
															<th>Technologies</th>
															<th>
																<span style={{ fontSize: '12px' }}>
																	Correct/InCorrect/UnAttempted
																</span>
															</th>
															<th>Result</th>
														</tr>
													</thead>
													<tbody>
														{jobtest && jobtest.map((test, index) => {
															if (test.taken === 1) {
																var myresult = test.helper;
																var allresults = JSON.parse(test.results);
																// console.log('results here ', allresults);
																let totalCorrect = 0;
																let totalIncorrect = 0; // eslint-disable-line
																let totalUnattempted = 0; // eslint-disable-line
																let totalquestions = 0;

																allresults.map((result) => {
																	totalCorrect += result.correct; // eslint-disable-line
																	totalIncorrect += result.incorrect; // eslint-disable-line
																	totalUnattempted += result.unattempted; // eslint-disable-line
																});
																myresult.map((resul) => {
																	resul.questions = parseInt(resul.questions);
																	totalquestions += resul.questions;
																});
																totalCorrect =
																	(100 / totalquestions) * totalCorrect;
																return (
																	<tr key={index}>
																		<td>{comptindex++}</td>

																		<td>
																			<h4>{test.generated_id}</h4>
																			<p>
																				From:
																					{test.name}
																				<br />
																					Email: {test.sent_by}
																				<br />
																					Mobile: {test.mobile}
																				<br />
																				{test.domain}
																			</p>
																		</td>

																		<td>
																			{dateFormat(test.taken_at, 'd-m-yyyy')}
																		</td>
																		<td>
																			<h4>
																				{test.helper &&
																					test.helper.map(
																						(selper, index) => {
																							return (
																								<Badge
																									key={index}
																									color="success"
																									style={{
																										padding: '5px',
																										margin: '5px',
																									}}
																								>
																									{selper.difficultyLevel}
																								</Badge>
																							);
																						},
																					)}
																			</h4>
																		</td>
																		<td>
																			<ResultMeter
																				marks={totalCorrect}
																				mmarks={totalquestions}
																			/>
																		</td>
																		<td>
																			<strong
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					this.props.history.push({
																						pathname: '/testreport',
																						testId: test.id,
																						email: test.sent_to,
																					});
																				}}
																			>
																				View Result
																				</strong>
																		</td>
																	</tr>
																);
															}
														})}
													</tbody>
												</Table>
											) : null}
										</Col>
									</Row>

								</CardBody>
							</Card>
						</Col>
					)

				})}
			</>
		);
	}
}


export default withRouter(JobApplicationsList);
