import React, { Component } from 'react';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../Apanel/QueryMe';

import swal from 'sweetalert'; //eslint-disable-line
import Alert from '../../shared/components/Alert';
const apiurl = MY_API_URL;
import {
	Modal,
} from 'reactstrap';
const initialstate = {

	showAlert: false,
	alertType: '',
	alertText: '',
	newjob: '',
	techs: [],
	upSingleModal: true,
	token: localStorage.token,

};
class AmEmployer extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {

	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleCkey = () => {
		this.setState({
			upSingleModal: true,
		});
	};


	handleSingleSubmit = (event) => { //eslint-disable-line



		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addsqurl = apiurl + 'addnewjob';
		var postData = {

			userid: localStorage.usercode,
			newjob: this.state.newjob,
		};
		axios
			.post(addsqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'danger',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{

							upSingleModal: true,
							showAlert: false,
							newjob: '',
							allkeys: response.data.allkeys,
						},
						() => {
							// this.fetchnewjobs();
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});

	};



	handlenewjobChange(editor) { //eslint-disable-line
		this.setState({ newjob: editor });
	}



	render() {
		const { newjob } = this.state; //eslint-disable-line


		// console.log('Options are ', options);
		return (
			<div className="col-md-12">



				<Modal
					isOpen={this.state.upSingleModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary modal-dialog-big "
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">
							New Job
						</h4>
					</div>
					<div className="modal__body">
						{this.state.allkeys &&
							this.state.allkeys.map((tech, index) => {
								return (
									<tr key={index}>
										<td>{index}</td>
										<td>{tech}</td>
									</tr>
								);
							})}
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"


						>
							<div className="form__form-group">
								<span className="form__form-group-label">Job Description</span>
								<div className="form__form-group-field">
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + newjob + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handlenewjobChange.bind(this)}

									/>
								</div>
							</div>



							<div className="col-md-12">
								<br />
							</div>


							<div className="row" style={{ width: '100%' }}>
								<div className="col-md-12">
									<br />
								</div>
								<div className="col-md-2">
								</div>
								<div className="col-md-4">
									<span className="btn btn-success  float-right"
										onClick={() => this.handleSingleSubmit('Approved')}
									>
										Submit
									</span>
								</div>




							</div>
						</form>
					</div>
				</Modal>
			</div >
		);
	}
}

export default AmEmployer;
