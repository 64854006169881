import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../QueryMe';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import Alert from '../../../shared/components/Alert';
import ProfileImage from '../ProfileImage';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
const apiurl = MY_API_URL;
import Resizer from 'react-image-file-resizer';
import {
	Card,
	CardBody,
	Col,
	Row,
} from 'reactstrap';
const initialstate = {
	email: localStorage.by,
	name: localStorage.name,
	mobile: localStorage.pmobile,
	image: localStorage.profile_img,
	technologies: localStorage.technologies,
	showAlert: false,
	alertType: '',
	alertText: '',
	pwd: '',
	cpwd: '',
	profile: null,
	prefix: localStorage.sufix,
	prefixoptions: [],
	failbtn: false,
};
class AllUserProfileEdit extends Component {
	constructor (props) {
		super(props);
		this.base64Image = this.base64Image.bind(this);
	}
	state = { ...initialstate };

	componentDidMount() {
		if (localStorage.gotodash === '1') {
			this.props.history.push({
				pathname: '/dashboard',
				backTo: '/',
			});
		} else {
			let fsprefix = localStorage.sufix;
			let flprefix = [];
			let fnprefix = '';
			if (fsprefix) {
				flprefix = fsprefix.split('@');
				fnprefix = flprefix[1];
			}

			this.setState({ nprefix: fnprefix });
			this.handleGetprefix();
		}
	}

	handleSelectChange = (selectedOption) => {
		let sprefix = selectedOption.value;
		let lprefix = sprefix.split('@');

		let nprefix = lprefix[1];
		this.setState({ prefix: sprefix, nprefix: nprefix });
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleProfileSubmit = (event) => {
		event.preventDefault();
		if (this.state.pwd != this.state.cpwd) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Password and Confirm Password Not Matched",
			});
		} else {

			if (isNaN(this.state.mobile)) {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: 'Invalid Mobile',
				});
			} else {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};
				var postData = {
					email: this.state.email,
					mobile: this.state.mobile,
					name: this.state.name,
					image: this.state.image,
					pwd: this.state.pwd,
					role: localStorage.roleSelected,
					technologies: localStorage.technologies,
					prefix: this.state.prefix,
				};
				const pupurl = apiurl + 'updateUserProfile';
				axios
					.post(pupurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log('Response is ', response.data);
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'warning',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{
									showAlert: true,
									alertType: 'success',
									alertText: response.data.message,
									showEbox: false,
									showObox: true,
								},
								() => {
									localStorage.setItem('profile_img', this.state.image);
									localStorage.setItem('name', this.state.name);
									localStorage.setItem(
										'mobile',
										this.state.nprefix + '-' + this.state.mobile,
									);

									localStorage.setItem('pmobile', this.state.mobile);
									localStorage.setItem('sufix', this.state.prefix);
									localStorage.setItem('profile_completed', 1);
									localStorage.setItem('gotodash', 1);
									window.location.reload(false);
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Request Failed Try again Later!',
						});
					});
			}
		}
	};

	handleGetprefix = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'getprefix';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('allprefix: ', response.data.allprefix);
				if (!response.data.error) {
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname =
							prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});
					this.setState({
						prefixoptions: prefixoptions,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};
	getImageType(giventype) {
		var type = '';

		switch (giventype) {
			case 'image/png':
				type = 'PNG';
				break;
			case 'image/gif':
				type = 'JPEG';
				break;
			case 'image/jpeg':
				type = 'JPEG';
				break;
			case 'image/jpg':
				type = 'JPEG';
				break;
			default:
				type = 'JPEG';
				break;
		}
		return type;
	}
	base64Image(event) {
		var mig = event.target.files[0];
		console.log('gottype', event.target.files[0].type);
		var gottype = this.getImageType(event.target.files[0].type);

		console.log('gottype', gottype);
		this.setState(
			{
				failbtn: true,
			},
			() => {
				console.log(this.state.image);
				var fileInput = false;
				if (mig) {
					fileInput = true;
				}
				if (fileInput) {
					Resizer.imageFileResizer(
						mig,
						300,
						300,
						gottype,
						100,
						0,
						(uri) => {
							console.log(uri);
							const baseimageuri = uri;

							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};
							var postData = {
								base64: baseimageuri,
							};

							const base64loadurl = apiurl + 'base64load';
							axios
								.post(base64loadurl, postData, {
									headers: headers,
								})
								.then((response) => {
									console.log('Response is ', response.data);

									if (response.data.error) {
										this.setState({
											showAlert: true,
											alertType: 'warning',
											alertText: response.data.message,
											failbtn: false,
										});
									} else {
										this.setState({
											showAlert: false,
											image: response.data.fileurl,
											failbtn: false,
										});
									}
								})
								.catch(() => {
									this.setState({
										alertType: 'danger',
										showAlert: true,
										alertText: 'Failled Try Again Later!',
									});
								});
						},
						'base64',
					);
				}
			},
		);
	}
	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};
	render() {
		const {
			prefixoptions,
			prefix,
			failbtn,
			pwd,
			cpwd,
			showPassword
		} = this.state;


		return (
			<>
				{localStorage.profile_completed === '0' ? (
					<Col md={{ size: 8, offset: 2 }}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h3 className="bold-text">
										Edit Profile
<Link className="float-right" style={{ fontSize: '14px' }} to="/profile">Back to Profile</Link>
									</h3>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
								</div>
								<form
									className="form"
									onSubmit={(event) => this.handleProfileSubmit(event)}
								>
									<Row style={{ width: '100%' }}>
										{/* left side */}
										<Col md={6}>
											{/* profile image */}
											<Row style={{ width: '100%' }}>
												<Col md={4}>
													<div className="profile__information">
														<div className="profile__avatar">
															<ProfileImage clname="userprofileimg" flpath={this.state.image} />
														</div>
													</div>
												</Col>
												<Col md={8}>
													<div className="form__form-group">
														<span className="form__form-group-label">Profile Image</span>
														<div className="form__form-group-field">
															<input type="file" onChange={this.base64Image} />
															{/* <input type="file" onChange={this.uploadImage} /> */}
														</div>
													</div>
												</Col>
											</Row>
											{/* profile image end */}

											<div className="form__form-group">
												<span className="form__form-group-label">Your Full Name</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<AccountOutlineIcon />
													</div>

													<input
														name="name"
														type="text"
														placeholder="Your Full Name"
														defaultValue={this.state.name ? this.state.name : ''}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<div className="form__form-group">
												<span className="form__form-group-label">Your Mobile</span>
												<div className="form__form-group-field">
													<Select
														name="prefix"
														value={prefixoptions.filter(
															(option) => option.value == prefix,
														)}
														onChange={this.handleSelectChange}
														options={prefixoptions}
														clearable={false}
														placeholder="Country"
														className="react-select"
														classNamePrefix="react-select"
													// defaultMenuIsOpen={true}
													// isDisabled={true}
													/>

													<input
														name="mobile"
														type="text"
														placeholder="Your Mobile"
														defaultValue={this.state.mobile ? this.state.mobile : ''}
														onChange={this.handleChange}
														pattern="^\d{10}$"
														title="Min and Max 10 Digits"
														required
													/>
												</div>
											</div>
										</Col>
										{/* right side */}
										<Col md={6}>
											<div className="form__form-group">
												<span className="form__form-group-label">Email ID</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<AccountOutlineIcon />
													</div>

													<input
														name="email"
														type="email"
														placeholder="Your Email"
														defaultValue={this.state.email ? this.state.email : ''}
														required
														readOnly
													/>
												</div>
											</div>

											<div className="form__form-group">
												<span className="form__form-group-label">Your Password</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="pwd"
														type={showPassword ? 'text' : 'password'}
														placeholder="Your Password"
														value={pwd}
														onChange={this.handleChange}
														pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
														title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
														required
														onKeyUp={(e) => {
															e.preventDefault();
															if (this.state.pwd != this.state.cpwd) {
																this.setState({
																	showAlert: true,
																	alertType: 'warning',
																	alertText: "Password and Confirm Password Not Matched",
																});
															} else {
																this.setState({
																	showAlert: false,
																	alertType: 'success',
																	alertText: "Password and Confirm Password  Matched",
																});
															}
														}}
													/>
													<button
														className={`form__form-group-button${showPassword ? ' active' : ''
															}`}
														onClick={(e) => this.showPassword(e)}
														type="button"
													>
														<EyeIcon />
													</button>
												</div>
											</div>

											<div className="form__form-group">
												<span className="form__form-group-label">Confirm Password</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="cpwd"
														type={showPassword ? 'text' : 'password'}
														placeholder="Confirm Password"
														value={cpwd}
														onChange={this.handleChange}
														pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
														title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
														required
														onKeyUp={(e) => {
															e.preventDefault();
															if (this.state.pwd != this.state.cpwd) {
																this.setState({
																	showAlert: true,
																	alertType: 'warning',
																	alertText: "Password and Confirm Password Not Matched",
																});
															} else {
																this.setState({
																	showAlert: false,
																	alertType: 'success',
																	alertText: "Password and Confirm Password  Matched",
																});
															}
														}}
													/>
													<button
														className={`form__form-group-button${showPassword ? ' active' : ''
															}`}
														onClick={(e) => this.showPassword(e)}
														type="button"
													>
														<EyeIcon />
													</button>
												</div>
											</div>

											<button
												className="btn btn-primary account__btn account__btn--small"
												// onClick={this.handleProfileSubmit}
												type="submit"
												disabled={failbtn}
											>
												Update Profile
							</button>
										</Col>
									</Row>








								</form>
							</CardBody>
						</Card>
					</Col>
				) : (
						<div className="account">
							<div className="account__wrapper">
								<div className="account__card">
									<div className="account__head">
										<h3 className="account__title">Edit Profile</h3>
										<h4 className="account__subhead subhead">
											<Link to="/profile">Back to Profile</Link>
										</h4>
									</div>

									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}

									<form
										className="form"
										onSubmit={(event) => this.handleProfileSubmit(event)}
									>
										<div className="profile__information">
											<div className="profile__avatar">
												<ProfileImage clname="" flpath={this.state.image} />
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Profile Image</span>
											<div className="form__form-group-field">
												<input type="file" onChange={this.base64Image} />
												{/* <input type="file" onChange={this.uploadImage} /> */}
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Email ID</span>
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<AccountOutlineIcon />
												</div>

												<input
													name="email"
													type="email"
													placeholder="Your Email"
													defaultValue={this.state.email ? this.state.email : ''}
													required
													readOnly
												/>
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Your Full Name</span>
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<AccountOutlineIcon />
												</div>

												<input
													name="name"
													type="text"
													placeholder="Your Full Name"
													defaultValue={this.state.name ? this.state.name : ''}
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Your Mobile</span>
											<div className="form__form-group-field">
												<Select
													name="prefix"
													value={prefixoptions.filter(
														(option) => option.value == prefix,
													)}
													onChange={this.handleSelectChange}
													options={prefixoptions}
													clearable={false}
													placeholder="Country"
													className="react-select"
													classNamePrefix="react-select"
												// defaultMenuIsOpen={true}
												// isDisabled={true}
												/>

												<input
													name="mobile"
													type="text"
													placeholder="Your Mobile"
													defaultValue={this.state.mobile ? this.state.mobile : ''}
													onChange={this.handleChange}
													pattern="^\d{10}$"
													title="Min and Max 10 Digits"
													required
												/>
											</div>
										</div>

										<button
											className="btn btn-primary account__btn account__btn--small"
											// onClick={this.handleProfileSubmit}
											type="submit"
											disabled={failbtn}
										>
											Update Profile
							</button>
									</form>
								</div>
							</div>
						</div>
					)}
			</>

		);
	}
}

export default withRouter(AllUserProfileEdit);
