import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
// import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import TopbarWithNavigationcandy from '../Apanel/topbar_with_navigation/TopbarWithNavigationcandy';
import Dashboard from './components/Dashboard';
import TestReport from '../Apanel/TestReport';
import TestAdvReport from '../Apanel/TestAdvReport';
import CustomTestReport from './components/CustomTestReport';
import StartTest from './components/StartTest';
import StartAdvTest from './components/StartAdvTest';
import CustomStartTest from './components/CustomStartTest';
import TakeTest from './components/TakeTest';
import Profile from '../Apanel/components/ProfileCard';
import ProfileEdit from '../Apanel/UserProfileEdit';

const initialstate = {
	sidebarShow: false,
	sidebarCollapse: true,
};

class TestsPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': true,
		});

		return (
			<div>
				<div className={layoutClass}>
					{/* <div className="layout layout--top-navigation"> */}
					{/* <Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/> */}
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
					<TopbarWithNavigationcandy
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/testreport" component={TestReport} />
					<Route path="/testadvreport" component={TestAdvReport} />
					<Route path="/customtestreport" component={CustomTestReport} />
					<Route path="/starttest" component={StartTest} />
					<Route path="/startadvtest" component={StartAdvTest} />
					<Route path="/customstarttest" component={CustomStartTest} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/taketest" component={TakeTest} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />
				</div>
			</div>
		);
	}
}

export default withRouter(TestsPanel);
