import React, { Component } from 'react';

import ReactDOM from 'react-dom'; // eslint-disable-line
import EditableLabel from 'react-inline-editing';
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import Flagimg from '../../Apanel/Flagimg';

// import Switch from 'react-switch';
import Select from 'react-select';
// import SearchIcon from 'mdi-react/SearchIcon';
import {
	Button,
	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Modal,
} from 'reactstrap';


import Alert from '../../../shared/components/Alert';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	dashboard: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	oldkeyname: '',
	newkeyname: '',
	noorid: 0,

	addKeyModal: false,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	prefixoptions: [],
	akeywords: [],
	token: localStorage.token,
};
class ManageDashboards extends Component {
	constructor (props) {
		super(props);
		// this._handleFocus = this._handleFocus.bind(this);
		// this._handleFocusOut = this._handleFocusOut.bind(this);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetDashs(localStorage.token);
		this.handleGetprefix();
		localStorage.setItem('dashbid', '');
		localStorage.setItem('dashbname', '');
	}
	_handleFocus(noorid, text) {
		console.log(text);
		console.log('Focused with: ' + text + ' noorid:' + noorid);
		this.setState({
			oldkeyname: text,
			noorid: noorid,
		});
	}

	_handleFocusOut(noorid, newkeyname) {
		console.log('Left editor with: ' + newkeyname + ' noorid:' + noorid);
		console.log('oldkeyname', this.state.oldkeyname);
		console.log('noorid', this.state.noorid);
		if (noorid == this.state.noorid) {
			if (newkeyname == this.state.oldkeyname) {
				console.log('no change');
			} else {
				// post started
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const editkeyurl = apiurl + 'editkey';
				var postData = {
					noorid: noorid,
					keyword: newkeyname,
				};
				axios
					.post(editkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							swal(newkeyname + ' ' + response.data.message, {
								icon: 'warning',
							});
							window.location.reload(false);
						} else {
							swal(newkeyname + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					});
				//post ended
			}
		}
	}
	handleSelectChange = (selectedOption) => {
		console.log(selectedOption.value);
		this.setState({ conid: selectedOption.value });
	};
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleGetprefix = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'getprefix';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('allprefix: ', response.data.allprefix);
				if (!response.data.error) {
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname = prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});
					this.setState({
						prefixoptions: prefixoptions,
						allcountrys: response.data.allprefix,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleDashSubmit = (event) => {
		event.preventDefault();
		console.log(this.state.keyword);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const contdtls = this.getContryDetails(this.state.conid);

		var ccode = contdtls[0].ccode;
		ccode = ccode.toLowerCase();

		const addkeyurl = apiurl + 'adddashboard';
		var postData = {
			dashboard: this.state.dashboard,
			conid: this.state.conid,
			ccode: ccode,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							addKeyModal: false,
							showAlert: false,
						},
						() => {
							this.handleGetDashs(localStorage.token);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleAkey = () => {
		this.setState({
			addKeyModal: true,
			showAlert: false,
		});
	};

	handleDkey = (id, keyword, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(keyword + 'keyword');

		swal({
			title: 'Delete ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const delkeyurl = apiurl + 'deldash';
				var postData = {
					keyid: id,
				};
				axios
					.post(delkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetDashs(localStorage.token);
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'warning',
						});
					});
			}
		});
	};
	handleTkey = (id, keyword, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(keyword + 'keyword');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togdashboard';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetDashs(localStorage.token);
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleCkey = () => {
		this.setState({
			addKeyModal: false,
			showAlert: false,
		});
	};

	handleGetDashs = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'adminalldashboards';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];
					console.log('new', response.data);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						akeywords: response.data.akeywords,
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	getDetails = (dashid) => {
		// console.log('normal ', dashid);
		var yesdel = 1;
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === dashid;
		});
		if (techname.length) {
			yesdel = techname[0].dashboard;
		}
		return yesdel;
	};
	getContryDetails = (conid) => {
		console.log('conid ', conid);

		let condtls = this.state.allcountrys.filter((contid) => {
			return contid.id === conid;
		});

		console.log('condtls ', condtls);
		return condtls;
	};
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};
	render() {
		const { dashboard, prefixoptions } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								Technology Keywords{' '}
								<Badge color="primary">Total {this.state.tkeys} </Badge>
								{'  '}
								<Badge color="success">Active {this.state.akeys} </Badge>
								{'  '}
								<Badge color="warning">InActive {this.state.bkeys} </Badge>
								{'  '}
								<Button
									className="float-right btn-sm"
									color="success"
									onClick={this.handleAkey}
								>
									+
								</Button>
							</h5>
						</div>
						{/* <form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										// onChange={(e) => {
										// 	this.handleSearch(e);
										// }}
									/>
								</div>
							</div>
						</form> */}
						{this.state.tshowAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Dashboard Name</th>
									<th>Country</th>
									<th>Manage</th>
									{/* <th>Delete</th> */}
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {
										var isdel = this.getDetails(hkey.id);
										// var ksts = false;
										// if (hkey.activated === 1) {
										// 	ksts = true;
										// }
										var flagname = hkey.ccode;
										flagname = flagname.toLowerCase();
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<EditableLabel
														text={hkey.dashboard}
														labelClassName="myLabelClass"
														inputClassName="myInputClass"
														inputHeight="25px"
														labelFontWeight="bold"
														inputFontWeight="bold"
														onFocus={this._handleFocus.bind(this, hkey.id)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															hkey.id,
														)}
													/>
													{/* <strong>{hkey.dashboard}</strong> */}
												</td>
												<td style={{ fontSize: '14px' }}>
													<Flagimg flagname={flagname} clname="flagimgadmin" />

													{hkey.cname}
												</td>
												{/* <td>
													<Switch
														onChange={this.handleTkey.bind(
															this,
															hkey.id,
															hkey.dashboard,
														)}
														checked={ksts}
													/>
												</td> */}
												<td>

													<button
														className="btn btn-success btn-sm"

														id={hkey.id}
														onClick={() => {
															localStorage.setItem('dashbid', hkey.id);
															localStorage.setItem('dashbname', hkey.dashboard);
															this.props.history.push('/dashbitems'); //eslint-disable-line
														}}
													>
														Dashboard Items
													</button>

												</td>
												<td>
													{isdel == 1 ? (
														<button
															className="btn btn-danger btn-sm"
															color="primary"
															id={hkey.id}
															onClick={this.handleDkey.bind(
																this,
																hkey.id,
																hkey.dashboard,
															)}
														>
															<span className="lnr lnr-trash" />
														</button>
													) : null}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.addKeyModal}
							modalClassName="ltr-support"
							className="modal-dialog--primary"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
								<h4 className="text-modal  modal__title">Add New Dashboard</h4>
							</div>
							<div className="modal__body">
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<form
									className="form"
									onSubmit={(event) => this.handleDashSubmit(event)}
								>
									<div className="form__form-group">
										<span className="form__form-group-label">Select Country</span>
										<div className="form__form-group-field">
											<Select
												name="prefix"
												onChange={this.handleSelectChange}
												options={prefixoptions}
												clearable={false}
												placeholder="Country"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<br />
									<div className="form__form-group">
										<span className="form__form-group-label">Dashboard</span>
										<div className="form__form-group-field">
											<input
												name="dashboard"
												type="text"
												placeholder="Enter Dashboard Name"
												className="form-control"
												value={dashboard}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add Dashboard
									</button>
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default ManageDashboards;
