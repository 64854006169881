import React, { Component } from 'react';

import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
// import Topbar from '../Apanel/topbar/Topbar';
import Sidebar from '../Apanel/sidebar/Sidebar';
import TopbarWithNavigationtech from '../Apanel/topbar_with_navigation/TopbarWithNavigationtech';
import Dashboard from './components/Dashboard';
import TechAddQuest from './components/TechAddQuest';
import Profile from '../Apanel/index';
import ProfileEdit from '../Apanel/UserProfileEdit';

import Emailids from './components/Emailids';

import ChgPwd from '../Apanel/ChgPwd';
import Mail from '../Mail/index';

import Asettings from './components/Asettings';
import CheckQuestions from './components/CheckQuestions';
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: true,
};

class TechnicalPanel extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {

	}


	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {

		const { sidebarShow, sidebarCollapse } = this.state;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebarCollapse,
		});

		return (
			<div>
				<div className={layoutClass}>
					{/* <div className="layout layout--top-navigation"> */}
					{/* <Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/> */}
					<Sidebar
						sidebarShow={sidebarShow}
						sidebarCollapse={sidebarCollapse}
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
					<TopbarWithNavigationtech
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					/>
				</div>
				<div className="container__wrap">
					<Route path="/asettings" component={Asettings} />
					<Route path="/checkques" component={CheckQuestions} />
					<Route path="/emailids" component={Emailids} />

					<Route path="/dashboard" component={Dashboard} />
					<Route path="/techaddques" component={TechAddQuest} />
					<Route path="/chgpwd" component={ChgPwd} />
					<Route path="/profile" component={Profile} />
					<Route path="/profileedit" component={ProfileEdit} />

					<Route path="/mail" component={Mail} />


				</div>
			</div>
		);
	}
}

export default withRouter(TechnicalPanel);
