import React, { Component } from 'react';
// import { Card, Col, CardBody } from 'reactstrap';
// import Inbox from './Inbox';
// import emails from './email-list';
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
const apiurl = MY_API_URL;
import Alert from '../../../shared/components/Alert';
const initialstate = {
  emails: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  addUserModal: false,
};
class InboxCard extends Component {
  constructor (props) {
    super(props);

  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetSettings();

  }





  handleGetSettings = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      usercode: localStorage.usercode,
    };
    const getuurl = apiurl + 'getmails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.error) {
          if (response.data.din == 2) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else if (response.data.din == 3) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            //localStorage.clear();
          }

        } else {
          console.log('all:', response.data);
          this.setState({
            emails: response.data.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // //localStorage.clear();
        //eslint-disable-line
      });
  };
  handleCkey = () => {
    this.setState({
      addUserModal: false,
    });
  };
  render() {
    const { emails } = this.state;  //eslint-disable-line
    return (

      <div className="col-md-12 col-lg-12">
        <div className="card">
          <div className="card-body">
            {this.state.showAlert ? (
              <Alert color={this.state.alertType}>
                <p>{this.state.alertText}</p>
              </Alert>
            ) : (
                <div className="inbox" role="presentation">
                  <div className="inbox__mailbox-list">
                    <button type="button" className="icon inbox__mailbox-list-btn btn btn-primary btn-sm">
                      <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20.71,7.04C20.37,7.38 20.04,7.71 20.03,8.04C20,8.36 20.34,8.69 20.66,9C21.14,9.5 21.61,9.95 21.59,10.44C21.57,10.93 21.06,11.44 20.55,11.94L16.42,16.08L15,14.66L19.25,10.42L18.29,9.46L16.87,10.87L13.12,7.12L16.96,3.29C17.35,2.9 18,2.9 18.37,3.29L20.71,5.63C21.1,6 21.1,6.65 20.71,7.04M3,17.25L12.56,7.68L16.31,11.43L6.75,21H3V17.25Z">

                        </path>
                      </svg>Compose</button>
                    <button type="button" className="inbox__list-button">
                      <div className="inbox__mailbox active">
                        <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M16,10H14V7H10V10H8L12,14M19,15H15A3,3 0 0,1 12,18A3,3 0 0,1 9,15H5V5H19M19,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z">

                          </path>
                        </svg>
                        <p className="inbox__mailbox-title">Inbox</p>
                        <span className="inbox__mailbox-label badge badge-secondary">21</span>
                      </div>
                    </button>
                    <button type="button" className="inbox__list-button">
                      <div className="inbox__mailbox">
                        <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z">

                          </path>
                        </svg>
                        <p className="inbox__mailbox-title">Sent Mail</p>
                      </div>
                    </button>
                    <button type="button" className="inbox__list-button">
                      <div className="inbox__mailbox">
                        <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M18,14V12H12.5L10.5,14H18M6,14H8.5L15.35,7.12C15.55,6.93 15.55,6.61 15.35,6.41L13.59,4.65C13.39,4.45 13.07,4.45 12.88,4.65L6,11.53V14Z">

                          </path>
                        </svg>
                        <p className="inbox__mailbox-title">Drafts</p>
                        <span className="inbox__mailbox-label badge badge-secondary">2</span>
                      </div>
                    </button>
                    <button type="button" className="inbox__list-button">
                      <div className="inbox__mailbox">
                        <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z">

                          </path>
                        </svg>
                        <p className="inbox__mailbox-title">Tagged</p>
                      </div>
                    </button>
                    <button type="button" className="inbox__list-button">
                      <div className="inbox__mailbox">
                        <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z">

                          </path>
                        </svg>
                        <p className="inbox__mailbox-title">Deleted</p>
                      </div>
                    </button>
                    <p className="inbox__labels">Labels</p>
                    <button type="button" className="inbox__list-button inbox__label active">
                      <span className="inbox__label-color inbox__label-color--green">

                      </span>Freelance</button>
                    <button type="button" className="inbox__list-button inbox__label">
                      <span className="inbox__label-color inbox__label-color--red">

                      </span>Work</button>
                    <button type="button" className="inbox__list-button inbox__label">
                      <span className="inbox__label-color inbox__label-color--blue">

                      </span>Travel</button>
                    <button type="button" className="inbox__list-button inbox__label">
                      <span className="inbox__label-color inbox__label-color--yellow">

                      </span>Clients</button>
                  </div>
                  <div className="inbox__container">
                    <div className="inbox__topbar">
                      <button className="inbox__topbar-button" type="button">
                        <svg className="mdi-icon inbox__topbar-button-icon" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z">

                          </path>
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div className="inbox__content">
                        <div className="inbox__emails-controls-wrap">
                          <div className="inbox__emails-controls">
                            <label className="checkbox-btn checkbox-btn--colored-click inbox__emails-control-checkbox" htmlFor="checkbox">
                              <input id="checkbox" className="checkbox-btn__checkbox" type="checkbox" />
                              <span className="checkbox-btn__checkbox-custom">
                                <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">

                                  </path>
                                </svg>
                              </span>
                            </label>
                            <div className="dropdown">
                              <button type="button" aria-haspopup="true" aria-expanded="false" className="icon icon--right btn btn-outline-secondary btn-sm">
                                <p>Filter By <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M7,10L12,15L17,10H7Z">

                                  </path>
                                </svg>
                                </p>
                              </button>
                              <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown__menu dropdown-menu">
                                <button type="button" tabIndex="0" role="menuitem" className="dropdown-item">Name</button>
                                <button type="button" tabIndex="0" role="menuitem" className="dropdown-item">Date</button>
                                <button type="button" tabIndex="0" role="menuitem" className="dropdown-item">Favorite</button>
                              </div>
                            </div>
                            <button type="button" className="icon inbox__emails-control-reload btn btn-outline-secondary btn-sm">
                              <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z">

                                </path>
                              </svg>
                            </button>
                          </div>
                          <div className="inbox__emails-controls-right">
                            <div className="inbox__emails-control-search">
                              <input placeholder="Search" />
                              <div className="inbox__emails-control-search-icon">
                                <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">

                                  </path>
                                </svg>
                              </div>
                            </div>
                            <div className="pagination__wrap pagination__wrap--small">
                              <nav className="pagination" aria-label="pagination">
                                <ul dir="ltr" className="pagination">
                                  <li className="pagination__item page-item disabled">
                                    <button type="button" className="pagination__link pagination__link--arrow page-link">
                                      <svg className="mdi-icon pagination__link-icon" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z">

                                        </path>
                                      </svg>
                                    </button>
                                  </li>
                                  <li className="pagination__item page-item">
                                    <button type="button" className="pagination__link pagination__link--arrow page-link">
                                      <svg className="mdi-icon pagination__link-icon" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z">

                                        </path>
                                      </svg>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table--bordered inbox__emails-table table">
                            <tbody>
                              {emails && emails.map((email, index) => {

                                return (
                                  <tr className="inbox__email-list-item" key={index}>
                                    <td>
                                      <label htmlFor="0" className="checkbox-btn checkbox-btn--colored-click inbox__email-list-item-checkbox">
                                        <input id="0" className="checkbox-btn__checkbox" type="checkbox" />
                                        <span className="checkbox-btn__checkbox-custom">
                                          <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">

                                            </path>
                                          </svg>
                                        </span>
                                      </label>
                                    </td>
                                    <td>
                                      <svg className="mdi-icon inbox__favorite" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z">

                                        </path>
                                      </svg>
                                    </td>
                                    <td className="inbox__email-table-name">{email.from}</td>
                                    <td className="inbox__email-table-preview">
                                      <div>
                                        <b>{email.subject}</b> Short Content</div>
                                    </td>
                                    <td>

                                    </td>
                                    <td className="inbox__email-table-date">{email.date}</td>
                                  </tr>
                                );
                              })}


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

    );
  }
}

export default InboxCard;
