import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DownIcon from 'mdi-react/ChevronDownIcon';
import {
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	UncontrolledDropdown,
} from 'reactstrap';
import TopbarNavLink from './TopbarNavLink';
import TopbarNavCategory from './TopbarNavCategory';
import MngLead from '../../../RecruiterPanel/components/clients/MngLead';
const initialstate = {
	showAddLead: false,
};
class TopbarNavRecruiter extends Component {
	constructor (props) {
		super(props);
		this._onButtonClick = this._onButtonClick.bind(this);
	}
	state = { ...initialstate };

	_onButtonClick() {
		this.setState({
			showAddLead: true,
		});
	}
	render() {
		return (
			<nav className="topbar__nav">
				{this.state.showAddLead ?
					<MngLead /> :
					null
				}
				{localStorage.dashbutype && localStorage.dashbutype == 'bdm' ? (

					<UncontrolledDropdown className="topbar__nav-dropdown">
						<DropdownToggle className="topbar__nav-dropdown-toggle">
							Business Development
						<DownIcon />
						</DropdownToggle>

						<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">

							<DropdownItem>
								<TopbarNavLink title="Client List" route="/clientlist" />
							</DropdownItem>
							<DropdownItem>
								<span className="topbar__link" onClick={this._onButtonClick} >
									<p className="topbar__link-title">Add Client</p>
								</span>
							</DropdownItem>


						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				{localStorage.dashbutype && localStorage.dashbutype == 'man' ? (

					<UncontrolledDropdown className="topbar__nav-dropdown">
						<DropdownToggle className="topbar__nav-dropdown-toggle">
							Reports <DownIcon />
						</DropdownToggle>
						<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
							<DropdownItem>
								<Link className="topbar__link" to="/teamusers">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Team Users </p>
								</Link>

							</DropdownItem>
							<DropdownItem>
								<Link className="topbar__link" to="/reportspage">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Reports </p>
								</Link>

							</DropdownItem>
							<DropdownItem>
								<Link className="topbar__link" to="/reportseven">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Weekly Report </p>
								</Link>

							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				{localStorage.dashbutype && localStorage.dashbutype == 'tl' ? (

					<UncontrolledDropdown className="topbar__nav-dropdown">
						<DropdownToggle className="topbar__nav-dropdown-toggle">
							Reports <DownIcon />
						</DropdownToggle>
						<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
							<DropdownItem>
								<Link className="topbar__link" to="/teamusers">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Team Users </p>
								</Link>

							</DropdownItem>
							<DropdownItem>
								<Link className="topbar__link" to="/reportspage">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Reports </p>
								</Link>

							</DropdownItem>
							<DropdownItem>
								<Link className="topbar__link" to="/reportseven">
									<span className={`topbar__link-icon lnr lnr-layers`} />
									<p className="topbar__link-title"> Weekly Report </p>
								</Link>

							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}

				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Sourcing
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
						<DropdownItem>
							<TopbarNavLink title="Jobs Published" route="/jobspublished" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavLink title="Jobs Saved as Drafts" route="/jobssaved" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavLink title="Jobs Deactivated" route="/jobsdeactivated" />
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>

				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Screening
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">

						<DropdownItem>
							<TopbarNavLink title="Phone Screen" route="/faqtechview" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavCategory title="Tech Tests" icon="smart-phone">
								<TopbarNavLink title="Add Test" route="/addtest" />
								<TopbarNavLink title="Saved Tests" route="/savetest" />
								<TopbarNavLink title="Sent Tests" route="/senttests" />
								<TopbarNavLink title="Completed Tests" route="/comtest" />
							</TopbarNavCategory>
						</DropdownItem>
						<DropdownItem>
							<TopbarNavCategory title="Tech ADV Tests" icon="smart-phone">
								<TopbarNavLink title="Add ADV Test" route="/addadvtest" />
								<TopbarNavLink title="Saved ADV Tests" route="/saveadvtest" />
								<TopbarNavLink title="Sent ADV Tests" route="/sentadvtests" />
								<TopbarNavLink title="Completed ADV Tests" route="/comadvtest" />
							</TopbarNavCategory>
						</DropdownItem>
						<DropdownItem>
							<TopbarNavCategory title="Custom Tests" icon="smart-phone">
								<TopbarNavLink title="Add Custom Test" route="/addcustomtest" />
								<TopbarNavLink title="UnSaved Test List" route="/mycustoms" />
								<TopbarNavLink
									title="Saved Test List"
									route="/customsavedtest"
								/>
								<TopbarNavLink title="Sent Test List" route="/customsenttest" />
								<TopbarNavLink
									title="Completed Custom Tests"
									route="/cuscomtest"
								/>
							</TopbarNavCategory>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>

				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Benchsales
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">

						<DropdownItem>
							<TopbarNavLink title="Bench Candidates" route="/bcandys" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavLink title="Hotlist" route="/hotlist" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavLink title="Job Cloud" route="/jobcloud" />
						</DropdownItem>

					</DropdownMenu>
				</UncontrolledDropdown>


				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Contacts
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">

						{/* <DropdownItem>
							<TopbarNavLink title="Phone Screen" route="/faqtechview" />
						</DropdownItem> */}
						<DropdownItem>
							<TopbarNavLink title="Vendor List" route="/vendorlist" />
						</DropdownItem>

						<DropdownItem>
							<TopbarNavCategory title="Clients" icon="smart-phone">
								<TopbarNavLink title="Add Custom Test" route="/addcustomtest" />
								<TopbarNavLink title="UnSaved Test List" route="/mycustoms" />
								<TopbarNavLink
									title="Saved Test List"
									route="/customsavedtest"
								/>
								<TopbarNavLink title="Sent Test List" route="/customsenttest" />
								<TopbarNavLink
									title="Completed Custom Tests"
									route="/cuscomtest"
								/>
							</TopbarNavCategory>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>


				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Documentation
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
						<DropdownItem>
							<TopbarNavLink title="Jobs Published" route="/jobspublished" />
						</DropdownItem>
						<DropdownItem>
							<TopbarNavLink title="Jobs Saved as Drafts" route="/jobssaved" />
						</DropdownItem>

					</DropdownMenu>
				</UncontrolledDropdown>

			</nav>
		);
	}
}

export default TopbarNavRecruiter;
