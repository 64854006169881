/* eslint-disable  */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ResultMeter from '../../Apanel/ResultMeter';
class RecentTakenTestDetails extends PureComponent {
	constructor() {
		super();
		this.state = {
			activeIndex: 0,
		};
	}

	render() {
		return (
			<Col md={12} xl={3} lg={6} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget">
						<div className="card__title">
							<h5 className="bold-text">
								Test ID: {this.props.tname}
								<span style={{ float: 'right', fontSize: '14px' }}>
									{this.props.tstep}
									<sup>{this.props.tstepd}</sup>
								</span>
							</h5>
						</div>
						<p>
							<b>Sent to: </b>
							{this.props.sentto}
						</p>

						<p>
							<b>Technologies: </b>
							{this.props.technologies}
						</p>
						<p>
							<b>Sent by: </b>
							{this.props.sentby}
						</p>
						<ResultMeter
							marks={this.props.totalCorrect}
							mmarks={this.props.totalquestions}
						/>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default withTranslation('common')(RecentTakenTestDetails);
