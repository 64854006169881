import React, { Component } from 'react';


import { withRouter } from 'react-router-dom';



const initialstate = {
	keyname: '',
};
class CheckProfileDone extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		var alldashboards = '[]';
		// console.log('localStorage.dashboards1', localStorage.dashboards);
		if (localStorage.profile_completed === '0') {

			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else if (localStorage.dashboards) {
			// console.log('localStorage.dashboards2', localStorage.dashboards);
			alldashboards = localStorage.dashboards;

			alldashboards = JSON.parse(alldashboards);
			if (alldashboards.length <= 0) {
				this.props.history.push({
					pathname: '/asettings',
					backTo: '/',
				});
			}
		} else {
			// console.log('localStorage.dashboards3', localStorage.dashboards);

			this.props.history.push({
				pathname: '/asettings',
				backTo: '/',
			});

		}
	}

	render() {
		return <span></span>;
	}
}


export default withRouter(CheckProfileDone);
