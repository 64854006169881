import React, { Component } from 'react';
/* global  */
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../Apanel/QueryMe';
// import Switch from 'react-switch';
import swal from 'sweetalert';

import {
	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Container,
	Row,
} from 'reactstrap';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import Alert from '../../../shared/components/Alert';
import ProfileImage from '../../Apanel/ProfileImage';
import CheckProfileDone from '../../Apanel/CheckProfileDone';
const apiurl = MY_API_URL;

import TotalProfit from '../../AdminPanel/ecomponents/TotalProfit';
const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'recruiter',
	emails: '',
	domain: '',
	token: localStorage.token,
	content_add: '',
	myItems: [],
	testEmails: [],
	currentcolor: [
		'#000000',
	],
	comprec: [{ name: 'Page A', amt: '0' }],
};
class Dashboard extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		// if (localStorage.profile_completed === '0') {
		// 	localStorage.setItem('came_from', 'dashboard');
		// 	this.props.history.push({
		// 		pathname: '/profile',
		// 		backTo: '/',
		// 	});
		// } else {
		this.handleGetUsers();
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
		// }
	}
	// handleChange = (event) => {
	// 	const { name, value } = event.target;
	// 	console.log(name + ' ' + value);
	// 	this.setState({ [name]: value });
	// };

	handleFocus(event) {
		event;
		this.setState({ content_add: '' });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};
	handleAddEmail = async (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];


		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		console.log(this.state.allones);
		await Promise.all(
			aallemails.map((aallemail) => {
				return new Promise((resolve) => {
					let isvalidrec = re.test(aallemail);
					if (isvalidrec) {
						var newemail_string_array = aallemail.split('@');
						var domain_string_location = newemail_string_array.length - 1;
						var newemaildomain = newemail_string_array[domain_string_location];
						if (mainemaildomain == newemaildomain) {
							var njk = this.state.allones.includes(aallemail);
							var dnjk = this.state.testEmails.includes(aallemail);
							if (njk) {
								resolve('');
							} else {
								if (dnjk) {
									resolve('');
								} else {
									newArray.push({
										content: aallemail,
										id: ++this.lastId,
									});
									testEmails.push(aallemail);
									this.setState(
										{
											myItems: newArray,
											content_add: '',
										},
										() => {
											console.log(this.state.myItems);
											resolve('');
										},
									);
								}
							}
						} else {
							resolve('');
						}
					} else {
						resolve('');
					}
				});
			}),
		);
		this.setState({
			content_add: '',
		});
	};

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	handleAccess = (recemail, e) => {
		e;
		localStorage.setItem('viewrecruiter', recemail);
		// this.props.history.push({
		// 	pathname: '/recruiter/dashboard',
		// 	recemail: recemail,
		// });
		location.assign('/recruiter/dashboard');
		// const win = window.open('/recruiter/dashboard');
		// if (win != null) {
		// 	win.focus();
		// }
	};
	handleAkey = () => {
		this.setState({
			addUserModal: true,
		});
	};

	handleCkey = () => {
		this.setState({
			addUserModal: false,
		});
	};
	handleTkey = (id, keyword, checked) => {
		// console.log(checked + 'checked');
		// console.log(id + 'id');
		// console.log(keyword + 'keyword');
		var keysts = checked == true ? 0 : 1;
		var kstatus = 'Block';
		if (checked) {
			kstatus = 'Unblock';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'toguser';
				var postData = {
					userid: id,
					ustatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetUsers();
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleGetUsers = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			role: 'recruiter',
			cemail: localStorage.by,
		};
		const getuurl = apiurl + 'comptexperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					let hk = [localStorage.by];
					let gk = [...response.data.data];
					gk.map((rk) => {
						hk.push(rk.email);
					});
					this.setState({
						allusers: [...response.data.data],
						allones: hk,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		const {
			comprec,
			allusers,
		} = this.state;
		return (
			<Container className="dashboard">
				<CheckProfileDone />
				<Row>
					<TotalProfit
						tname="Company Users"
						totaln={allusers.length}
						rdata={comprec}
					/>
				</Row>
				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">
									All Users
									{/* <Button
										className="float-right btn-sm"
										color="success"
										onClick={this.handleAkey}
									>
										+
									</Button> */}
								</h5>
							</div>
							<Table responsive hover>
								<thead>
									<tr>
										<th>#</th>
										<th>.</th>
										{/* <th>Manage</th> */}
										<th>Email</th>
										<th>Type</th>
										<th>Name</th>
										<th>Mobile</th>
										<th>Registered</th>
										{/* <th>Access</th> */}
									</tr>
								</thead>
								<tbody>
									{this.state.allusers &&
										this.state.allusers.map((huser, index) => {
											if (huser.role === 'recruiter') {
												let userole = 'Recruiter';
												if (huser.usertype == 2) {
													userole = 'DBM';
												}
												if (huser.usertype == 3) {
													userole = 'Team Lead';
												}
												// var usts = true;
												// if (huser.blocked === 1) {
												// 	usts = false;
												// }
												return (
													<tr key={index}>
														<td>{index + 1}</td>
														<td>
															<ProfileImage
																clname="topbar__avatar-img"
																flpath={
																	huser.image_url
																		? huser.image_url
																		: this.state.image
																}
															/>
														</td>
														{/* <td>
															<Switch
																onChange={this.handleTkey.bind(
																	this,
																	huser.id,
																	huser.email,
																)}
																checked={usts}
															/>
														</td> */}
														<td>{huser.email}</td>
														<td>
															<h4>
																<Badge color="success">{userole}</Badge>
															</h4>
														</td>
														<td>{huser.name}</td>
														<td>{huser.mobile}</td>
														<td>{huser.createdon}</td>
														{/* <td>
															<button
																className="btn btn-sm btn-primary accessbtnrec"
																type="button"
																onClick={this.handleAccess.bind(
																	this,
																	huser.email,
																)}
															>
																Access
															</button>
														</td> */}
													</tr>
												);
											}
										})}
								</tbody>
							</Table>

						</CardBody>
					</Card>
				</Col>
			</Container>
		);
	}
}

export default Dashboard;
