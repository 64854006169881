import React, { Component } from 'react';
import JobsList from './JobsList';
import { withRouter } from 'react-router-dom';

class JobsSaved extends Component {
	constructor (props) {
		super(props);
		localStorage.setItem('jstatus', 0);
	}

	componentDidMount() {
		if (localStorage.dashbid) { //eslint-disable-line
		} else {
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}




	render() {

		return (
			<div> <JobsList /></div >
		);
	}
}


export default withRouter(JobsSaved);
