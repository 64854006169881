import React, { Component } from 'react';

import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
class App extends Component {
	constructor () {
		super();
		this.state = {
			loading: true,
			loaded: false,
		};
	}

	componentDidMount() {

		this.setState({
			loading: false,
			loaded: true,
		});

		loadProgressBar();
	}


	render() {
		const { loaded, loading } = this.state;
		return (
			<BrowserRouter>
				<div>
					{!loaded && (
						<div className={`load${loading ? '' : ' loaded'}`}>
							<div className="load__icon-wrap">
								<svg className="load__icon">
									<path
										fill="#4ce1b6"
										d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
									/>
								</svg>
							</div>
						</div>
					)}
					<div>
						<Router />
					</div>
				</div>
			</BrowserRouter>
		);
	}
}

export default hot(module)(App);
