import React, { PureComponent } from 'react';

//const queryString = require('query-string'); //eslint-disable-line
import axios from 'axios';
import { MY_API_URL, CRYPTKEY } from '../Apanel/QueryMe';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../shared/components/Alert';
import { withRouter } from 'react-router-dom';

const apiurl = MY_API_URL;
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTKEY);

const initialstate = {
	email: '',
	otp: '',
	showEbox: true,
	showObox: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	otpurl: '',
	mobile: localStorage.pmobile,
	profile: null,
	prefix: localStorage.sufix,
	prefixoptions: [],
	name: '',
	secondary_phone: '',
	skype: '',
	current_location: '',
	passport: '',
	ssn: '',
	dob: '',
	work_auth: '',
	employer_info: '',
	relocation: '',
	availability: '',
	linkedin: '',
	resume: '',
};
class AmCandidate extends PureComponent {
	constructor (props) {
		super(props);

	}
	state = { ...initialstate };

	componentDidMount() {

		if (localStorage.token) {
			this.props.history.push('/dashboard'); //eslint-disable-line
		} else {

			this.setState({
				jtoken: this.props.location.jtoken,
				japid: this.props.location.japid
			}, () => {
				if (this.state.jtoken == '') {
					this.props.history.push('/applytojob'); //eslint-disable-line
				} else {
					var jtokendtls = cryptr.decrypt(this.state.jtoken);
					jtokendtls = JSON.parse(jtokendtls);
					this.setState({
						jobid: jtokendtls.jobid,
						jemail: jtokendtls.email,
						recid: jtokendtls.recid,
					});

				}
			});
		}

	}



	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleEmailSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				email: this.state.email,
				jobid: this.state.jobid,
			};
			const loginurl = apiurl + 'candylogin';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
							showEbox: false,
							showObox: true,
							otpurl: 'candyvalidateOTP',
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleOtpSubmit = (event) => {
		event.preventDefault();
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			if (isNaN(this.state.otp)) {
				this.setState({
					showAlert: true,
					alertType: 'danger',
					alertText: 'Enter Valid OTP Only Numbers',
				});
			} else {
				const headers = {
					'Content-Type': 'application/json',
				};
				var postData = {
					otp: this.state.otp,
					email: this.state.email,
					jobid: this.state.jobid,
				};

				const otpurl = apiurl + this.state.otpurl;

				axios
					.post(otpurl, postData, {
						headers: headers,
					})
					.then((response) => {
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'warning',
								alertText: response.data.message,
							});
						} else {
							console.log(response.data.data);

							this.setState(
								{

									showAlert: false,
									showLinkbox: false,
									showEbox: false,
									showObox: false,
									wantpwd: false,
									loginwithpwd: false,
								},
								() => {
									var role = response.data.data.role;
									var upanel = response.data.upanel;
									localStorage.setItem('usercode', response.data.data.id);
									localStorage.setItem('token', response.data.token);
									localStorage.setItem('roleSelected', role);
									localStorage.setItem('by', response.data.data.email);
									localStorage.setItem('domain', response.data.data.domain);
									if (response.data.data.image_url == 0) {
										response.data.data.image_url = this.state.dimgurl;
									}
									localStorage.setItem(
										'profile_img',
										response.data.data.image_url,
									);
									localStorage.setItem('name', response.data.data.name);
									localStorage.setItem('sufix', response.data.data.sufix);
									localStorage.setItem(
										'mobile',
										response.data.data.mprefix +
										'-' +
										response.data.data.mobile,
									);
									localStorage.setItem('pmobile', response.data.data.mobile);
									localStorage.setItem('upanel', upanel);
									localStorage.setItem(
										'profile_completed',
										response.data.data.profile_completed,
									);
									localStorage.setItem('logo', response.data.data.logo);
									localStorage.setItem(
										'createdon',
										response.data.data.createdon,
									);

									if (role === 'test_taker') {
										localStorage.setItem(
											'tests_taken',
											response.data.data.tests_taken,
										);
									}

									window.location.reload(false);
								},
							);


						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};


	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};

	render() {
		const { showPassword, email, otp } = this.state; //eslint-disable-line



		return (
			<div className="account">


				{this.state.showEbox ? (
					<div className="account__wrapper">
						<div className="account__card">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<div className="account__head">
								<h3 className="account__title">
									<span className="account__logo">
										Application
											<span className="account__logo-accent"> Process</span>
									</span>
								</h3>
								<h4 className="account__subhead subhead">
									Enter Email ID to Get OTP
									</h4>
							</div>
							<form
								className="form"
								onSubmit={(event) => this.handleEmailSubmit(event)}
							>

								<div className="form__form-group">
									<span className="form__form-group-label">Enter Email ID</span>
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="email"
											type="email"
											placeholder="Your Email"
											value={email}
											onChange={this.handleChange}
											required
											onKeyUp={(e) => {
												e.preventDefault();

												if (e.keyCode == 13) {
													this.handleEmailSubmit(event);
												}
											}}
										/>
									</div>
								</div>

								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Submit
									</button>


							</form>


						</div>
					</div>
				) : null}
				{this.state.showObox ? (
					<div className="account__wrapper">
						<div className="account__card">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<div className="account__head">
								<h3 className="account__title">
									<span className="account__logo">
										Validate
											<span className="account__logo-accent"> OTP</span>
									</span>
								</h3>
								<h4 className="account__subhead subhead">
									Enter OTP sent to your Email ID to Verify
									</h4>
							</div>
							<form
								className="form"
								onSubmit={(event) => this.handleOtpSubmit(event)}
							>
								<div className="form__form-group">
									<span className="form__form-group-label">Validate OTP</span>
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<KeyVariantIcon />
										</div>
										<input
											name="otp"
											type={showPassword ? 'text' : 'password'}
											placeholder="Enter OTP"
											value={otp}
											onChange={this.handleChange}
											required
											onKeyUp={(e) => {
												e.preventDefault();

												if (e.keyCode == 13) {
													this.handleOtpSubmit(event);
												}
											}}
											pattern="\d{5}"
										/>
										<button
											className={`form__form-group-button${showPassword ? ' active' : ''
												}`}
											onClick={(e) => this.showPassword(e)}
											type="button"
										>
											<EyeIcon />
										</button>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									// onClick={this.handleOtpSubmit}
									type="submit"
								>
									Validate OTP
									</button>
							</form>
						</div>
					</div>
				) : null}

			</div>

		);
	}
}

export default withRouter(AmCandidate);
