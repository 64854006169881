import React, { Component } from 'react';
/* global  */
/* eslint-disable  */
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../../Apanel/QueryMe';
import Flagimg from '../../../Apanel/Flagimg';

import Switch from 'react-switch';
import {
	Button,
	Card,
	CardBody,
	Col,
	Table,
	Modal,
	Container,
	Row,
} from 'reactstrap';
import swal from 'sweetalert';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Select from 'react-select';
import EditIcon from 'mdi-react/EditIcon';
import Alert from '../../../../shared/components/Alert';
import ProfileImage from '../../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

var dateFormat = require('dateformat'); // eslint-disable-line

const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'recruiter',
	emails: '',
	domain: '',
	toteam: '',
	token: localStorage.token,
	content_add: '',
	myItems: [],
	testEmails: [],
	currentcolor: [
		'#000000',
		'#000000',
	],
	comprec: [{ name: 'Page A', amt: '0' }],
	addtm: false,
	addtl: false,
	addrec: false,
	addteam: false,
	addtledit: false,
	moverec: false,
	moverecteamid: 0,
	anotherdash: false,
	thisteams: [{
		value: '',
		label: (<div>
			Search/Select Team
		</div>
		),
	}],
	todashteams: [{
		value: '',
		label: (<div>
			Search/Select To Team
		</div>
		),
	}],
	todashboards: [{
		value: '',
		label: (<div>
			Search/Select Dashboard
		</div>
		),
	}],
	assignteamusers: [{
		value: '',
		label: (<div>
			Search/Select Recruiter to Assign Data
		</div>
		),
	}],
	teamname: '',
	teameditoptions: false,
	upnewtl: false,
	usertypes: [],
	thisteamusers: [{
		value: '',
		label: (<div>
			Search/Select User
		</div>
		),
	}],
	newtlemail: '',
	tms: 0,
	tls: 0,
	trecs: 0,
	email: '',
	tmid: 0,
	tlid: 0,
};
class MembersList extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetUsers();

			this.handleChange = this.handleChange.bind(this);

			this.handleClick = this.handleClick.bind(this);
			this.handleManagerSubmit = this.handleManagerSubmit.bind(this);
			this.lastId = -1;
		}
	}
	// handleChange = (event) => {
	// 	const { name, value } = event.target;
	// 	console.log(name + ' ' + value);
	// 	this.setState({ [name]: value });
	// };



	handleChange = (event) => {
		let { name, value } = event.target;

		value = value.toLowerCase();
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};
	handleSingleChange = (event) => {
		let { name, value } = event.target;
		value = value.toLowerCase();
		console.log(name + ' ' + value);
		this.setState({ [name]: value });

	};
	handleAddEmail = async (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];


		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		// console.log(this.state.allones);
		await Promise.all(
			aallemails.map((aallemail) => {
				return new Promise((resolve) => {
					let isvalidrec = re.test(aallemail);
					if (isvalidrec) {
						var newemail_string_array = aallemail.split('@');
						var domain_string_location = newemail_string_array.length - 1;
						var newemaildomain = newemail_string_array[domain_string_location];
						if (mainemaildomain == newemaildomain) {
							var njk = this.state.allones.includes(aallemail);
							var dnjk = this.state.testEmails.includes(aallemail);
							if (njk) {
								resolve('');
							} else {
								if (dnjk) {
									resolve('');
								} else {
									newArray.push({
										content: aallemail,
										id: ++this.lastId,
									});
									testEmails.push(aallemail);
									this.setState(
										{
											myItems: newArray,
											content_add: '',
										},
										() => {
											console.log(this.state.myItems);
											resolve('');
										},
									);
								}
							}
						} else {
							resolve('');
						}
					} else {
						resolve('');
					}
				});
			}),
		);
		this.setState({
			content_add: '',
		});
	};

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	handleAddTeam = () => {
		this.setState({
			addUserModal: true,
			addteam: true,
			addtm: false,
			addtl: false,
			addtledit: false,
			addrec: false,
			teamid: this.state.tmid,
			teamname: '',
			teameditoptions: false,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],

		});
	};



	handleAkey = (teamid, e) => {
		e.preventDefault();
		console.log(teamid);
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: false,
			addtledit: false,
			addrec: true,
			teamid: teamid,
			teamname: '',
			teameditoptions: false,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],
		});
	};
	handleTMkey = () => {
		this.setState({
			addUserModal: true,
			addtm: true,
			addtl: false,
			addtledit: false,
			addrec: false,
			teamid: this.state.tmid,
			teamname: '',
			teameditoptions: false,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],

		});
	};
	handleTMAkey = () => {
		this.setState({
			addUserModal: true,
			addtm: true,
			addtl: false,
			addtledit: false,
			addrec: false,
			teamid: this.state.tmid,
			teamname: '',
			teameditoptions: false,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],
		});
	};
	handleTLAkey = (teamid, e) => {
		e.preventDefault();
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: true,
			addtledit: false,
			addrec: false,
			teamid: teamid,
			teamname: '',
			teameditoptions: false,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],
		});
	};

	handleTLAEditkey = (teamid, teamname, e) => {
		e.preventDefault();
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: false,
			addtledit: true,
			addrec: false,
			teamid: teamid,
			teamname: teamname,
			teameditoptions: true,
			upnewtl: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			showAlert: false,
			moverec: false,
			moverecteamid: 0,
			todash: 0,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],
		}, () => {
			this.state.allusers.map((teamuser) => {
				let userole = '';
				if (teamuser.teamid == this.state.teamid) {

					if (teamuser.blocked == 1 && teamuser.usertype == 5) {

						userole = 'Previous Team Lead';

						this.state.thisteamusers.push({
							value: teamuser.email,
							label: (
								<div>

									{teamuser.email + ' - ' + userole}
								</div>
							),
						});



					} else if (teamuser.blocked == 0 && teamuser.usertype == 1) {

						userole = 'Recruiter';

						this.state.thisteamusers.push({
							value: teamuser.email,
							label: (
								<div>

									{teamuser.email + ' - ' + userole}
								</div>
							),
						});



					}
				}
			})
		});
	};
	handleMovekey = (teamid, teamname, moverecid, e) => {
		e.preventDefault();
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: false,
			addtledit: false,
			addrec: false,
			teamid: teamid,
			teamname: teamname,
			moverec: true,
			moverecid: moverecid,
			todash: 0,
			teameditoptions: false,
			recmoveoptions: true,
			upnewtl: false,
			anotherdash: false,
			thisteams: [{
				value: '',
				label: (<div>
					Search/Select Team
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],
			todashboards: [{
				value: '',
				label: (<div>
					Search/Select Dashboard
				</div>
				),
			}],
			assignteamusers: [{
				value: '',
				label: (<div>
					Search/Select Recruiter to Assign Data
				</div>
				),
			}],
			showAlert: false,
		}, () => {
			this.state.teams.map((teamm) => {
				if (teamm.id != this.state.teamid) {
					this.state.thisteams.push({
						value: teamm.id,
						label: (
							<div>
								{teamm.teamname + ' - Team'}
							</div>
						),
					});
				}
			});


		});
	};

	handleMoveRecUpdate = (event) => {
		event.preventDefault();


		console.log('came');
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		let addkeyurl = apiurl + 'bossmoverectoteam';

		var postData = {



			teamid: this.state.toteam,
			ntrid: this.state.moverecid,
			bossemail: localStorage.by,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('respon', response.data);
				if (response.data.error) {
					swal(response.data.message, {
						icon: 'danger',
					});
				} else {
					this.setState(
						{
							addUserModal: false,
							showAlert: false,
							addteam: false,
							teamname: '',
							teamid: '',
							todash: 0,

						},
						() => {
							swal(response.data.message, {
								icon: 'success',
							});
							this.handleGetUsers();
						},
					);
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};

	handleMoveRecDashUpdate = (event) => {
		event.preventDefault();


		console.log('came');
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		let addkeyurl = apiurl + 'moverectodashteam';

		var postData = {

			dboard: localStorage.dashboard,
			todash: this.state.todash,
			teamid: this.state.toteam,
			ntrid: this.state.moverecid,
			assignntremail: this.state.assignntremail,
			cemail: localStorage.by,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('respon', response.data);
				if (response.data.error) {
					swal(response.data.message, {
						icon: 'danger',
					});
				} else {
					this.setState(
						{
							addUserModal: false,
							showAlert: false,
							addteam: false,
							teamname: '',
							teamid: '',
							todash: 0,
							moverecid: 0,
							assignntremail: '',

						},
						() => {
							swal(response.data.message, {
								icon: 'success',
							});
							this.handleGetUsers();
						},
					);
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});
	};


	handleTeamSubmit = (event) => {
		event.preventDefault();
		console.log('came');
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		let addkeyurl = apiurl + 'addbossnewteam';

		var postData = {
			dboard: localStorage.dashboard,
			teamname: this.state.teamname,
			bossemail: localStorage.by,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('respon', response.data);
				if (response.data.error) {
					swal(this.state.teamname + '  ' + response.data.message, {
						icon: 'success',
					});
				} else {
					this.setState(
						{
							addUserModal: false,
							showAlert: false,
							addteam: false,
							teamname: '',

						},
						() => {
							swal(this.state.teamname + '  ' + response.data.message, {
								icon: 'success',
							});
							this.handleGetUsers();
						},
					);
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// this.props.history.push('/'); //eslint-disable-line
			});





	};


	handleManagerSubmit = (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];
		var manageremail = this.state.email;
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		const isvalidrec = re.test(manageremail);
		if (isvalidrec) {
			var newemail_string_array = manageremail.split('@');
			var domain_string_locationn = newemail_string_array.length - 1;
			var newemaildomain = newemail_string_array[domain_string_locationn];
			if (mainemaildomain == newemaildomain) {
				const njk = this.state.allones.includes(manageremail);
				console.log('njk', njk);
				if (njk) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: "Email Already Exist!",
					});
				} else {
					console.log('came');
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};

					const addkeyurl = apiurl + 'addcomptmm';
					var postData = {
						email: this.state.email,
						cemail: localStorage.by,
						cname: localStorage.name,
						utype: 2,
						dboard: localStorage.dashboard,
					};
					axios
						.post(addkeyurl, postData, {
							headers: headers,
						})
						.then((response) => {
							console.log('respon', response.data);
							if (!response.data.error) {
								this.setState(
									{
										addUserModal: false,
										showAlert: false,
										email: '',
									},
									() => {
										swal(manageremail + ' Manager ' + response.data.message, {
											icon: 'success',
										});
										this.handleGetUsers();
									},
								);
							}
						})
						.catch(() => {
							// //localStorage.clear();
							// this.props.history.push('/'); //eslint-disable-line
						});

				}
			} else {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: "Email Should be of your Company Domain!",
				});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Email Invalid!",
			});
		}


	};



	handleTeamLeadSubmit = (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];

		var teamleademail = this.state.email;


		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line


		const isvalidrec = re.test(teamleademail);
		if (isvalidrec) {
			var newemail_string_array = teamleademail.split('@');
			var domain_string_locationn = newemail_string_array.length - 1;
			var newemaildomain = newemail_string_array[domain_string_locationn];
			if (mainemaildomain == newemaildomain) {
				const njk = this.state.allones.includes(teamleademail);
				console.log('njk', njk);
				if (njk) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: "Email Already Exist!",
					});
				} else {
					console.log('came');
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};

					const addkeyurl = apiurl + 'addbosstml';
					var postData = {
						email: this.state.email,
						bossemail: localStorage.by,
						cname: localStorage.name,
						dboard: localStorage.dashboard,
						teamid: this.state.teamid,
					};
					axios
						.post(addkeyurl, postData, {
							headers: headers,
						})
						.then((response) => {
							console.log('respon', response.data);
							if (!response.data.error) {
								this.setState(
									{
										addUserModal: false,
										showAlert: false,
										email: '',
									},
									() => {
										swal(teamleademail + ' Team Lead ' + response.data.message, {
											icon: 'success',
										});
										this.handleGetUsers();
									},
								);
							}
						})
						.catch(() => {
							// //localStorage.clear();
							// this.props.history.push('/'); //eslint-disable-line
						});

				}
			} else {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: "Email Should be of your Company Domain!",
				});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Email Invalid!",
			});
		}


	};
	handleTeamLeadUpdate = (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];

		var teamleademail = this.state.newtlemail;


		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line


		const isvalidrec = re.test(teamleademail);
		if (isvalidrec) {
			var newemail_string_array = teamleademail.split('@');
			var domain_string_locationn = newemail_string_array.length - 1;
			var newemaildomain = newemail_string_array[domain_string_locationn];
			if (mainemaildomain == newemaildomain) {


				console.log('came');
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addkeyurl = apiurl + 'bossupdatecomptml';
				var postData = {
					email: teamleademail,
					bossemail: localStorage.by,
					cname: localStorage.name,
					teamid: this.state.teamid,
				};
				axios
					.post(addkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log('respon', response.data);
						if (!response.data.error) {
							this.setState(
								{
									addUserModal: false,
									showAlert: false,
									email: '',
								},
								() => {
									swal(teamleademail + ' Team Lead ' + response.data.message, {
										icon: 'success',
									});
									this.handleGetUsers();
								},
							);
						}
					})
					.catch(() => {
						// //localStorage.clear();
						// this.props.history.push('/'); //eslint-disable-line
					});


			} else {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: "Email Should be of your Company Domain!",
				});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Email Invalid!",
			});
		}


	};
	handleUserSubmit = (event) => {
		event.preventDefault();

		var jjjjjjjj = this.state.testEmails;
		if (jjjjjjjj.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'addbosstmrecs';
			var postData = {
				emails: this.state.testEmails,
				bossemail: localStorage.by,
				cname: localStorage.name,
				dboard: localStorage.dashboard,
				teamid: this.state.teamid,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						var okemailss = response.data.data[0];
						okemailss.toString();
						this.setState(
							{
								addUserModal: false,
								showAlert: false,
								emails: '',
								myItems: [],
								testEmails: [],
							},
							() => {
								swal(okemailss + ' ' + response.data.message, {
									icon: 'success',
								});
								this.handleGetUsers();
							},
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
			anotherdash: false,
			addbdm: false,
			thisteamusers: [{
				value: '',
				label: (<div>
					Search/Select User
				</div>
				),
			}],
			todashteams: [{
				value: '',
				label: (<div>
					Search/Select To Team
				</div>
				),
			}],

		});
	};
	handleTkey = (id, keyword, checked) => {
		// console.log(checked + 'checked');
		// console.log(id + 'id');
		// console.log(keyword + 'keyword');
		var keysts = checked == true ? 0 : 1;
		var kstatus = 'Block';
		if (checked) {
			kstatus = 'Unblock';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'toguser';
				var postData = {
					userid: id,
					ustatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetUsers();
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleGetUsers = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			compid: localStorage.usercode,
			bossemail: localStorage.by,
			dashboard: localStorage.dashboard,
		};
		const getuurl = apiurl + 'bossdashtexperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('response.data', response.data);
					let hk = [localStorage.by];
					let allusersd = [...response.data.allusersd];
					let allusers = [...response.data.allusers];
					let tms = 0;
					let tls = 0;
					let rec = 0;
					let tmid = 0;
					allusersd.map((rk) => {

						if (rk.usertype == 2) {
							tms = tms + 1;
							tmid = rk.id;
						}
						if (rk.usertype == 3) {
							tls = tls + 1;
						}
						if (rk.usertype == 1) {
							rec = rec + 1;
						}
					});
					allusers.map((rkg) => {
						hk.push(rkg.email);

					});
					this.setState({
						allusers: [...response.data.allusersd],
						usertypes: response.data.usertypes,
						teams: response.data.teams,
						allones: hk,
						tms: tms,
						tls: tls,
						trecs: rec,
						tmid: tmid,
						email: '',
						emails: '',
						myItems: [],
						testEmails: [],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	getDetails = (utypeid) => {
		var utype = '';
		let usertype = this.state.usertypes.filter((tt) => {
			return tt.id == utypeid;
		});
		if (usertype.length) {
			utype = usertype[0].usertype;
		}
		return utype;
	};
	getrecsDetails = (tlid) => {
		var allrecs = [];
		let recs = this.state.allusers.filter((tt) => {
			if (tt.teamid == tlid && tt.usertype == 1) {
				return tt;

			}
		});
		if (recs.length) {
			allrecs = recs;
		}
		return allrecs;
	};
	getteamusersDetails = (teamid) => {
		var allteamusers = [];
		let tusers = this.state.allusers.filter((tt) => {
			if (tt.teamid == teamid && tt.usertype == 3) {
				return tt;

			}
		});
		if (tusers.length) {
			allteamusers = tusers;
		}
		return allteamusers;
	};

	handleGetTeams = (todash) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			compid: localStorage.usercode,
			dashboard: todash,
		};
		const getuurl = apiurl + 'dashboardteams';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('response.data', response.data);
					var atodashteams = response.data.teams;
					this.setState({
						todashteams: [{
							value: '',
							label: (<div>
								Search/Select To Team
							</div>
							),
						}],
						assignteamusers: [{
							value: '',
							label: (<div>
								Search/Select Recruiter to Assign Data
							</div>
							),
						}],
						anotherdash: true
					}, () => {
						atodashteams.map((teamm) => {
							if (teamm.id != this.state.teamid) {
								this.state.todashteams.push({
									value: teamm.id,
									label: (
										<div>
											{teamm.teamname + ' - Team'}
										</div>
									),
								});
							}
						});
						this.state.allusers.map((teamuser) => {
							let userole = 'Recruiter';
							if (teamuser.blocked == 0 && teamuser.usertype == 1) {
								if (teamuser.teamid == this.state.teamid) {

									userole = 'Recruiter Same Team';
								}

								this.state.assignteamusers.push({
									value: teamuser.email,
									label: (
										<div>

											{teamuser.email + ' - ' + userole}
										</div>
									),
								});



							}

						})
					})


				}
			})
			.catch(() => {
				// //localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};


	handleSelectChange = (selectedOption) => {
		let newtlemail = selectedOption.value;
		this.setState({ newtlemail: newtlemail });
	};
	handleSelectTeamChange = (selectedOption) => {
		let toteam = selectedOption.value;
		this.setState({ toteam: toteam });
	};
	handleSelectAsignTeamChange = (selectedOption) => {
		let assignntremail = selectedOption.value;
		this.setState({ assignntremail: assignntremail });
	};
	handleSelectDashChange = (selectedOption) => {
		let todash = selectedOption.value;
		this.setState({ todash: todash }, () => {
			this.handleGetTeams(todash);
		});
	};

	render() {
		const {
			email,
			teamname,
			newtlemail,
			toteam,
			assignntremail
		} = this.state;

		return (
			<Container className="dashboard">

				<Row>
					<Col md={3}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										<Flagimg flagname={localStorage.flgccode} clname="flagimg" />{localStorage.dashname}
										<div>
											{this.state.tmid ? (
												<div >


													{this.state.allusers &&
														this.state.allusers.map((huser, index) => {
															// var usertype = this.getDetails(huser.usertype);
															if (huser.usertype == 2) {
																// var usts = true;
																// if (huser.blocked === 1) {
																// 	usts = false;
																// }
																return (
																	<Row key={index}>
																		<Col md={4}>
																			<img
																				src={huser.image_url == 0 ? this.state.image : huser.image_url}
																				clname="topbar__avatar-img"
																				style={{ marginTop: '10px' }}

																			/>

																		</Col>
																		<Col md={8}>
																			<p>{huser.name}</p>
																			<p>{huser.email}</p>


																			<p>{huser.mobile}</p>
																			<p>Created on: {dateFormat(huser.createdon, 'd-m-yyyy')}</p>
																		</Col>
																	</Row>

																);
															}
														})}

													<button
														className="btn btn-primary account__btn account__btn--small dboardbtns"
														onClick={this.handleAddTeam}
													>
														Add Team
													</button>

													<Link className="btn btn-primary account__btn account__btn--small dboardbtns" to="/mngcdashboard">

														Users & Roles
													</Link>

												</div>
											) : null}
										</div>




									</h5>
								</div>

							</CardBody>
						</Card>
					</Col>
					<Col md={9}>
						{this.state.teams &&
							this.state.teams.map((team, index) => {
								var allteamusers = this.getteamusersDetails(team.id);
								// console.log(allteamusers);
								var allrecs = this.getrecsDetails(team.id);
								return (
									<Col md={12} key={index}>
										<Card>
											<CardBody>
												<div className="card__title">
													<h5 className="bold-text">
														{team.teamname} {' '}{' '}

													</h5>
													{allteamusers && allteamusers.length >= 1 ? (
														<h5 className="teamleader" >
															{allteamusers && allteamusers.map((huser, index) => {
																// var usertype = this.getDetails(huser.usertype);
																if (huser.usertype == 3 && huser.teamid == team.id) {

																	// console.log('allrecs', allrecs);
																	var usts = true;
																	if (huser.blocked === 1) {
																		usts = false;
																	}

																	return (
																		<Row key={index}>
																			<Col md={1}><ProfileImage
																				clname="topbar__avatar-img"
																				flpath={
																					huser.image_url
																						? huser.image_url
																						: this.state.image
																				}
																			/>

																			</Col>
																			<Col md={1}>
																				<Switch
																					onChange={this.handleTkey.bind(
																						this,
																						huser.id,
																						huser.email,
																					)}
																					checked={usts}
																				/>
																			</Col>
																			<Col md={4}>
																				<p className="teamleaderp">Name: {huser.name}</p>
																				<p className="teamleaderp">Email: {huser.email}</p>
																			</Col>
																			<Col md={3}>
																				<p className="teamleaderp">Mobile: {huser.mobile}</p>
																				<p className="teamleaderp">Created on: {dateFormat(huser.createdon, 'd-m-yyyy')}</p>
																			</Col>
																			<Col md={3}>
																				<span style={{ color: '#fff', cursor: 'pointer', float: 'right' }}
																					onClick={this.handleTLAEditkey.bind(
																						this,
																						team.id,
																						team.teamname
																					)}
																				>
																					<EditIcon /> Edit
																				</span>
																			</Col>
																		</Row>


																	);
																}
															})}
														</h5>
													) : (
														<h5 className="teamleader" >
															<Row>
																<Col md={12}>
																	<span style={{ color: '#fff', cursor: 'pointer', float: 'right' }}
																		onClick={this.handleTLAkey.bind(
																			this,
																			team.id,
																		)}
																	>
																		+ Team Lead
																	</span>
																</Col>
															</Row>
														</h5>
													)}
													<h5 style={{ margin: '20px', float: 'right' }}>
														<Button
															className="btn-sm"
															color="primary"
															onClick={this.handleAkey.bind(this, team.id)}
														>
															+ Recruiters
														</Button>
													</h5>

												</div>

												{allrecs && allrecs.length >= 1 ? (


													<Table responsive hover>
														<thead>
															<tr>
																<th>#</th>
																<th>.</th>
																<th>Manage</th>
																<th>Email</th>
																{/* <th>Type</th> */}
																<th>Name</th>
																<th>Mobile</th>
																<th>

																	Created On
																</th>
																<th>Actions</th>

															</tr>
														</thead>
														<tbody>
															{allrecs && allrecs.map((huser, index) => {
																// var usertype = this.getDetails(huser.usertype);
																let fgh = 1;
																if (huser.usertype == 1 && huser.teamid == team.id) {

																	// console.log('allrecs', allrecs);
																	var usts = true;
																	if (huser.blocked === 1) {
																		usts = false;
																	}

																	return (
																		<tr key={index}>
																			<td>{fgh + 1}</td>
																			<td>
																				<ProfileImage
																					clname="topbar__avatar-img"
																					flpath={
																						huser.image_url
																							? huser.image_url
																							: this.state.image
																					}
																				/>
																			</td>
																			<td>
																				<Switch
																					onChange={this.handleTkey.bind(
																						this,
																						huser.id,
																						huser.email,
																					)}
																					checked={usts}
																				/>
																			</td>
																			<td>{huser.email}</td>
																			{/* <td>
																	<h4>
																		<Badge color="success">{huser.profile_completed ? 'Completed' : 'Not Completed'}</Badge>
																	</h4>
																</td> */}
																			<td>{huser.name}</td>
																			<td>{huser.mobile}</td>
																			<td>{dateFormat(huser.createdon, 'd-m-yyyy')}</td>
																			<td>
																				<Button
																					className="btn-sm"
																					color="primary"
																					onClick={this.handleMovekey.bind(this, team.id, team.teamname, huser.id)}
																				>
																					Move Recruiter
																				</Button>
																			</td>



																		</tr>
																	);
																}
															})}
														</tbody>
													</Table>


												) : null}
											</CardBody>
										</Card>


									</Col>

								);
							})}

					</Col>
				</Row>



				<Modal
					isOpen={this.state.addUserModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary addemailsbox"
				>


					{this.state.tmid && this.state.addtl ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">Add Team Lead</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleTeamLeadSubmit(event)}
							>
								<div className="form__form-group">
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="email"
											type="email"
											placeholder="Enter email"
											className="form-control"
											value={email}
											onChange={this.handleSingleChange}
											required
										/>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Add Team Lead
								</button>
							</form>
						</div>
					</div>) : null}

					{this.state.tmid && this.state.addtledit ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">Edit Team Lead of {this.state.teamname}</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							{this.state.addtledit && this.state.teameditoptions ? (
								<Row>
									<Col md={6}>
										<button className="btn btn-block btn-success"
											onClick={this.handleTLAkey.bind(
												this,
												this.state.teamid,
											)}
										>
											+ New Team Lead And Replace Existing Team Lead
										</button>
									</Col>
									<Col md={6}>
										<button className="btn btn-block btn-primary"
											onClick={() => {
												this.setState({
													upnewtl: true,
													addnewtl: false,
													teameditoptions: false,
												});
											}}
										>
											+ Team Lead from Team Users
										</button>
									</Col>
								</Row>
							) : null}
							{this.state.addnewtl && this.state.addtledit ? (
								<form
									className="form"
									onSubmit={(event) => this.handleTeamLeadSubmit(event)}
								>
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="email"
												type="email"
												placeholder="Enter email"
												className="form-control"
												value={email}
												onChange={this.handleSingleChange}
												required
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add Team Lead
									</button>
								</form>
							) : null}
							{this.state.upnewtl && this.state.addtledit ? (
								<div>


									<form
										className="form"
										onSubmit={(event) => this.handleTeamLeadUpdate(event)}
									>
										<div className="form__form-group">
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<AccountOutlineIcon />
												</div>

												<Select
													name="newtlemail"
													value={this.state.thisteamusers.filter(
														(option) => option.value == newtlemail,
													)}
													onChange={this.handleSelectChange}
													options={this.state.thisteamusers}
													clearable={false}
													placeholder="Team Staff"
													className="react-select-team"
													classNamePrefix="react-select"
												// defaultMenuIsOpen={true}
												// isDisabled={true}
												/>
											</div>
										</div>
										{this.state.newtlemail ? (
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Replace  Team Lead
											</button>
										) : (
											<button
												className="btn btn-secondary account__btn account__btn--small"
												type="submit"
												desabled="true"
											>
												Replace  Team Lead
											</button>
										)}

									</form>
								</div>
							) : null}
						</div>
					</div>) : null}

					{this.state.moverecid && this.state.moverec ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">Move Recruiter from {this.state.teamname}  </h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}


							{this.state.moverec ? (
								<div>


									<form
										className="form"
										onSubmit={(event) => this.handleMoveRecUpdate(event)}
									>
										<div className="form__form-group">
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<AccountOutlineIcon />
												</div>

												<Select
													name="toteam"
													value={this.state.thisteams.filter(
														(option) => option.value == toteam,
													)}
													onChange={this.handleSelectTeamChange}
													options={this.state.thisteams}
													clearable={false}
													placeholder="Teams"
													className="react-select-team"
													classNamePrefix="react-select"
												// defaultMenuIsOpen={true}
												// isDisabled={true}
												/>
											</div>
										</div>
										{this.state.toteam ? (
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Move Recruiter
											</button>
										) : (
											<button
												className="btn btn-secondary account__btn account__btn--small"
												type="submit"
												desabled="true"
											>
												Move Recruiter
											</button>
										)}

									</form>
								</div>
							) : null}
							{this.state.anotherdash && this.state.moverec ? (
								<div>


									<form
										className="form"
										onSubmit={(event) => this.handleMoveRecDashUpdate(event)}
									>
										<Col md={12}>
											<Row>
												<Col md={6}>
													<div className="form__form-group">
														<div className="form__form-group-field">
															<div className="form__form-group-icon">
																<AccountOutlineIcon />
															</div>

															<Select
																name="assignntremail"
																value={this.state.assignteamusers.filter(
																	(option) => option.value == assignntremail,
																)}
																onChange={this.handleSelectAsignTeamChange}
																options={this.state.assignteamusers}
																clearable={false}
																placeholder="Assign Data"
																className="react-select-team"
																classNamePrefix="react-select"
															// defaultMenuIsOpen={true}
															// isDisabled={true}
															/>
														</div>
													</div>
												</Col>
												<Col md={6}>
													<div className="form__form-group">
														<div className="form__form-group-field">
															<div className="form__form-group-icon">
																<AccountOutlineIcon />
															</div>

															<Select
																name="toteam"
																value={this.state.todashteams.filter(
																	(option) => option.value == toteam,
																)}
																onChange={this.handleSelectTeamChange}
																options={this.state.todashteams}
																clearable={false}
																placeholder="To Teams"
																className="react-select-team"
																classNamePrefix="react-select"
															// defaultMenuIsOpen={true}
															// isDisabled={true}
															/>
														</div>
													</div>
												</Col>
											</Row>
										</Col>
										{this.state.toteam && this.state.assignntremail ? (
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Move Recruiter Another Dashboard
											</button>
										) : (
											<button
												className="btn btn-secondary account__btn account__btn--small"
												type="submit"
												desabled="true"
											>
												Move Recruiter Another Dashboard
											</button>
										)}

									</form>
								</div>
							) : null}
						</div>
					</div>) : null}

					{this.state.tmid && this.state.teamid && this.state.addrec ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Recruiter
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleAddEmail(event)}
							>
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<p>{this.makeAddedList()}</p>
								<div className="form__form-group">
									{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
									<div className="form__form-group-field">
										<textarea
											name="email"
											type="email"
											placeholder="Add Email ID"
											// onFocus={this.handleFocus}
											onChange={this.handleChange}
											value={this.state.content_add}
											style={{ width: '75%', margin: '15px' }}
											onKeyUp={(e) => {
												e.preventDefault();

												// if (
												// 	e.keyCode == 13 ||
												// 	e.keyCode == 32 ||
												// 	e.keyCode == 188
												// ) {
												// 	this.handleAddEmail(event);
												// }
											}}
										/>
										<button
											className="btn btn-primary btn-sm"
											style={{ marginTop: '15px' }}
											type="submit"
										>
											Add Recruiter Email
										</button>
									</div>
								</div>
							</form>
							<form
								className="form"
								onSubmit={(event) => this.handleUserSubmit(event)}
							>
								<Col md={12}>
									{this.state.testEmails.length > 0 ? (
										<button
											className="btn btn-primary float-right"
											type="submit"
											style={{ width: '40%' }}
										>
											Add Recruiter
										</button>
									) : (
										<button
											className="btn btn-secondary float-right"
											type="button"
											desabled="true"
											style={{ width: '40%' }}
										>
											Add Recruiter
										</button>
									)}
								</Col>
							</form>
						</div>
					</div>) : null}


					{this.state.addteam ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Team</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleTeamSubmit(event)}
							>
								<div className="form__form-group">
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="teamname"
											type="text"
											placeholder="Enter Team Name"
											className="form-control"
											value={teamname}
											onChange={this.handleSingleChange}
											required
										/>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Add Team
								</button>
							</form>
						</div>
					</div>) : null}
				</Modal>

			</Container>
		);
	}
}

export default MembersList;
