import React, { Component } from 'react';
/* global  */
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../../Apanel/QueryMe';
// import Flagimg from '../../../Apanel/Flagimg';

import Switch from 'react-switch';
import {
	Card,
	CardBody,
	Col,
	Table,
	Container,
	Row,
} from 'reactstrap';
import swal from 'sweetalert';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import Select from 'react-select';
// import EditIcon from 'mdi-react/EditIcon';
// import Alert from '../../../../shared/components/Alert';
import ProfileImage from '../../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

var dateFormat = require('dateformat'); // eslint-disable-line

const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'recruiter',
	emails: '',
	domain: '',
	toteam: '',
	token: localStorage.token,
	content_add: '',
	myItems: [],
	testEmails: [],
	currentcolor: [
		'#000000',
		'#000000',
	],
	comprec: [{ name: 'Page A', amt: '0' }],
	addtm: false,
	addtl: false,
	addrec: false,
	addteam: false,
	addtledit: false,
	moverec: false,
	moverecteamid: 0,
	anotherdash: false,
	thisteams: [{
		value: '',
		label: (<div>
			Search/Select Team
		</div>
		),
	}],
	todashteams: [{
		value: '',
		label: (<div>
			Search/Select To Team
		</div>
		),
	}],
	todashboards: [{
		value: '',
		label: (<div>
			Search/Select Dashboard
		</div>
		),
	}],
	assignteamusers: [{
		value: '',
		label: (<div>
			Search/Select Recruiter to Assign Data
		</div>
		),
	}],
	teamname: '',
	teameditoptions: false,
	upnewtl: false,
	usertypes: [],
	thisteamusers: [{
		value: '',
		label: (<div>
			Search/Select User
		</div>
		),
	}],
	newtlemail: '',
	tms: 0,
	tls: 0,
	trecs: 0,
	email: '',
	tmid: 0,
	tlid: 0,
};
class TLMembersList extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetUsers();
			this.lastId = -1;
		}
	}

	handleTkey = (id, keyword, checked) => {
		// console.log(checked + 'checked');
		// console.log(id + 'id');
		// console.log(keyword + 'keyword');
		var keysts = checked == true ? 0 : 1;
		var kstatus = 'Block';
		if (checked) {
			kstatus = 'Unblock';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'toguser';
				var postData = {
					userid: id,
					ustatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetUsers();
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleGetUsers = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			compid: localStorage.usercode,
			bossemail: localStorage.by,
			dashboard: localStorage.dashboard,
		};
		const getuurl = apiurl + 'bossdashtexperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('response.data', response.data);
					let hk = [localStorage.by];
					let allusersd = [...response.data.allusersd];
					let allusers = [...response.data.allusers];
					let tms = 0;
					let tls = 0;
					let rec = 0;
					let tmid = 0;
					allusersd.map((rk) => {

						if (rk.usertype == 2) {
							tms = tms + 1;
							tmid = rk.id;
						}
						if (rk.usertype == 3) {
							tls = tls + 1;
						}
						if (rk.usertype == 1) {
							rec = rec + 1;
						}
					});
					allusers.map((rkg) => {
						hk.push(rkg.email);

					});
					this.setState({
						allusers: [...response.data.allusersd],
						usertypes: response.data.usertypes,
						teams: response.data.teams,
						allones: hk,
						tms: tms,
						tls: tls,
						trecs: rec,
						tmid: tmid,
						email: '',
						emails: '',
						myItems: [],
						testEmails: [],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};


	getrecsDetails = (tlid) => {
		var allrecs = [];
		let recs = this.state.allusers.filter((tt) => {
			if (tt.teamid == tlid && tt.usertype == 1) {
				return tt;

			}
		});
		if (recs.length) {
			allrecs = recs;
		}
		return allrecs;
	};
	getteamusersDetails = (teamid) => {
		var allteamusers = [];
		let tusers = this.state.allusers.filter((tt) => {
			if (tt.teamid == teamid && tt.usertype == 3) {
				return tt;

			}
		});
		if (tusers.length) {
			allteamusers = tusers;
		}
		return allteamusers;
	};





	render() {


		return (
			<Container className="dashboard">

				<Row>

					<Col md={12} >
						{this.state.teams &&
							this.state.teams.map((team, index) => {
								var allteamusers = this.getteamusersDetails(team.id);
								// console.log(allteamusers);
								var allrecs = this.getrecsDetails(team.id);
								return (
									<Col md={12} key={index}>
										<Card>
											<CardBody>
												<div className="card__title">
													<h5 className="bold-text">
														{team.teamname} {' '}{' '}

													</h5>
													{allteamusers && allteamusers.length >= 1 ? (
														<h5 className="teamleader" >
															{allteamusers && allteamusers.map((huser, index) => {
																// var usertype = this.getDetails(huser.usertype);
																if (huser.usertype == 3 && huser.teamid == team.id) {

																	// console.log('allrecs', allrecs);


																	return (
																		<Row key={index}>
																			<Col md={1}><ProfileImage
																				clname="topbar__avatar-img"
																				flpath={
																					huser.image_url
																						? huser.image_url
																						: this.state.image
																				}
																			/>

																			</Col>

																			<Col md={6}>
																				<p className="teamleaderp">Name: {huser.name}</p>
																				<p className="teamleaderp">Email: {huser.email}</p>
																			</Col>
																			<Col md={3}>
																				<p className="teamleaderp">Mobile: {huser.mobile}</p>
																				<p className="teamleaderp">Created on: {dateFormat(huser.createdon, 'd-m-yyyy')}</p>
																			</Col>

																		</Row>


																	);
																}
															})}
														</h5>
													) : null}


												</div>

												{allrecs && allrecs.length >= 1 ? (


													<Table responsive hover>
														<thead>
															<tr>
																<th>#</th>
																<th>.</th>
																<th>Manage</th>
																<th>Email</th>
																{/* <th>Type</th> */}
																<th>Name</th>
																<th>Mobile</th>
																<th>

																	Created On
																	</th>


															</tr>
														</thead>
														<tbody>
															{allrecs && allrecs.map((huser, index) => {
																// var usertype = this.getDetails(huser.usertype);
																let fgh = 1;
																if (huser.usertype == 1 && huser.teamid == team.id) {

																	// console.log('allrecs', allrecs);
																	var usts = true;
																	if (huser.blocked === 1) {
																		usts = false;
																	}

																	return (
																		<tr key={index}>
																			<td>{fgh + 1}</td>
																			<td>
																				<ProfileImage
																					clname="topbar__avatar-img"
																					flpath={
																						huser.image_url
																							? huser.image_url
																							: this.state.image
																					}
																				/>
																			</td>
																			<td>
																				<Switch
																					onChange={this.handleTkey.bind(
																						this,
																						huser.id,
																						huser.email,
																					)}
																					checked={usts}
																				/>
																			</td>
																			<td>{huser.email}</td>
																			{/* <td>
																	<h4>
																		<Badge color="success">{huser.profile_completed ? 'Completed' : 'Not Completed'}</Badge>
																	</h4>
																</td> */}
																			<td>{huser.name}</td>
																			<td>{huser.mobile}</td>
																			<td>{dateFormat(huser.createdon, 'd-m-yyyy')}</td>




																		</tr>
																	);
																}
															})}
														</tbody>
													</Table>


												) : null}
											</CardBody>
										</Card>


									</Col>

								);
							})}

					</Col>
				</Row>





			</Container>
		);
	}
}

export default TLMembersList;
