import React, { PureComponent } from 'react';


import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';



const initialstate = {

	collapse: false,
};
export default class TopbarProfile extends PureComponent {
	constructor () {
		super();
		this.state = {
			collapse: false,
		};
	}
	state = { ...initialstate };
	toggle = () => {
		this.setState((prevState) => ({ collapse: !prevState.collapse }));
	};

	render() {
		const { collapse } = this.state;

		return (
			<div className="topbar__profile">
				<button type="button" className="topbar__avatar" onClick={this.toggle}>
					<img
						className="topbar__avatar-img"
						src={localStorage.profile_img}
						alt="avatar"
					/>
					<p className="topbar__avatar-name">{localStorage.name}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && (
					<button
						className="topbar__back"
						type="button"
						onClick={this.toggle}
					/>
				)}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						{/* <TopbarMenuLink
							title="My Profile"
							icon="user"
							path="/profile"
							onClick={this.toggle}
						/> */}
						<TopbarMenuLink
							title="Change Password"
							icon="warning"
							path="/chgpwd"
							onClick={this.toggle}
						/>
						{/* <TopbarMenuLink
							title="Tasks"
							icon="list"
							path="/todo"
							onClick={this.toggle}
						/> */}
						{/* <TopbarMenuLink
							title="Inbox"
							icon="inbox"
							path="/mail"
							onClick={this.toggle}
						/> */}
						<div className="topbar__menu-divider" />
						{/* <TopbarMenuLink
							title="Account Settings"
							icon="cog"
							path="/asettings"
							onClick={this.toggle}
						/> */}
						{/* <TopbarMenuLink
							title="Lock Screen"
							icon="lock"
							path="/lock_screen"
							onClick={this.toggle}
						/> */}
						<TopbarMenuLink
							title="Log Out"
							icon="exit"
							path="/logout"
							onClick={this.toggle}
						/>
					</div>
				</Collapse>
			</div>
		);
	}
}
