import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

const apiurl = MY_API_URL;
import { Card, CardBody, Col, Row, Badge } from 'reactstrap';
const initialstate = {
	tstats: [],
	token: localStorage.token,
};
class Dashboard extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.getRTests();
		}
	}
	getRTests = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'getRTests';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						tstats: [...response.data.data],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	render() {
		return (
			<Row style={{ margin: '0px' }}>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Saved Tests</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="primary">
									{this.state.tstats[0]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Sent Tests</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="warning">
									{this.state.tstats[1]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Test Completed</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="success">
									{this.state.tstats[2]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default Dashboard;
