import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom'; //eslint-disable-line
import { Card, CardBody, Col, Row, Modal } from 'reactstrap';
import { MY_API_URL, LOGO_SVG_URL } from '../Apanel/QueryMe';
import Alert from '../../shared/components/Alert';
import axios from 'axios';
/* global  */

// const queryString = require('query-string');
import Topbar from '../Apanel/topbar/IndexTopbar';

const apiurl = MY_API_URL;
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
	showBvbox: false,
	whichone: '',
	ttitle: '',
	showAlert: false,
	alertType: '',
	alertText: '',
	email: '',
	name: '',
	phone: '',
	company_name: '',
	company_address: '',
	company_website: '',
	designation: '',
	linkedin: '',

};

class VendorFormPanel extends Component {
	constructor(props) {
		super(props);
		this.submitCandy = this.submitCandy.bind(this);

	}
	state = { ...initialstate };

	componentDidMount() {
		// const value = queryString.parse(this.props.location.search);
		// const jtoken = value.token;
		// this.handleGetUser(jtoken);
		this.handlelogo();


	}

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};


	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleCkey = () => {
		this.setState({
			showBvbox: false,
			showResultbox: false,
			whichone: '',
			ttitle: '',
			showAlert: false,
			alertType: '',
			alertText: '',
			bvid: '',
			rvid: '',
			email: '',
			name: '',
			phone: '',
			company_name: '',
			company_address: '',
			company_website: '',
			designation: '',
			linkedin: '',
		});
	};

	submitCandy = (whichone, ttitle) => {
		// alert(whichone);
		this.setState({
			showBvbox: true,
			showResultbox: false,
			whichone: whichone,
			ttitle: ttitle,
			showAlert: false,
			alertType: '',
			alertText: '',
			bvid: '',
			rvid: '',
			email: '',
			name: '',
			phone: '',
			company_name: '',
			company_address: '',
			company_website: '',
			designation: '',
			linkedin: '',
		});
	};
	handleBVendorSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			const { text, match: { params } } = this.props; //eslint-disable-line
			const { usercode, compname, recname } = params; //eslint-disable-line
			var postData = {
				email: this.state.email,
				redcode: usercode,
				name: this.state.name,
				phone: this.state.phone,
				company_name: this.state.company_name,
				company_address: this.state.company_address,
				company_website: this.state.company_website,
				designation: this.state.designation,
				linkedin: this.state.linkedin,
				whichone: this.state.whichone,
			};
			const loginurl = apiurl + 'submitvendor';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {


						this.setState({
							showAlert: false,
							showBvbox: false,
							showResultbox: true,
							results: response.data.result,
							whichone: 'one',
							email: '',
							name: '',
							phone: '',
							company_name: '',
							company_address: '',
							company_website: '',
							designation: '',
							linkedin: '',
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handlelogo = () => {


		const headers = {
			'Content-Type': 'application/json',
		};

		const { text, match: { params } } = this.props; //eslint-disable-line
		const { usercode, compname, recname } = params; //eslint-disable-line
		var postData = {
			redcode: usercode,
		};
		const loginurl = apiurl + 'getrecdetails';
		axios
			.post(loginurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState({
						showAlert: false,
						complogo: response.data.complogo,
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});

	};


	render() {
		const { text, match: { params } } = this.props; //eslint-disable-line
		const { usercode, compname, recname } = params; //eslint-disable-line
		const { email, name, phone, company_name, company_address, company_website, designation, linkedin, ttitle, results } = this.state;
		const pageurl = "https://ScreenHRai.com/user/" + usercode + "/" + compname + "/" + recname;
		var titlee = "Join My Vendor List | " + compname.replace(/-/gi, ' ') + " | " + recname.replace(/-/gi, ' ');
		return (
			<div>
				<MetaTags>
					<title id="vfp0">{titlee}</title>
					<meta id="vfp1" property="og:title" content={titlee} />
					<meta id="vfp2" property="og:type" content="website" />
					<meta id="vfp3" property="og:url" content={pageurl} />
					<meta id="vfp4" property="og:image:type" content="image/svg+xml" />
					<meta id="vfp5" property="og:image:width" content="1024" />
					<meta id="vfp6" property="og:image:height" content="1024" />
					<meta id="vfp7" property="og:image" content={LOGO_SVG_URL} />
					<meta id="vfp8" name="description" content="Connecting dots in staffing" />
				</MetaTags>
				<div className="layout layout--top-navigation">
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
				</div>
				<div
					className="container__wrap"
					style={{ paddingTop: '62px', width: '100%', background: '#fff' }}
				>


					<Card>
						<CardBody className="logincardbody">
							<div className="card__title">

								<h4 className="bold-text" style={{ textAlign: 'center' }}>
									<img src={this.state.complogo} style={{ width: '150px' }} /><br />
									{compname}<br />{recname}<br />
									Join My Vendor List
								</h4>

							</div>
							<Row className="loginrow">
								<Col md={4} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											<span style={{ textTransform: 'capitalize' }}>
												I Have Bench Candidates
											</span><br />

											<span className="applybtntexpert"
												onClick={this.submitCandy.bind(this, 'one', 'I Have Bench Candidates')} >Submit Details</span>
										</h2>
									</div>

								</Col>
								<Col md={4} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											<span style={{ textTransform: 'capitalize' }}>
												I Have Requirements
											</span><br />

											<span className="applybtntexpert"
												onClick={this.submitCandy.bind(this, 'two', 'I Have Requirements')} >Submit Details</span>
										</h2>
									</div>

								</Col>

								<Col md={4} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											<span style={{ textTransform: 'capitalize' }}>
												I Have Both
											</span><br />

											<span className="applybtntexpert"
												onClick={this.submitCandy.bind(this, 'three', 'I Have Bench Candidates and Requirements')} >Submit Details</span>
										</h2>
									</div>

								</Col>

							</Row>
						</CardBody>
					</Card>

				</div>
				<Modal
					isOpen={this.state.showBvbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>{ttitle}</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleBVendorSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Enter Email"
												value={email}
												className="form-control"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Name</span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Name"
												value={name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone</span>
										<div className="form__form-group-field">
											<input
												name="phone"
												type="text"
												placeholder="Phone"
												value={phone}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">
											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												value={linkedin}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>

								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Designation</span>
										<div className="form__form-group-field">
											<input
												name="designation"
												type="text"
												placeholder="Designation"
												value={designation}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Name</span>
										<div className="form__form-group-field">
											<input
												name="company_name"
												type="text"
												placeholder="Company Name"
												value={company_name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Address</span>
										<div className="form__form-group-field">
											<input
												name="company_address"
												type="text"
												placeholder="Company Address"
												value={company_address}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Website</span>
										<div className="form__form-group-field">
											<input
												name="company_website"
												type="url"
												placeholder="Company Website"
												value={company_website}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Submit
									</button>
								</Col>

							</Row>







						</form>

					</div>
				</Modal>
				<Modal
					isOpen={this.state.showResultbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>

					</div>
					<div className="modal__body">
						{results && results.map((result, index) => {
							return (
								<div key={index}>
									<h5><b>{result.vtext}</b>  </h5>
									<Alert color={result.atype}>
										<p>{result.sts}</p>
									</Alert>
								</div>
							)
						})}
					</div>
				</Modal>
			</div>
		);
	}
}

export default withRouter(VendorFormPanel);
