import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';

import SearchIcon from 'mdi-react/SearchIcon';
import Alert from '../../../../shared/components/Alert';

const apiurl = MY_API_URL;
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
	Table,
	Button,
	Badge,
	Modal,
	Row,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	technologies: [],
	selectedTechnologies: [],
	technologyToSearch: '',
	difficulty: 'Beginner',
	responseQuestions: [],
	questionQuantity: [],
	difficultyLevels: [],
	gotCount: [],
	technologyWise: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,
	options: [],
	questionSelected: {},
	technologySelected: '',
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	currentcolor: [
		'#000000',
	],
	content_add: '',

	testEmails: [],
};
class EditJobTest extends Component {
	constructor(props) {
		super(props);

	}
	state = { ...initialstate };
	componentDidMount() {

		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();
			localStorage.setItem('faqtechs', '');
		}
	}








	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
			testid: localStorage.testid,
		};
		const getuurl = apiurl + 'getqueskeystest';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then(async (response) => {
				if (!response.data.error) {
					console.log('allkeys: ', response.data.allkeys);
					console.log('thistest: ', response.data.test);
					console.log('no errors');
					let jobtestkeyids = JSON.parse(response.data.test);
					var prekeys = [];
					var testdtlss = [];
					const unifiedCount = await Promise.all( //eslint-disable-line
						jobtestkeyids.map((testdtls) => {
							return new Promise((resolve, reject) => { //eslint-disable-line

								console.log('testdtls', testdtls);
								prekeys.push(testdtls.technology);
								testdtlss.push(testdtls.difficultyLevel);
								if (
									this.state.selectedTechnologies.indexOf(
										testdtls.technology,
									) == -1
								) {
									this.setState(
										{
											selectedTechnologies: [
												...this.state.selectedTechnologies,
												testdtls.technology,
											],
										},
										() => {
											let previousDifficultyArray = this.state
												.difficultyLevels;
											previousDifficultyArray.push(
												testdtls.difficultyLevel,
											);
											let previousQuantityArray = this.state
												.questionQuantity;
											previousQuantityArray.push(testdtls.questions);
											this.setState({
												difficultyLevels: previousDifficultyArray,
												questionQuantity: previousQuantityArray,
											}, () => {
												console.log('selectedTechnologies', this.state.selectedTechnologies);
												console.log('difficultyLevels', this.state.difficultyLevels);
												console.log('questionQuantity', this.state.questionQuantity);
												resolve('ok');
											});
										},
									);
								} else {
									resolve('ok');
								}
							});
						}),
					);
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
						testcode: response.data.testcode,
					}, () => {
						console.log('prekeys', prekeys);
						console.log('this.state.prekeys', this.state.prekeys);
						console.log('this.state.testdtlss', this.state.testdtlss);
						console.log('testdtlss', testdtlss);
						console.log('this.state.keywords', this.state.keywords);
						console.log('this.state.relatedKeywords', this.state.relatedKeywords);
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// localStorage.clear();
				//this.props.history.push('/');//eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	unselectTechnology = (technology) => {
		console.log('Technology is ', technology);
		console.log('Selected Technologies', this.state.selectedTechnologies);
		console.log('Selected difficultyLevels', this.state.difficultyLevels);
		let index = this.state.selectedTechnologies.indexOf(technology);
		console.log('Index is ', index);
		let copyArray = [...this.state.selectedTechnologies];
		let copyDIfArray = [...this.state.difficultyLevels];
		let copyDIfQkonArray = [...this.state.questionQuantity];
		copyArray.splice(index, 1);
		copyDIfArray.splice(index, 1);
		copyDIfQkonArray.splice(index, 1);
		this.setState(
			{
				selectedTechnologies: [...copyArray],
				difficultyLevels: [...copyDIfArray],
				questionQuantity: [...copyDIfQkonArray],
			},
			() => {
				console.log('Selected Technologies', this.state.selectedTechnologies);
				console.log('Selected difficultyLevels', this.state.difficultyLevels);
			},
		);
	};

	generateTest = (event) => {
		event.preventDefault();
		let quantity = this.state.questionQuantity.reduce((total, quant) => {
			return Number(total) + Number(quant);
		});
		console.log('Quantity is ', quantity);
		this.setState(
			{
				showAlert: false,
			},
			() => {

				if (this.state.selectedTechnologies == []) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				} else if (quantity > 100 || quantity < 10) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText:
							'Number of questions should be more than 10 and less than 100',
					});
				} else {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};
					var postData = {
						technologies: [...this.state.selectedTechnologies],
						questionCount: this.state.questionQuantity,
						level: 'Beginner',
						mode: 'Single',
					};
					const generateTestx = apiurl + 'generateTest';

					axios
						.post(generateTestx, postData, {
							headers: headers,
						})
						.then((response) => {
							// console.log('Response is ', response);
							console.log(response.data.data);
							let duration = 0;
							response.data.data.map((question) => {
								duration = duration + Number(question.Duration);
							});
							if (duration === 0) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText:
										'No test Can  be generated meeting your requirements.Try changing your requirements and Try Again',
								});
							} else {
								this.setState(
									{
										timeRequired: duration,
										startGeneration: false,
										responseQuestions: [...response.data.data],
									},
									() => {
										this.setState({
											showModal: true,
										});
									},
								);
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
		);
	};
	createTest = (wtodo) => {

		let ids = this.state.responseQuestions.map((question) => {
			return question.id;
		});
		let helper = this.state.selectedTechnologies.map((tech, index) => {
			return {
				technology: tech,
				difficultyLevel: this.state.difficultyLevels[index],
				questions: this.state.questionQuantity[index],
			};
		});
		// var techarray = this.state.difficultyLevels;
		// var technames = techarray.join(', ');
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: this.state.testEmails,
			technologies: [...this.state.selectedTechnologies],
			ids: [...ids],
			level: 'Beginner',
			mode: 'Single',
			duration: this.state.timeRequired,
			by: localStorage.by,
			testjobid: localStorage.testjobid,

			testcode: this.state.testcode,
			helper: helper,
			wtodo: wtodo,
		};
		const createTestx = apiurl + 'updateTestJob';

		axios
			.post(createTestx, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('Create Test response is ', response);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showModal: false,
						},
						() => {
							if (localStorage.jstatus == 1) {
								this.props.history.push({ //eslint-disable-line
									pathname: '/jobspublished',
								});
							} else {
								this.props.history.push({ //eslint-disable-line
									pathname: '/jobssaved',
								});
							}

						},
					);
				}

			})
			.catch((error) => {
				console.log(error);
			});
	};


	render() {
		let countingArray = [2, 3, 4, 5, 6, 7, 8, 9, 10];

		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3 order-md-1 order-sm-1 order-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies </h5>

								<form className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<SearchIcon />
											</div>
											<input
												name="tkey"
												type="text"
												placeholder="Search"
												onChange={(e) => {
													this.handleSearch(e);
												}}
											/>
										</div>
									</div>
								</form>
								{/* technologies here */}
								<Table responsive hover>
									<tbody>
										{this.state.relatedKeywords.map((keyword, index) => {

											// var ifmatched = this.state.prekeys.includes(keyword.id);
											// if (ifmatched) {

											// }
											if (
												this.state.selectedTechnologies.indexOf(keyword.id) ===
												-1
											) {
												return (
													<tr key={index}>
														<td
															// onClick={() => {
															// 	this.setState({
															// 		technologySelected: keyword.id,
															// 	});
															// }}
															onClick={() => {
																if (
																	this.state.selectedTechnologies.indexOf(
																		keyword.id,
																	) == -1
																) {
																	this.setState(
																		{
																			selectedTechnologies: [
																				...this.state.selectedTechnologies,
																				keyword.id,
																			],
																		},
																		() => {
																			let previousDifficultyArray = this.state
																				.difficultyLevels;
																			previousDifficultyArray.push(
																				keyword.keyword,
																			);
																			let previousQuantityArray = this.state
																				.questionQuantity;
																			previousQuantityArray.push(2);
																			this.setState({
																				difficultyLevels: previousDifficultyArray,
																				questionQuantity: previousQuantityArray,
																			}, () => {
																				console.log('selectedTechnologies', this.state.selectedTechnologies);
																				console.log('difficultyLevels', this.state.difficultyLevels);
																				console.log('questionQuantity', this.state.questionQuantity);
																			});
																		},
																	);
																}
															}}
															style={{
																background:
																	keyword.id === this.state.technologySelected
																		? 'red'
																		: 'transparent',

																cursor: 'pointer',
															}}
														>
															<strong>{keyword.keyword}</strong>
														</td>
													</tr>
												);
											}
										})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					<div className="col-md-9 order-md-12 order-sm-12 order-1">
						<div className="row">
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<h4>Add Test to Newly Created Job</h4>
										<p><b>{localStorage.testjobcode}</b> - {localStorage.testjobtitle}</p>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										{this.state.selectedTechnologies.map(
											(technology, index) => {
												return (
													<Button
														key={index}
														className="btn-sm icon btn btn-primary"
														style={{ marginLeft: 10 }}
													>
														{this.state.difficultyLevels[index]}

														<i
															className="lnr lnr-cross"
															style={{ marginLeft: 10 }}
															onClick={() => {
																this.unselectTechnology(technology);
															}}
														></i>
													</Button>
												);
											},
										)}
									</div>
								</div>
							</div>


							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<h5 className="bold-text">Entered Questions</h5>
										<Table className="table--bordered">
											<thead>
												<tr>
													<th>#</th>
													<th>Technology</th>
													<th>Select no of Questions</th>
												</tr>
											</thead>
											<tbody>
												{this.state.selectedTechnologies.map((tech, index) => {
													let manipulatedArray =
														this.state.gotCount &&
														this.state.gotCount.map((counts) => {
															return counts.count;
														});
													let countArray = countingArray.filter((counts) => { //eslint-disable-line
														return counts <= manipulatedArray[index];
													});

													return (
														<tr key={index}>
															<td>{index + 1}</td>

															<td>
																<strong>
																	{this.state.difficultyLevels[index]}
																</strong>
															</td>
															<td>
																<DropdownButton
																	size="sm"
																	title={
																		this.state.questionQuantity[index] <
																			countingArray[countingArray.length - 1]
																			? this.state.questionQuantity[index] || 2
																			: countingArray[
																			countingArray.length - 1
																			] || 2
																	}
																	value={2}
																	onSelect={(k) => {
																		let initialArray = [
																			...this.state.questionQuantity,
																		];
																		initialArray[index] = k;

																		this.setState({
																			questionQuantity: [...initialArray],
																			showAlert: false,
																		});
																	}}
																>
																	{countingArray.length > 1 ? (
																		countingArray.map((counts, index) => {
																			return (
																				<Dropdown.Item
																					key={index}
																					eventKey={counts}
																				>
																					{counts}
																				</Dropdown.Item>
																			);
																		})
																	) : (
																		<Dropdown.Item>No Questions </Dropdown.Item>
																	)}
																</DropdownButton>
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
										<Row>
											<div className="col-md-6"></div>
											<div className="col-md-6">
												<form
													className="form"
													onSubmit={(event) => this.generateTest(event)}
												>
													{this.state.questionQuantity.length > 0 ? (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															Next
														</button>
													) : (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="button"
															desabled="true"
														>
															Next
														</button>
													)}
												</form>
											</div>

										</Row>
										<Modal
											isOpen={this.state.showModal}
											modalClassName="ltr-support"
											className="modal-dialog--primary"
										>
											<div className="modal__header">
												<button
													className="lnr lnr-cross modal__close-btn"
													type="button"
													onClick={() => {
														this.setState({
															showModal: false,
														});
													}}
												/>
												<h4 className="text-modal  modal__title">
													A test has been Edited
												</h4>
											</div>
											<div className="modal__body">
												{this.state.showAlert ? (
													<Alert color={this.state.alertType}>
														<p>{this.state.alertText}</p>
													</Alert>
												) : null}
												<div>
													<strong>Technologies:</strong>
													{this.state.selectedTechnologies.map(
														(technology, index) => {
															return (
																<div key={index}>
																	<Badge color="success">
																		{this.state.difficultyLevels[index]}
																	</Badge>
																	<div> </div>
																</div>
															);
														},
													)}
												</div>
												<div>
													<strong>Time Required:</strong>
													{this.state.timeRequired} minutes
												</div>

												<div>
													<strong> Number of questions generated:</strong>
													{this.state.responseQuestions.length}
												</div>
												<div className="mt-3" style={{ color: 'red' }}>
													*Note: If the number of generated questions is lesser
													than mentioned then there might not be enough
													questions in the database that meet your criteria
												</div>
											</div>

											{/* <button
												className="btn btn-primary account__btn account__btn--small"
												type="button"
												onClick={this.createTest.bind(this, 0)}
											>
												Save as Draft
											</button> */}

											<button
												className="btn btn-primary account__btn account__btn--small"
												type="button"
												onClick={this.createTest.bind(this, 1)}
											>
												Update Test
											</button>


										</Modal>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EditJobTest;
