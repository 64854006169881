import React, { PureComponent } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfilerec from './TopbarProfilerec';
// import TopbarMail from './TopbarMail';
// import TopbarLanguage from './TopbarLanguage';
// import TopbarNotification from './TopbarNotification';
import TopbarNavRecruiter from './tobar_nav/TopbarNavRecruiter';
// import TopbarSearch from './TopbarSearch';

export default class TopbarWithNavigationrec extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
	};

	render() {
		const { changeMobileSidebarVisibility } = this.props;

		return (
			<div className="topbar topbar--navigation">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
						/>
						<Link className="topbar__logo" to="/dashboard" />
					</div>
					<TopbarNavRecruiter />
					<div className="topbar__right">
						{/* <TopbarSearch /> */}
						{/* <TopbarNotification /> */}
						{/* <TopbarMail new /> */}
						<TopbarProfilerec />
						{/* <TopbarLanguage /> */}
					</div>
				</div>
			</div>
		);
	}
}
