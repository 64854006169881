import React from 'react';

import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AdminSidebarContent from './AdminSidebarContent';
import CreaterSidebarContent from './CreaterSidebarContent';
import ITSidebarContent from './ITSidebarContent';
import AuditorSidebarContent from './AuditorSidebarContent';
import CompanySidebarContent from './CompanySidebarContent';
import CustomerCareSidebarContent from './CustomerCareSidebarContent';
import RecruiterSidebarContent from './RecruiterSidebarContent';
import SidebarContent from './SidebarContent';

const Sidebar = ({
	changeMobileSidebarVisibility,
	sidebarShow,
	sidebarCollapse,
}) => {
	const sidebarClass = classNames({
		sidebar: true,
		'sidebar--show': sidebarShow,
		'sidebar--collapse': sidebarCollapse,
	});
	var userSidebarContentmobile;
	var userSidebarContent;
	var upanel = localStorage.upanel;
	if (upanel === 'admin-panel') {
		userSidebarContent = <AdminSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<AdminSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'creater-panel') {
		userSidebarContent = <CreaterSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<CreaterSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'it-panel') {
		userSidebarContent = <ITSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<ITSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'auditor-panel') {
		userSidebarContent = <AuditorSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<AuditorSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'company-panel') {
		userSidebarContent = <CompanySidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<CompanySidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'ccare-panel') {
		userSidebarContent = <CustomerCareSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<CustomerCareSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'recruiter-panel') {
		userSidebarContent = <RecruiterSidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<RecruiterSidebarContent onClick={changeMobileSidebarVisibility} />
		);
	} else if (upanel === 'tests-view') {
		userSidebarContent = <SidebarContent onClick={() => { }} />;
		userSidebarContentmobile = (
			<SidebarContent onClick={changeMobileSidebarVisibility} />
		);
	}
	return (
		<div className={sidebarClass}>
			<button
				type="button"
				className="sidebar__back"
				onClick={changeMobileSidebarVisibility}
			/>
			<Scrollbar className="sidebar__scroll scroll">
				<div className="sidebar__wrapper sidebar__wrapper--desktop">
					{userSidebarContent}
					{/* <SidebarContent onClick={() => {}} /> */}
				</div>
				<div className="sidebar__wrapper sidebar__wrapper--mobile">
					{/* <SidebarContent onClick={changeMobileSidebarVisibility} /> */}
					{userSidebarContentmobile}
				</div>
			</Scrollbar>
		</div>
	);
};

Sidebar.propTypes = {
	sidebarShow: PropTypes.bool.isRequired,
	sidebarCollapse: PropTypes.bool.isRequired,
	changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
