import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import {
	Card,
	CardBody,
	Col,
	Badge,
	Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
const apiurl = MY_API_URL;
const initialstate = {
	creater: 0,
	curator: 0,
	auditor: 0,
	recruiter: 0,
	company: 0,
	ccare: 0,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
};
class Dashboard extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		this.handleGetUsers(localStorage.token);

	}

	handleGetUsers = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			role: 'all',
		};
		const getuurl = apiurl + 'texperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allusers = [...response.data.data];
					const allkeys = [...response.data.allkeys];
					let countObject = {
						creater: 0,
						curator: 0,
						auditor: 0,
						company: 0,
						ccare: 0,
						recruiter: 0,
					};
					allusers.map((user) => {
						countObject[user.role] = countObject[user.role] + 1;
					});
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						creaters: countObject.creater,
						curators: countObject.curator,
						auditors: countObject.auditor,
						recruiters: countObject.recruiter,
						company: countObject.company,
						ccare: countObject.ccare,
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								<span style={{ textTransform: 'capitalize' }}>
									{localStorage.roleSelected}
								</span>{' '}
								Dashboard
							</h5>
							<h5 className="subhead">All Sum Users with Roles</h5>
						</div>
						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Users / Keywords</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>
										<Link to="/users/creaters">Creaters</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="success">
											{this.state.creaters}
										</Badge>
									</td>
								</tr>
								<tr>
									<td>2</td>
									<td>
										<Link to="/users/auditors">Auditors</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="success">
											{this.state.auditors}
										</Badge>
									</td>
								</tr>
								<tr>
									<td>3</td>
									<td>
										<Link to="/users/companies">Companies</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="success">
											{this.state.company}
										</Badge>
									</td>
								</tr>
								<tr>
									<td>4</td>
									<td>
										<Link to="/users/recruiter">Recruiters</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="success">
											{this.state.recruiters}
										</Badge>
									</td>
								</tr>
								<tr>
									<td>4</td>
									<td>
										<Link to="/users/ccares">Customer Care</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="success">
											{this.state.ccare}
										</Badge>
									</td>
								</tr>
								<tr>
									<td>5</td>
									<td>
										<Link to="/allKeywords">
											Keywords Active{'  '}
											<Badge style={{ fontSize: '15px' }} color="success">
												{this.state.akeys}
											</Badge>{' '}
											/ Inactive{' '}
											<Badge style={{ fontSize: '15px' }} color="warning">
												{this.state.bkeys}
											</Badge>
										</Link>
									</td>
									<td>
										<Badge style={{ fontSize: '15px' }} color="primary">
											{this.state.tkeys}
										</Badge>
									</td>
								</tr>
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default Dashboard;
