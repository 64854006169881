import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
const apiurl = MY_API_URL;
import { Table, Col, Row, Badge } from 'reactstrap';
const initialstate = {
	mykeys: [],
	token: localStorage.token,
};
class Dashboard extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeysnew();

		}
	}

	handleGetkeysnew = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			userid: localStorage.usercode
		};
		const getuurl = apiurl + 'mytechkeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {

				if (!response.data.error) {
					console.log('response.data.mykeys', response.data.mykeys);

					this.setState({
						mykeys: [...response.data.mykeys],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	auditques = (techid, techname) => {
		if (techid) {
			localStorage.setItem('audittechid', techid);
			localStorage.setItem('audittechname', techname);
			this.props.history.push('checkques'); //eslint-disable-line
		}
	};

	render() {
		return (
			<Row style={{ margin: '0px' }}>



				{/* mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm */}
				<Col md={12}>
					<div className="grid card">
						<div className="card-body">
							<h5 className="bold-text">Questions by Technologies Selected </h5>


							{/* technologies here */}
							<Table responsive hover>
								<thead>
									<tr>
										<th>Users</th>
										<th>Technology</th>
										<th>Processed/All</th>
										<th>Pending Audit</th>
										<th>Approved</th>
										<th>Rejected</th>
									</tr>
								</thead>
								<tbody>

									{this.state.mykeys.map(
										(mykey, index) => {
											var proces = mykey.totques - mykey.allq;
											return (
												<tr key={index}>
													<td>{mykey.users}</td>
													<td>
														{mykey.keyword}
													</td>
													<td>
														<Badge
															style={{ fontSize: '15px' }}
															color="primary"
														>
															{mykey.allq}/{mykey.totques}
														</Badge>
													</td>
													<td>
														{proces && proces >= 1 ? (
															<Badge
																style={{ fontSize: '15px', cursor: 'pointer' }}
																color="success"
																onClick={() => {
																	this.auditques(mykey.id, mykey.keyword);
																}}
															>
																{proces} <i
																	className="lnr lnr-eye"
																	style={{ fontSize: '15px' }}

																></i>
															</Badge>

														) : null}

													</td>
													<td>
														<Badge
															style={{ fontSize: '15px' }}
															color="success"
														>
															{mykey.approved}
														</Badge>
													</td>

													<td>
														<Badge
															style={{ fontSize: '15px' }}
															color="danger"
														>
															{mykey.reported}
														</Badge>
													</td>
												</tr>

											);
										},
									)}
								</tbody>
							</Table>
						</div>
					</div>
				</Col>

			</Row>
		);
	}
}

export default Dashboard;
