import React, { Component } from 'react';
/* eslint-disable */
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

const apiurl = MY_API_URL;
import { Card, CardBody, Col, Row, Badge } from 'reactstrap';

const initialstate = {
	cats: [{ name: '', age: '' }],
	owner: '',
	description: '',
};
class Emailids extends Component {
	constructor(props) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeypress = this.handleKeypress.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.state = {
			currentcolor: [
				'#531CB3',
				'#7149EE',
				'#B754FF',
				'#ED4FEF',
				'#ED49AB',
				'#ED4FEF',
				'#B754FF',
				'#7149EE',
			],
			content_add: 'Enter Email ID',

			myItems: [],
		};
		this.lastId = -1;
	}

	handleFocus(event) {
		this.setState({ content_add: '' });
	}

	handleChange(event) {
		const usr_input = event.target.value;
		this.setState({ content_add: usr_input });
	}

	handleKeypress(event) {
		if (event.key == 'Enter') {
			var newArray = this.state.myItems;
			var currentcontent = this.state.content_add.trim();
			if (!currentcontent) {
				return;
			}

			newArray.push({
				content: currentcontent,
				id: ++this.lastId,
			});
			this.setState({
				myItems: newArray,
				content_add: '',
			});
		}
	}

	handleBlur(event) {
		this.setState({ content_add: 'Add Email ID Press Enter' });
	}

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		this.setState({ myItems: newArray });
	}

	componentDidUpdate(prevProps, prevState) {}

	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	render() {
		return (
			<div>
				<input
					id="add"
					email="text"
					name="initvalue"
					autoComplete="off"
					maxLength="70"
					onFocus={this.handleFocus}
					onChange={this.handleChange}
					onKeyPress={this.handleKeypress}
					onBlur={this.handleBlur}
					value={this.state.content_add}
					style={{ width: '300px' }}
					required
				/>
				<div>{this.makeAddedList()}</div>
			</div>
		);
	}
}

export default Emailids;
