import React, { Component } from 'react';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import RefreshIcon from 'mdi-react/RefreshIcon';
// import Select from 'react-select';
import swal from 'sweetalert';
// import Alert from '../../../shared/components/Alert'; //eslint-disable-line
// import EditIcon from 'mdi-react/EditIcon';
import CheckIcon from 'mdi-react/CheckIcon'; // eslint-disable-line
// import AlertIcon from 'mdi-react/AlertIcon';
const apiurl = MY_API_URL;
import {
	Button,
	Badge //eslint-disable-line
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',

	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	duration: 0,
	quesidslen: 0,
	newdiflevel: '',
};
class TechCheckQuestionsEdited extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.audittechid) {
			this.fetchQuestions(localStorage.audittechid);
		} else {
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleCkey = () => {
		this.setState({
			upSingleModal: false,
			quesid: 0,
			question: '',
			optionA: '',
			optionB: '',
			optionC: '',
			optionD: '',
			correctAnswer: '',
			diflevel: '',
		});
	};
	handleSingle = (quesid, question, optiona, optionb, optionc, optiond, ans, dlevel, duration) => {
		this.setState({
			upSingleModal: true,
			quesid: quesid,
			question: question,
			optionA: optiona,
			optionB: optionb,
			optionC: optionc,
			optionD: optiond,
			correctAnswer: ans,
			diflevel: dlevel,
			duration: duration,
		});
	};

	handleSingleSubmit = (event) => {

		// alert(event);
		let optionss = [];
		optionss.push(this.state.optionA);
		optionss.push(this.state.optionB);
		optionss.push(this.state.optionC);
		optionss.push(this.state.optionD);
		console.log('OPTIONS ARRAY IS ', optionss);
		this.setState(
			{
				options: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'techeditappQuestion';
				var postData = {
					technology: localStorage.audittechid,
					options: [...this.state.options],
					userid: localStorage.usercode,
					question: this.state.question,
					answer: this.state.correctAnswer,
					diflevel: this.state.diflevel,
					duration: this.state.duration,
					quesid: this.state.quesid,
					aevent: event,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{

									upSingleModal: false,
									showAlert: false,
									question: '',
									options: [],
									optionA: '',
									optionB: '',
									optionC: '',
									optionD: '',
									quesid: '',
									correctAnswer: '',
									duration: '',
								},
								() => {
									this.fetchQuestions();
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};


	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {

			technology: localStorage.audittechid,
			techtype: localStorage.audittechtype,

		};
		const getfqurl = apiurl + 'processedtechquestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('response.data', response.data);
				if (!response.data.error) {
					this.setState({
						questions: [...response.data.questions],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				console.log('error', error);
				// this.props.history.push('/');
			});
	};






	handleDelQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Report',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techdelQnew';
				var postData = {
					quesid: quesid,
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.fetchQuestions();
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleUpdateQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Approve',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techappQnewf';
				var postData = {
					quesid: quesid,
					newdiflevel: this.state['level' + quesid],
					newduration: this.state['time' + quesid],
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.setState({
								newdiflevel: ''
							}, () => {
								this.fetchQuestions();
								swal(response.data.message, {
									icon: 'success',
								});
							});

						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};







	render() {
		const { quesid, question, optionA, optionB, optionC, optionD, diflevel, correctAnswer, duration, tinymceapikey } = this.state; //eslint-disable-line




		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">



					<div className="col-md-6">
						<div className="grid card">
							<div className="card-body">
								{localStorage.audittechid ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.fetchQuestions.bind(this)}
									>
										<strong style={{ fontSize: '18px' }}><RefreshIcon /> {this.state.questions && this.state.questions.length}</strong>	Refresh  Questions
									</Button>
								) : (
										<Button
											className="btn-sm btn-block"
											color="success"
											disabled
											style={{ cursor: 'not-allowed' }}
										>
											Refresh   Questions of {localStorage.audittechname}
										</Button>

									)}


							</div>
						</div>
					</div>
					{this.state.questions && this.state.questions.length >= 1 ? (
						<div className="col-md-6">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Questions of {localStorage.audittechname}

									</h5>

								</div>
							</div>
						</div>
					) : null}


				</div>

				<div className="row">

					{this.state.questions &&
						this.state.questions.map((ques, index) => {
							var mainques = ques.mainques;
							var techquesofmain = ques.techquesofmain; // eslint-disable-line
							var diffone = 0;
							var difftwo = 0;
							var diffthree = 0;
							var tpfive = 0;
							var tone = 0;
							var tonepfive = 0;
							var ttwo = 0;
							var ttwopfive = 0;
							var tthree = 0;
							var tthreepfive = 0;
							var tfour = 0;
							var tfourpfive = 0;
							var tfive = 0;

							techquesofmain.map((techques) => {
								if (techques.difficulty_level == 'Beginner') {
									diffone = diffone + 1;
								} else if (techques.difficulty_level == 'Intermediate') {
									difftwo = difftwo + 1;
								} else if (techques.difficulty_level == 'Advanced') {
									diffthree = diffthree + 1;
								}
								if (techques.duration == '0.5') {
									tpfive = tpfive + 1;
								} else if (techques.duration == '1') {
									tone = tone + 1;
								} else if (techques.duration == '1.5') {
									tonepfive = tonepfive + 1;
								} else if (techques.duration == '2') {
									ttwo = ttwo + 1;
								} else if (techques.duration == '2.5') {
									ttwopfive = ttwopfive + 1;
								} else if (techques.duration == '3') {
									tthree = tthree + 1;
								} else if (techques.duration == '3.5') {
									tthreepfive = tthreepfive + 1;
								} else if (techques.duration == '4') {
									tfour = tfour + 1;
								} else if (techques.duration == '4.5') {
									tfourpfive = tfourpfive + 1;
								} else if (techques.duration == '5') {
									tfive = tfive + 1;
								}
							});
							return (
								<div className="col-md-12" key={index}>
									<div className="row">
										<div className="col-md-8">
											<div className="grid card">
												<div className="card-body">
													<Badge style={{ marginRight: '20px', fontSize: '12px', float: 'right' }} color="primary">
														{mainques.difficulty_level}
													</Badge> <Badge style={{ marginRight: '20px', fontSize: '12px', float: 'right' }} color="primary">
														{mainques.duration}
													</Badge>


													<h5
														className="col-md-12 bold-text"
														style={{ position: 'relative', paddingLeft: '20px' }}
													>
														<span className="cusquestionic">Q:{index + 1}</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + mainques.question + '</p>'}
															disabled={true}
															init={{
																inline: true,
																height: 100,
																menubar: false,

																toolbar: '',
															}}

														/>
													</h5>
													<div className="clearfix"></div>

													<div className="row">
														<div
															className={
																mainques.correct == 0
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[0] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>


														<div
															className={
																mainques.correct == 1
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[1] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>



														<div className="clearfix"></div>
														<div
															className={
																mainques.correct == 2
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">C:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[2] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>

														<div className="clearfix"></div>
														<div
															className={
																mainques.correct == 3
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">D:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[3] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>


														<div className="clearfix"></div>
														<div className="col-md-12" style={{ marginTop: '15px' }}>
															<div className="row">
																<div className="col-md-6">
																	<h5>Recomended Duration</h5>
																	{tpfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">0.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tpfive} </Badge></Badge>) : null}
																	{tone != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">1min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tone} </Badge></Badge>) : null}
																	{tonepfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">1.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tonepfive} </Badge></Badge>) : null}
																	{ttwo != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">2min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {ttwo} </Badge></Badge>) : null}
																	{ttwopfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">2.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {ttwopfive} </Badge></Badge>) : null}
																	{tthree != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">3min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tthree} </Badge></Badge>) : null}
																	{tthreepfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">3.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tthreepfive} </Badge></Badge>) : null}
																	{tfour != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">4min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfour} </Badge></Badge>) : null}
																	{tfourpfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">4.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfourpfive} </Badge></Badge>) : null}
																	{tfive != 0 ? (<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">5min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfive} </Badge></Badge>) : null}
																</div>
																<div className="col-md-6">
																	<h5>Recomended Level</h5>
																	{diffone != 0 ? (
																		<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">
																			Beginner <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{diffone}
																			</Badge>
																		</Badge>
																	) : null}{' '}{difftwo != 0 ? (
																		<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="warning">
																			Intermediate<Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{difftwo}
																			</Badge>
																		</Badge>
																	) : null}{' '}{diffthree != 0 ? (
																		<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="danger">
																			Advanced<Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{diffthree}
																			</Badge>
																		</Badge>
																	) : null}

																</div>

															</div>
														</div>
													</div>



												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="grid card">
												<div className="card-body">
													{techquesofmain &&
														techquesofmain.map((techques, index) => {
															return (
																<div className="row techquesofmain" key={index}>
																	<div className="col-md-12">
																		<b>Name: </b>{techques.techname}
																	</div>
																	<div className="col-md-5">
																		<b>Level: </b>
																		{techques.difficulty_level == mainques.difficulty_level ? 'Default' : techques.difficulty_level}
																	</div>
																	<div className="col-md-5">
																		<b>Time: </b>
																		{techques.duration == mainques.duration ? 'Default' : techques.duration}
																	</div>

																	<div className="col-md-2">
																		<span style={{ color: 'green', cursor: 'pointer' }}

																			onClick={this.handleUpdateQues.bind(this, techques.id)}
																		>
																			<CheckIcon />
																		</span>

																	</div>

																</div>
															);
														})}

												</div>
											</div>
										</div>
									</div>

								</div>
							);
						})}


				</div>

			</div >
		);
	}
}

export default TechCheckQuestionsEdited;
