import React, { Component } from 'react';


import { Editor } from '@tinymce/tinymce-react';
import Notifications, { notify } from 'react-notify-toast';
import axios from 'axios';
import { MY_API_URL, TINYMCEAPIKEY } from '../../../Apanel/QueryMe';
import DeleteIcon from 'mdi-react/DeleteIcon';

import swal from 'sweetalert';
const apiurl = MY_API_URL;
import {
	Card,
	CardBody,
	Col,
	Button,
	Badge,
	Input,
	Row,
	Container,
} from 'reactstrap';
const initialstate = {
	showAlert: false,
	alertType: '',
	alertText: '',

	token: localStorage.token,
	options: [],
	editoptions: [],
	technologySelected: '',
	editquesid: '',
	editquestion: '',
	editoptionA: '',
	editoptionB: '',
	editoptionC: '',
	editoptionD: '',
	editcorrectAnswer: '',
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	enterques: false,
	questype: 0,
	questypename: '',
	testname: '',
	newtestname: '',
	testid: localStorage.custestid,
	editOpModal: false,
	showtickets: 1,
};
class AddQuest extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			if (localStorage.custestid) {
				this.handleGetTest(localStorage.custestid);
			} else {
				this.createTestName();
			}
		}
	}
	_handleFocus(noorid, tfor, opt, text) {
		console.log(text);
		console.log(
			'Focused with: ' +
			text +
			' noorid:' +
			noorid +
			' tfor:' +
			tfor +
			' opt:' +
			opt,
		);
		this.setState({
			edittestid: tfor,
			oldkeyname: text,
			noorid: noorid,
		});
	}

	_handleFocusOut(noorid, tfor, opt, newkeyname) {
		console.log(
			'Left editor with: ' +
			newkeyname +
			' noorid:' +
			noorid +
			' tfor:' +
			tfor +
			' opt:' +
			opt,
		);
		console.log('edittestid', this.state.edittestid);
		console.log('oldkeyname', this.state.oldkeyname);
		console.log('noorid', this.state.noorid);
		if (noorid == this.state.noorid) {
			if (newkeyname == this.state.oldkeyname) {
				console.log('no change');
			} else {
				// post started
				let editkeyurl = '';
				var postData = {};
				if (tfor == 'testname') {
					editkeyurl = apiurl + 'editmytest';
					postData = {
						noorid: noorid,
						testname: newkeyname,
					};
				} else if (tfor == 'quesname') {
					editkeyurl = apiurl + 'editmyques';
					postData = {
						noorid: noorid,
						quesname: newkeyname,
						testid: localStorage.custestid,
					};
				} else if (tfor == 'opt') {
					console.log('opt change');
					editkeyurl = apiurl + 'editmyquesopt';
					postData = {
						noorid: noorid,
						optext: newkeyname,
						opt: opt,
						testid: localStorage.custestid,
					};
				}
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				axios
					.post(editkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							notify.show(
								newkeyname + ' ' + response.data.message,
								'warning',
								3000,
								'',
							);
							window.location.reload(false);
						} else {
							notify.show(
								newkeyname + ' ' + response.data.message,
								'success',
								3000,
								'',
							);
						}
					});
				//post ended
			}
		}
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleSelectQuesType = (quesid, e) => {
		let questype = e.target.value;
		console.log('quesid', quesid);
		console.log('questype', questype);
		let questypename = '';
		let optionss = [];
		if (questype == 1) {
			questypename = 'Multiple choice';
			optionss.push('Option A');
			optionss.push('Option B');
			optionss.push('Option C');
			optionss.push('Option D');
		} else if (questype == 2) {
			questypename = 'True or False';
			optionss.push('True');
			optionss.push('False');
			optionss.push('');
			optionss.push('');
		} else if (questype == 3) {
			questypename = 'Paragraph';
			optionss.push('');
			optionss.push('');
			optionss.push('');
			optionss.push('');
		}
		this.handleQuestionEditSubmit(optionss, quesid, questype, questypename);
	};
	handleQuestionEditSubmit = (optionss, quesid, questype, questypename) => {
		console.log('OPTIONS ARRAY IS ', optionss);

		this.setState(
			{
				editoptions: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'chngMyOwnMQues';
				var postData = {
					options: [...this.state.editoptions],
					answer: 0,
					questype: questype,
					questypename: questypename,
					quesid: quesid,
					email: localStorage.by,
					testid: localStorage.custestid,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState({
								editOpModal: false,
								showAlert: false,

								questions: [...response.data.testques],

								questype: 0,
								questypename: '',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};
	handleComplete = () => {
		swal({
			title: 'Save: ' + this.state.newtestname,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'savecustumtest';
				var postData = {
					quesid: localStorage.custestid,
					email: localStorage.by,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							swal(response.data.message, {
								icon: 'success',
							});
							localStorage.setItem('custestid', '');
							this.props.history.push('/customsavedtest'); //eslint-disable-line
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleExit = () => {
		localStorage.setItem('custestid', '');
		this.props.history.push('/mycustoms'); //eslint-disable-line
	};
	handleSingleSubmit = () => {
		let correctAnswer = 0;
		let optionss = [];
		let questypename = 'Multiple choice';
		let questype = 1;
		optionss.push('Option A');
		optionss.push('Option B');
		optionss.push('Option C');
		optionss.push('Option D');
		console.log('OPTIONS ARRAY IS ', optionss);

		this.setState(
			{
				options: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'addMyOwnMQues';
				var postData = {
					options: [...this.state.options],
					email: localStorage.by,
					question: 'Question',
					answer: correctAnswer,
					questype: questype,
					questypename: questypename,
					testid: localStorage.custestid,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState({
								enterques: false,
								showAlert: false,
								question: '',
								options: [],
								optionA: '',
								optionB: '',
								optionC: '',
								optionD: '',
								correctAnswer: '',
								questions: [...response.data.testques],

								questype: 0,
								questypename: '',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};

	createTestName = () => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		let uploadurl = 'createMyTestName';

		const addsqurl = apiurl + uploadurl;
		var postData = {
			testname: 'New Test Name',
			email: localStorage.by,
		};
		axios
			.post(addsqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
						testid: 0,
					});
				} else {
					console.log(response.data.data);
					localStorage.setItem('custestid', response.data.data[0].id);
					this.setState(
						{
							showAlert: false,
							enterques: false,
							questype: 0,
							questypename: '',
							testid: response.data.testid,
						},
						() => {
							console.log(response.data.data[0].testname);
							this.setState({
								newtestname: response.data.data[0].testname,
								questions: [...response.data.testques],
							});
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleGetTest = (testid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			testid: testid,
		};
		const getfqurl = apiurl + 'getmytestid';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log(response.data.data[0].testname);
					this.setState({
						newtestname: response.data.data[0].testname,
						questions: [...response.data.testques],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handlechngAnswer = (quesid, oans, qans, id) => {
		console.log(quesid + 'quesid');
		console.log(id + 'id');
		console.log(qans + 'qans');
		if (oans == qans) { //eslint-disable-line
		} else {
			swal({
				title: 'Change Answer',
				text: 'Are you sure?',
				icon: 'warning',
				buttons: true,
				dangerMode: true,
			}).then((keyconfirm) => {
				if (keyconfirm) {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};

					const togkeyurl = apiurl + 'changeOption';
					var postData = {
						quesid: quesid,
						qans: qans,
					};
					axios
						.post(togkeyurl, postData, {
							headers: headers,
						})
						.then((response) => {
							console.log(response.data.message);

							if (response.data.error) {
								notify.show(response.data.message, 'warning', 3000, '');
							} else {
								this.handleGetTest(localStorage.custestid);

								notify.show(response.data.message, 'success', 3000, '');
							}
						})
						.catch((error) => {
							console.log('Error is ', error);
							swal('Try Again Later', {
								icon: 'Danger',
							});
						});
				}
			});
		}
	};
	handleDelQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Delete',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'managemyquestion';
				var postData = {
					quesid: quesid,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetTest(localStorage.custestid);
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	// _handleFocusOut(noorid, tfor, opt, e) {
	// 	if (this.state[tfor]) {
	// 		// post started
	// 		let editkeyurl = '';
	// 		if (tfor == 'question') {
	// 			console.log('question change');
	// 			editkeyurl = apiurl + 'editnormalques';
	// 			var postData = {
	// 				noorid: noorid,
	// 				quesname: this.state[tfor],
	// 			};
	// 		} else if (tfor == 'option') {
	// 			console.log('opt change');
	// 			editkeyurl = apiurl + 'editnormalquesopt';
	// 			var postData = {
	// 				noorid: noorid,
	// 				optext: this.state[tfor],
	// 				opt: opt,
	// 			};
	// 		}
	// 		const headers = {
	// 			'Content-Type': 'application/json',
	// 			Authorization: localStorage.token,
	// 		};

	// 		axios
	// 			.post(editkeyurl, postData, {
	// 				headers: headers,
	// 			})
	// 			.then((response) => {
	// 				console.log(response.data.message);
	// 				if (response.data.error) {
	// 					this.setState(
	// 						{
	// 							question: '',
	// 							option: '',
	// 							failbtn: false,
	// 						},
	// 						() => {
	// 							notify.show(response.data.message, 'warning', 3000, '');
	// 						},
	// 					);

	// 					window.location.reload(false);
	// 				} else {
	// 					this.setState(
	// 						{
	// 							question: '',
	// 							option: '',
	// 							failbtn: false,
	// 						},
	// 						() => {
	// 							notify.show(response.data.message, 'success', 3000, '');
	// 						},
	// 					);
	// 				}
	// 			});
	// 		//post ended
	// 	} else {
	// 	}
	// }

	handleTestnameChange(newtestname, editor) { //eslint-disable-line
		this.setState({ newtestname });
	}
	handleQuestionChange(question, editor) { //eslint-disable-line
		this.setState({ question });
	}
	handleOptionChange(option, editor) { //eslint-disable-line
		console.log('optiona was optiona:', option);
		// console.log('optiona was editor:', editor);
		this.setState({ option });
	}
	// desableButtons(dd, gg) {
	// 	this.setState({ failbtn: true });
	// }
	_handleEditorFocusOut(noorid, tfor, opt, e) { //eslint-disable-line
		if (this.state[tfor]) {
			// post started
			let editkeyurl = '';
			var postData = {};
			if (tfor == 'question') {
				console.log('question change');
				editkeyurl = apiurl + 'editcustomques';
				postData = {
					noorid: noorid,
					quesname: this.state[tfor],
				};
			} else if (tfor == 'newtestname') {
				console.log('tfor: ', tfor);
				console.log('newtestname change');
				editkeyurl = apiurl + 'editmytest';
				postData = {
					noorid: noorid,
					testname: this.state[tfor],
				};
			} else if (tfor == 'option') {
				console.log('opt change');
				editkeyurl = apiurl + 'editcustomquesopt';
				postData = {
					noorid: noorid,
					optext: this.state[tfor],
					opt: opt,
				};
			}
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			axios
				.post(editkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState(
							{
								question: '',
								option: '',
								failbtn: false,
							},
							() => {
								notify.show(response.data.message, 'warning', 3000, '');
							},
						);

						window.location.reload(false);
					} else {
						this.setState(
							{
								question: '',
								option: '',
								failbtn: false,
							},
							() => {
								notify.show(response.data.message, 'success', 3000, '');
							},
						);
					}
				});
			//post ended
		}
	}
	render() {

		const questiontypes = [
			{ value: '1', label: 'Multiple choice' },
			{ value: '2', label: 'True or False' },
			{ value: '3', label: 'Paragraph' },
		];
		return (
			<Container>
				<Notifications />
				{this.state.newtestname ? (
					<Row style={{ margin: '0px' }}>
						<Col md={11}>
							<Card>
								<CardBody>
									<h5 className="bold-text">
										Test Name:{' '}
										<Editor
											apiKey={TINYMCEAPIKEY}
											value={this.state.newtestname}
											init={{
												inline: true,
												height: 100,
												menubar: false,
												plugins: [
													'advlist autolink lists link image',
													'charmap print preview anchor help',
													'searchreplace visualblocks code',
													'insertdatetime media table paste wordcount',
												],
												toolbar: '',
											}}
											ClassName="myInputClass"
											onEditorChange={this.handleTestnameChange.bind(this)}
											onFocusOut={this._handleEditorFocusOut.bind(
												this,
												localStorage.custestid,
												'newtestname',
												'opt',
											)}
										/>
									</h5>
								</CardBody>
							</Card>
						</Col>
						<Col md={1}>
							<Button
								className="btn-sm"
								color="success"
								onClick={this.handleSingleSubmit}
								style={{ position: 'fixed', zIndex: '9', top: '100px', right: '35px' }}
							>
								+
							</Button>
						</Col>
					</Row>
				) : null}
				{this.state.questions &&
					this.state.questions.map((ques, index) => {
						return (
							<div className="col-md-11" key={index}>
								<div className="row">
									<div className="col-md-12">
										<div className="grid card">
											<div className="card-body">
												<Badge className="qtypename" color="primary">
													{ques.type}{' '}
												</Badge>
												<div className="questiontype">
													<Input
														type="select"
														name="questype"
														style={{ fontSize: '12px' }}
														onChange={this.handleSelectQuesType.bind(
															this,
															ques.id,
														)}
														defaultValue={ques.questype}
													>
														{questiontypes &&
															questiontypes.map((questiontype, index) => {
																return (
																	<option
																		key={index}
																		value={questiontype.value}
																	>
																		{questiontype.label}
																	</option>
																);
															})}
													</Input>
												</div>
												<span
													className="deleteques"
													onClick={this.handleDelQues.bind(this, ques.id)}
												>
													<DeleteIcon />
												</span>
												<h5
													className="col-md-10 bold-text"
													style={{ position: 'relative', paddingLeft: '10px' }}
												>
													<span className="cusquestionic">Q:{index + 1}</span>

													<Editor
														apiKey={TINYMCEAPIKEY}
														value={'<p>' + ques.question + '</p>'}
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onEditorChange={this.handleQuestionChange.bind(
															this,
														)}
														onFocusOut={this._handleEditorFocusOut.bind(
															this,
															ques.id,
															'question',
															'opt',
														)}
													/>
												</h5>
												<div className="clearfix"></div>
												{ques.questype == 1 ? (
													<div className="row">
														<div
															className={
																ques.correct == 0
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[0]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	0,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 0 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	0,
																)}
															/>
															{ques.correct == 0 ? 'Ans' : null}
														</div>
														<div
															className={
																ques.correct == 1
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[1]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	1,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 1 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	1,
																)}
															/>
															{ques.correct == 1 ? 'Ans' : null}
														</div>
														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 2
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">C:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[2]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	2,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 2 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	2,
																)}
															/>
															{ques.correct == 2 ? 'Ans' : null}
														</div>
														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 3
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">D:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[3]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	3,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 3 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	3,
																)}
															/>
															{ques.correct == 3 ? 'Ans' : null}
														</div>
														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 2 ? (
													<div className="row">
														<div
															className={
																ques.correct == 0
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>
															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[0]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	0,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 0 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	0,
																)}
															/>
															{ques.correct == 0 ? 'Ans' : null}
														</div>
														<div className="clearfix"></div>
														<div
															className={
																ques.correct == 1
																	? 'col-md-10 mycusoptionsuccess'
																	: 'col-md-10 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>
															<Editor
																apiKey={TINYMCEAPIKEY}
																value={ques.options[1]}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,
																	plugins: [
																		'advlist autolink lists link image',
																		'charmap print preview anchor help',
																		'searchreplace visualblocks code',
																		'insertdatetime media table paste wordcount',
																	],
																	toolbar: '',
																}}
																onEditorChange={this.handleOptionChange.bind(
																	this,
																)}
																onFocusOut={this._handleEditorFocusOut.bind(
																	this,
																	ques.id,
																	'option',
																	1,
																)}
															/>
														</div>
														<div className="col-md-1 anscusoptions">
															<Input
																type="radio"
																name={ques.id}
																checked={ques.correct == 1 ? 1 : 0}
																onChange={this.handlechngAnswer.bind(
																	this,
																	ques.id,
																	ques.correct,
																	1,
																)}
															/>
															{ques.correct == 1 ? 'Ans' : null}
														</div>
														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 3 ? (
													<div className="row">
														<div className="col-md-10 mycusoptions">
															Test Taker Need to Write Answer
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				{this.state.newtestname && this.state.questions ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<h5 className="bold-text">
									<Button
										className="btn-sm"
										color="primary"
										onClick={this.handleExit}
									>
										Exit
									</Button>
									<Button
										className="float-right btn-sm"
										color="success"
										onClick={this.handleComplete}
									>
										Finalise and Exit
									</Button>
								</h5>
							</CardBody>
						</Card>
					</Col>
				) : null}
			</Container>
		);
	}
}

export default AddQuest;
