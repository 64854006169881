import React, { Component } from 'react';
/* eslint-disable */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import { Table } from 'react-bootstrap';
import { Card, CardBody, Col, Badge } from 'reactstrap';
import ResultMeter from '../../../Apanel/ResultMeter';
import TechName from '../../../Apanel/TechName';
var download = require('downloadjs');
var dateFormat = require('dateformat');
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	testTakers: [],
};
class CusComTest extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'cuscomtest');
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchSentTests();
		}
	}
	timespent = (given_seconds) => {
		let hours = Math.floor(given_seconds / 3600);
		let minutes = Math.floor((given_seconds - hours * 3600) / 60);
		let seconds = given_seconds - hours * 3600 - minutes * 60;

		let timeString =
			hours.toString().padStart(2, '0') +
			':' +
			minutes.toString().padStart(2, '0') +
			':' +
			seconds.toString().padStart(2, '0');

		return timeString;
	};

	fetchSentTests = () => {
		this.setState(
			{
				loading: true,
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};
				var postData = {
					email: localStorage.by,
				};
				const getfqurl = apiurl + 'fetchCustComSentTests';
				axios
					.post(getfqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.data);
						this.setState(
							{
								tests: [...response.data.data],
							},
							() => {
								this.setState({
									loading: false,
								});
							},
						);
					})
					.catch((error) => {
						console.log(error);
					});
			},
		);
	};
	getBadgeColor = (index) => {
		switch (index % 3) {
			case 0:
				return 'badge-purple';
			case 1:
				return 'badge-yellow';
			case 2:
				return 'badge-grey';
		}
	};
	render() {
		const mystyle = {
			color: 'green',
			fontSize: '22px',
		};
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">List of Completed Tests</h5>
						</div>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Test Name</th>
									<th>Test Taker Details</th>
									<th>
										Time Taken
										<br />
										HH:MM:SS
									</th>

									<th>% of Score</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tests &&
									this.state.tests.map((test, index) => {
										let allresults = JSON.parse(test.results);
										// console.log('results here ', allresults);
										let allcusques = test.question_ids;
										let allcusquescnt = allcusques.split(',');
										let totalCorrect = parseInt(allresults[0].correct, 0);
										let totalIncorrect = parseInt(allresults[0].incorrect, 0);
										let totalUnattempted = parseInt(
											allresults[0].unattempted,
											0,
										);
										let totalquestions = allcusquescnt.length;

										totalCorrect = (100 / totalquestions) * totalCorrect;
										return (
											<tr key={index}>
												<td>{test.id}</td>
												<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
												<td>{test.testname}</td>
												<td>
													<b>Email: </b>
													{test.sent_to}
													<br />
													<b>Name: </b>
													{test.name}
													<br />
													<b>Mobile: </b>
													{test.mobile}
												</td>
												<td>{this.timespent(test.nqno)}</td>

												<td>
													<ResultMeter
														marks={totalCorrect}
														mmarks={totalquestions}
													/>
												</td>
												<td>
													{test.taken === 1 ? (
														<div>
															{/* <i
																className="lnr lnr-download"
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	this.fetchTest(test.id, test.sent_to);
																}}
															></i> */}
															<i
																className="lnr lnr-eye"
																style={mystyle}
																onClick={() => {
																	localStorage.setItem(
																		'cusreporttestId',
																		test.id,
																	);
																	this.props.history.push('/custestreport');
																}}
															></i>
														</div>
													) : (
															''
														)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default CusComTest;
