import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactSpeedometer from 'react-d3-speedometer';




class ResultMeter extends PureComponent {
	constructor (props) {
		super(props);
	}

	static propTypes = {
		marks: PropTypes.number,
	};
	render() {

		const marks = Math.round(this.props.marks);
		return (
			<ReactSpeedometer
				forceRender
				maxSegmentLabels={1}
				width={150}
				height={150}
				ringWidth={25}
				// segmentColors={['green', 'gold']}
				// segments={5}
				// segmentColors={['red', 'orange', 'yellow', 'grey', 'green']}
				startColor="red"
				segments={10}
				endColor="green"
				needleColor="blue"
				needleHeightRatio={0.6}
				labelFontSize="9px"
				minValue={0}
				maxValue={100}
				value={marks}
			// customSegmentStops={[0, marks, 100]}
			/>
		);
	}
}

export default withRouter(ResultMeter);
