import React, { Component } from 'react';
/* eslint-disable */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
var dateFormat = require('dateformat');
import readXlsxFile from 'read-excel-file';
import { Link, withRouter } from 'react-router-dom';
const apiurl = MY_API_URL;
import {
	Card,
	CardBody,
	Col,
	Table,
	Button,
	Badge,
	ButtonGroup,
	ButtonToolbar,
	Modal,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	showQues: false,
	technology: '',
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class Allmycustoms extends Component {
	constructor (props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			localStorage.setItem('custestid', '');
			this.fetchAllmycustoms(localStorage.token);
		}
	}
	handleCtest = (testid, n) => {
		console.log(testid);
		localStorage.setItem('custestid', testid);
		this.props.history.push('/addcustomtest');
	};

	fetchAllmycustoms = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getfqurl = apiurl + 'fetchAllmycustoms';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
				} else {
					this.setState({
						tests: [...response.data.data],
					});
				}
			})
			.catch((error) => { });
	};

	render() {
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-12">
						<div className="grid card">
							<div className="card-body">
								<div className="card__title">
									<h5 className="bold-text">
										<span style={{ textTransform: 'capitalize' }}>
											All Un Saved Custom Tests
										</span>
									</h5>
									<Table striped bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Test Name</th>
												<th>No. Q</th>
												<th>View/Edit</th>
											</tr>
										</thead>
										<tbody>
											{this.state.tests &&
												this.state.tests.map((test, index) => {
													return (
														<tr key={index}>
															<td>{index}</td>
															<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
															<td>{test.testname}</td>

															<td>{test.allq}</td>

															<td>
																<Button
																	className="float-right btn-sm"
																	color="success"
																	onClick={this.handleCtest.bind(
																		this,
																		test.testid,
																	)}
																>
																	View/Edit
																</Button>
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Allmycustoms;
