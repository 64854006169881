import React, { Component } from 'react';

import ReactDOM from 'react-dom'; // eslint-disable-line
import EditableLabel from 'react-inline-editing';
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import Switch from 'react-switch';
// import SearchIcon from 'mdi-react/SearchIcon';
import {
	Button,
	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Modal,
} from 'reactstrap';

import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import Alert from '../../../shared/components/Alert';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	client_types: [],
	client_type: '',
	lead_statuss: [],
	lead_status: '',
	alldoctypes: [],
	req_document: '',
	allinterview_modes: [],
	interview_mode: '',
	allsecurity_clearances: [],
	security_clearance: '',
	alldash_states: [],
	dash_state: '',
	allemp_types: [],
	emp_type: '',
	alljob_types: [],
	job_type: '',
	allvisatypess: [],
	visatypes: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	oldkeyname: '',
	newkeyname: '',
	noorid: 0,

	addCliModal: false,
	addLStsModal: false,
	addDocTypeModal: false,
	addIntModeModal: false,
	addSecClearModal: false,
	addDashStateModal: false,
	addJobTypeModal: false,
	addEmpTypeModal: false,
	addVisaTypesModal: false,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class DashBItems extends Component {
	constructor (props) {
		super(props);
		// this._handleFocus = this._handleFocus.bind(this);
		// this._handleFocusOut = this._handleFocusOut.bind(this);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.dashbid) {
			this.handleGetkeys(localStorage.dashbid);
		} else {
			this.props.history.push('/managedashboards'); //eslint-disable-line
		}

	}
	_handleFocus(noorid, text) {
		console.log(text);
		console.log('Focused with: ' + text + ' noorid:' + noorid);
		this.setState({
			oldkeyname: text,
			noorid: noorid,
		});
	}

	_handleFocusOut(noorid, noortype, newkeyname) {
		console.log('Left editor with: ' + newkeyname + ' noorid:' + noorid + ' noortype:' + noortype);
		console.log('oldkeyname', this.state.oldkeyname);
		console.log('noorid', this.state.noorid);
		if (noorid == this.state.noorid) {
			if (newkeyname == this.state.oldkeyname) {
				console.log('no change');
			} else {
				// post started
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const editkeyurl = apiurl + noortype;
				var postData = {
					noorid: noorid,
					noortype: newkeyname,
					dashbid: localStorage.dashbid,
				};
				axios
					.post(editkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							swal(newkeyname + ' ' + response.data.message, {
								icon: 'warning',
							});
							window.location.reload(false);
						} else {
							swal(newkeyname + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					});
				//post ended
			}
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	addCliTypeSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.client_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addclienttype';
		var postData = {
			client_type: this.state.client_type,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							client_type: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addLeadStsSubmit = (event) => {
		event.preventDefault();
		console.log(this.state.lead_status);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addleadstatus';
		var postData = {
			lead_status: this.state.lead_status,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							lead_status: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addDocTypeSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.req_document);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'adddoctype';
		var postData = {
			req_document: this.state.req_document,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							req_document: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addIntModeSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.interview_mode);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addintmode';
		var postData = {
			interview_mode: this.state.interview_mode,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							interview_mode: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addSecClearSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.security_clearance);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addsecclear';
		var postData = {
			security_clearance: this.state.security_clearance,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							security_clearance: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addDashStateSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.dash_state);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'adddashstate';
		var postData = {
			dash_state: this.state.dash_state,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							dash_state: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addVisaTypesSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.visatypes);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addvisatypes';
		var postData = {
			visatypes: this.state.visatypes,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addVisaTypesModal: false,
							visatypes: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addJobTypeSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.job_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addjobtype';
		var postData = {
			job_type: this.state.job_type,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addJobTypeModal: false,
							addVisaTypesModal: false,
							dash_state: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	addEmpTypeSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.job_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addemptype';
		var postData = {
			emp_type: this.state.emp_type,
			dashbid: localStorage.dashbid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addCliModal: false,
							addLStsModal: false,
							addDocTypeModal: false,
							addIntModeModal: false,
							addSecClearModal: false,
							addDashStateModal: false,
							addJobTypeModal: false,
							addEmpTypeModal: false,
							addVisaTypesModal: false,
							dash_state: '',
						},
						() => {
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};

	openCliModel = () => {
		this.setState({
			addCliModal: true,
		});
	};
	openLStsModel = () => {
		this.setState({
			addLStsModal: true,
		});
	};
	openDocTypeModal = () => {
		this.setState({
			addDocTypeModal: true,
		});
	};
	openIntModeModal = () => {
		this.setState({
			addIntModeModal: true,
		});
	};
	openSecClearModal = () => {
		this.setState({
			addSecClearModal: true,
		});
	};
	openDashStateModal = () => {
		this.setState({
			addDashStateModal: true,
		});
	};
	openVisaTypesModal = () => {
		this.setState({
			addVisaTypesModal: true,
		});
	};
	openJobTypeModal = () => {
		this.setState({
			addJobTypeModal: true,
		});
	};
	openEmpTypeModal = () => {
		this.setState({
			addEmpTypeModal: true,
		});
	};
	handleCkey = () => {
		this.setState({
			addCliModal: false,
			addLStsModal: false,
			addDocTypeModal: false,
			addIntModeModal: false,
			addSecClearModal: false,
			addDashStateModal: false,
			addJobTypeModal: false,
			addEmpTypeModal: false,
			addVisaTypesModal: false,
		});
	};


	handleCliTypStskey = (id, client_type, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(client_type + 'Client Type');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + client_type,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togclitype';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(client_type + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(client_type + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};

	handleLeadStskey = (id, lead_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(lead_status + 'Lead Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + lead_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togleadsts';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(lead_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(lead_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleDocTypeStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Required documents Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togdoctypests';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleIntModeStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Interview Mode Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togintmodests';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleSecClearStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Security Clearance Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togsecclearsts';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleDashStateStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Dashboard State Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togdashstatests';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleVisaTypesStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Visa Types Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togvisatypessts';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleJobTypeStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Job Type Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togjobtypests';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};

	handleEmpTypeStskey = (id, item_status, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(item_status + 'Employment Type Status');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + item_status,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togemptypests';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(item_status + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.dashbid);
							swal(item_status + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};


	handleGetkeys = (dashbid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			dashbid: dashbid,
		};
		const getuurl = apiurl + 'admindashbitems';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('data', response.data);
					const allclitype = response.data.allclitype;
					const allleadsts = response.data.allleadsts;
					const alldoctypes = response.data.alldoctypes;
					const allinterview_modes = response.data.allinterview_modes;
					const allsecurity_clearances = response.data.allsecurity_clearances;
					const alldash_states = response.data.alldash_states;
					const alljob_types = response.data.alljob_types;
					const allemp_types = response.data.allemp_types;
					const allvisatypess = response.data.allvisatypess;

					let aclitype = 0;
					let tclitype = 0;
					allclitype.map((clitype) => {
						aclitype = aclitype + clitype.activated;
						tclitype = tclitype + 1;
					});

					let aleadsts = 0;
					let tleadsts = 0;
					allleadsts.map((leadsts) => {
						aleadsts = aleadsts + leadsts.activated;
						tleadsts = tleadsts + 1;
					});

					let adoctypes = 0;
					let tdoctypes = 0;
					alldoctypes.map((doctypes) => {
						adoctypes = adoctypes + doctypes.activated;
						tdoctypes = tdoctypes + 1;
					});

					let ainterview_modes = 0;
					let tinterview_modes = 0;
					allinterview_modes.map((interview_modes) => {
						ainterview_modes = ainterview_modes + interview_modes.activated;
						tinterview_modes = tinterview_modes + 1;
					});

					let asecurity_clearances = 0;
					let tsecurity_clearances = 0;
					allsecurity_clearances.map((security_clearances) => {
						asecurity_clearances = asecurity_clearances + security_clearances.activated;
						tsecurity_clearances = tsecurity_clearances + 1;
					});


					let adash_states = 0;
					let tdash_states = 0;
					alldash_states.map((dash_states) => {
						adash_states = adash_states + dash_states.activated;
						tdash_states = tdash_states + 1;
					});

					let ajob_types = 0;
					let tjob_types = 0;
					alljob_types.map((job_types) => {
						ajob_types = ajob_types + job_types.activated;
						tjob_types = tjob_types + 1;
					});

					let aemp_types = 0;
					let temp_types = 0;
					allemp_types.map((emp_types) => {
						aemp_types = aemp_types + emp_types.activated;
						temp_types = temp_types + 1;
					});

					let avisatypes = 0;
					let tvisatypes = 0;
					allvisatypess.map((visatypes) => {
						avisatypes = avisatypes + visatypes.activated;
						tvisatypes = tvisatypes + 1;
					});

					this.setState({
						client_types: allclitype,
						aclitype: aclitype,
						bclitype: tclitype - aclitype,
						tclitype: tclitype,
						lead_statuss: allleadsts,
						aleadsts: aleadsts,
						bleadsts: tleadsts - aleadsts,
						tleadsts: tleadsts,
						alldoctypes: alldoctypes,
						adoctypes: adoctypes,
						bdoctypes: tdoctypes - adoctypes,
						tdoctypes: tdoctypes,
						allinterview_modes: allinterview_modes,
						ainterview_modes: ainterview_modes,
						binterview_modes: tinterview_modes - ainterview_modes,
						tinterview_modes: tinterview_modes,
						allsecurity_clearances: allsecurity_clearances,
						asecurity_clearances: asecurity_clearances,
						bsecurity_clearances: tsecurity_clearances - asecurity_clearances,
						tsecurity_clearances: tsecurity_clearances,
						alldash_states: alldash_states,
						adash_states: adash_states,
						bdash_states: tdash_states - adash_states,
						tdash_states: tdash_states,
						alljob_types: alljob_types,
						ajob_types: ajob_types,
						bjob_types: tjob_types - ajob_types,
						tjob_types: tjob_types,
						allemp_types: allemp_types,
						aemp_types: aemp_types,
						bemp_types: temp_types - aemp_types,
						temp_types: temp_types,
						allvisatypess: allvisatypess,
						avisatypes: avisatypes,
						bvisatypes: tvisatypes - avisatypes,
						tvisatypes: tvisatypes,

					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				this.props.history.push('/managedashboards'); //eslint-disable-line
			});
	};


	render() {
		const { client_type, lead_status, req_document, interview_mode, security_clearance, dash_state, job_type, emp_type, visatypes } = this.state;
		return (
			<div>

				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">
									{localStorage.dashbname}

								</h3>
							</div>
						</CardBody>
					</Card>
				</Col>

				{/* Client Types  */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Client Types <br />
										<Badge color="primary">Total {this.state.tclitype} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.aclitype} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bclitype} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openCliModel}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Client types</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.client_types &&
											this.state.client_types.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.client_type}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editclienttype'
																)}
															/>
															{/* <strong>{hkey.client_type}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleCliTypStskey.bind(
																	this,
																	hkey.id,
																	hkey.client_type,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addCliModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Client type</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addCliTypeSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Client type</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="client_type"
														type="text"
														placeholder="Enter Client type"
														className="form-control"
														value={client_type}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Client type
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* client types end */}
				{/* lead status */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Lead Status <br />
										<Badge color="primary">Total {this.state.tleadsts} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.aleadsts} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bleadsts} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openLStsModel}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Lead Status</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.lead_statuss &&
											this.state.lead_statuss.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.lead_status}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editleadstatus'
																)}
															/>
															{/* <strong>{hkey.lead_status}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleLeadStskey.bind(
																	this,
																	hkey.id,
																	hkey.lead_status,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addLStsModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Lead Status</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addLeadStsSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Lead Status</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="lead_status"
														type="text"
														placeholder="Enter Lead Status"
														className="form-control"
														value={lead_status}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Lead Status
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* lead status end */}
				{/* Required documents */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Required documents <br />
										<Badge color="primary">Total {this.state.tdoctypes} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.adoctypes} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bdoctypes} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openDocTypeModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Required documents</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.alldoctypes &&
											this.state.alldoctypes.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.req_document}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editdoctype'
																)}
															/>
															{/* <strong>{hkey.req_document}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleDocTypeStskey.bind(
																	this,
																	hkey.id,
																	hkey.req_document,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addDocTypeModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Required documents</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addDocTypeSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Required documents</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="req_document"
														type="text"
														placeholder="Enter Required documents"
														className="form-control"
														value={req_document}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Required documents
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Required documents end */}
				{/* Interview mode */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Interview Mode <br />
										<Badge color="primary">Total {this.state.tinterview_mode} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.ainterview_mode} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.binterview_mode} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openIntModeModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Interview Mode</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.allinterview_modes &&
											this.state.allinterview_modes.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.interview_mode}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editintmode'
																)}
															/>
															{/* <strong>{hkey.interview_mode}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleIntModeStskey.bind(
																	this,
																	hkey.id,
																	hkey.interview_mode,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addIntModeModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Interview Mode</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addIntModeSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Interview Mode</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="interview_mode"
														type="text"
														placeholder="Enter Interview Mode"
														className="form-control"
														value={interview_mode}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Interview Mode
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Interview mode end */}
				{/* Security Clearance */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Security Clearance <br />
										<Badge color="primary">Total {this.state.tsecurity_clearance} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.asecurity_clearance} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bsecurity_clearance} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openSecClearModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Security Clearance</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.allsecurity_clearances &&
											this.state.allsecurity_clearances.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.security_clearance}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editsecclear'
																)}
															/>
															{/* <strong>{hkey.security_clearance}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleSecClearStskey.bind(
																	this,
																	hkey.id,
																	hkey.security_clearance,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addSecClearModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Security Clearance</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addSecClearSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Security Clearance</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="security_clearance"
														type="text"
														placeholder="Enter Security Clearance"
														className="form-control"
														value={security_clearance}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Security Clearance
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Security Clearance end */}
				{/* Visa Types */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Visa Types <br />
										<Badge color="primary">Total {this.state.tvisatypes} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.avisatypes} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bvisatypes} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openVisaTypesModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Visa Types</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.allvisatypess &&
											this.state.allvisatypess.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.visatypes}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editvisatypes'
																)}
															/>
															{/* <strong>{hkey.visatypes}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleVisaTypesStskey.bind(
																	this,
																	hkey.id,
																	hkey.visatypes,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addVisaTypesModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Visa Type</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addVisaTypesSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Visa Type</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="visatypes"
														type="text"
														placeholder="Enter Dashboard State"
														className="form-control"
														value={visatypes}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Visa Type
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Visa Types end */}
				{/* Job Types */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Job Types <br />
										<Badge color="primary">Total {this.state.tjob_type} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.ajob_type} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bjob_type} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openJobTypeModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Job Type</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.alljob_types &&
											this.state.alljob_types.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.job_type}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editjobtype'
																)}
															/>
															{/* <strong>{hkey.job_type}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleJobTypeStskey.bind(
																	this,
																	hkey.id,
																	hkey.job_type,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addJobTypeModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Job Type</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addJobTypeSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Job Type</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="job_type"
														type="text"
														placeholder="Enter Job Type"
														className="form-control"
														value={job_type}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Job Type
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Job Types end */}
				{/* Emp Types */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Employment Types <br />
										<Badge color="primary">Total {this.state.temp_type} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.aemp_type} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bemp_type} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openEmpTypeModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Employment Type</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.allemp_types &&
											this.state.allemp_types.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.emp_type}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editemptype'
																)}
															/>
															{/* <strong>{hkey.job_type}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleEmpTypeStskey.bind(
																	this,
																	hkey.id,
																	hkey.emp_type,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addEmpTypeModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Employment Type</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addEmpTypeSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Employment Type</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="emp_type"
														type="text"
														placeholder="Enter Employment Type"
														className="form-control"
														value={emp_type}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Employment Type
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Emp Types end */}
				{/* Dash States */}
				{localStorage.dashbid == 6 ? (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Dashboard States <br />
										<Badge color="primary">Total {this.state.tdash_state} </Badge>
										{'  '}
										<Badge color="success">Active {this.state.adash_state} </Badge>
										{'  '}
										<Badge color="warning">InActive {this.state.bdash_state} </Badge>
										{'  '}
										<Button
											className="float-right btn-sm"
											color="success"
											onClick={this.openDashStateModal}
										>
											+
								</Button>
									</h5>
								</div>

								{this.state.tshowAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Dashboard State</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.alldash_states &&
											this.state.alldash_states.map((hkey, index) => {
												var ksts = false;
												if (hkey.activated === 1) {
													ksts = true;
												}

												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<EditableLabel
																text={hkey.dash_state}
																labelClassName="myLabelClass"
																inputClassName="myInputClass"
																inputHeight="25px"
																labelFontWeight="bold"
																inputFontWeight="bold"
																onFocus={this._handleFocus.bind(this, hkey.id)}
																onFocusOut={this._handleFocusOut.bind(
																	this,
																	hkey.id, 'editdashstate'
																)}
															/>
															{/* <strong>{hkey.dash_state}</strong> */}
														</td>

														<td>
															<Switch
																onChange={this.handleDashStateStskey.bind(
																	this,
																	hkey.id,
																	hkey.dash_state,
																)}
																checked={ksts}
															/>
														</td>

													</tr>
												);
											})}
									</tbody>
								</Table>
								<Modal
									isOpen={this.state.addDashStateModal}
									modalClassName="ltr-support"
									className="modal-dialog--primary"
								>
									<div className="modal__header">
										<button
											className="lnr lnr-cross modal__close-btn"
											type="button"
											onClick={this.handleCkey}
										/>
										<h4 className="text-modal  modal__title">Add New Dashboard State</h4>
									</div>
									<div className="modal__body">
										{this.state.showAlert ? (
											<Alert color={this.state.alertType}>
												<p>{this.state.alertText}</p>
											</Alert>
										) : null}
										<form
											className="form"
											onSubmit={(event) => this.addDashStateSubmit(event)}
										>
											<div className="form__form-group">
												<span className="form__form-group-label">Dashboard State</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="dash_state"
														type="text"
														placeholder="Enter Dashboard State"
														className="form-control"
														value={dash_state}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Add Dashboard State
									</button>
										</form>
									</div>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				) : null}
				{/* Dash States end */}


			</div>
		);
	}
}

export default DashBItems;
