import React, { Component } from 'react';


import DownIcon from 'mdi-react/ChevronDownIcon';
import {
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	UncontrolledDropdown,
} from 'reactstrap';
import TopbarNavLink from './TopbarNavLink';
const initialstate = {
	showAddLead: false,
};
class TopbarNavTechnical extends Component {
	constructor (props) {
		super(props);
		this._onButtonClick = this._onButtonClick.bind(this);
	}
	state = { ...initialstate };

	_onButtonClick() {
		this.setState({
			showAddLead: true,
		});
	}
	render() {
		return (
			<nav className="topbar__nav">
				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownToggle className="topbar__nav-dropdown-toggle">
						Questions
						<DownIcon />
					</DropdownToggle>

					<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">

						<DropdownItem>
							<TopbarNavLink title="Add New Questions" route="/techaddques" />
						</DropdownItem>

					</DropdownMenu>
				</UncontrolledDropdown>



			</nav>
		);
	}
}

export default TopbarNavTechnical;
