import React, { PureComponent } from 'react';
/* global  */
import PropTypes from 'prop-types';
import { DUM_IMG_URL } from '../../../Apanel/QueryMe';
import { withRouter } from 'react-router-dom';


const initialstate = {
    image: DUM_IMG_URL,
};

class ProfileImage extends PureComponent {
    constructor (props) {
        super(props);
    }
    state = { ...initialstate };
    static propTypes = {
        cliclname: PropTypes.string,
        cliflpath: PropTypes.node,
    };
    render() {


        return (
            <img
                className={this.props.cliclname}
                src={this.props.cliflpath == '0' ? this.state.image : this.props.cliflpath}
                alt="avatar"
            />
        );
    }
}

export default withRouter(ProfileImage);
