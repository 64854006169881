import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// import DownIcon from 'mdi-react/ChevronDownIcon';
import {
	DropdownItem,
	// DropdownToggle,
	// DropdownMenu,
	UncontrolledDropdown,
} from 'reactstrap';
import TopbarNavLink from './TopbarNavLink';
// import TopbarNavCategory from './TopbarNavCategory';
const initialstate = {
	showAddLead: false,
};
class TopbarNavCandy extends Component {
	constructor (props) {
		super(props);
		this._onButtonClick = this._onButtonClick.bind(this);
	}
	state = { ...initialstate };

	_onButtonClick() {
		this.setState({
			showAddLead: true,
		});
	}
	render() {
		return (
			<nav className="topbar__nav">

				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownItem>
						<TopbarNavLink title="Profile" route="/profile" />
					</DropdownItem>
				</UncontrolledDropdown>
				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownItem>
						<TopbarNavLink title="Jobs" route="/jobs" />
					</DropdownItem>
				</UncontrolledDropdown>
				<UncontrolledDropdown className="topbar__nav-dropdown">
					<DropdownItem>
						<TopbarNavLink title="Dashboard" route="/dashboard" />
					</DropdownItem>
				</UncontrolledDropdown>


			</nav>
		);
	}
}

export default TopbarNavCandy;
